.editor-wrap {
  legend, 
  caption {
    position: absolute !important;
    width: 1px !important;
    height: 1px !important;
    padding: 0 !important;
    margin: -1px !important;
    overflow: hidden !important;
    clip: rect(0, 0, 0, 0) !important;
    white-space: nowrap !important;
    border: 0 !important;
  }

  table {
    width: 100%;
  }

  b, strong {font-weight: bold;}

  @import "asis";
}

.board-detail__content {
  .editor-wrap {
    font-family: 'youandiR';
    font-size: 14px;
    color: #666;

    .tbs02.c-table--scroll {
      border: 0;

      table {
        border-bottom: 1px solid #d1d1d1;
        border-top: 1px solid #666;
      }
    }
  }
}