.c-accordion {
  $root: ".c-accordion";

  &__item {
    background-color: #fff;
  }

  &__head {
    position: relative;
  }

  &__title {
    display: block;

    .o-text--3 {
      margin-left: 5px;
    }
  }

  &__button {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    &::before,
    &::after {
      position: absolute;
      top: 50%;
      right: 20px;
      transform: translateY(-50%);
      width: 12px;
      height: 2px;
      border-radius: 2px;
      background-color: #0076c0;
      content: "";
      transition-property: transform;
      transition-duration: 200ms;
    }

    &::after {
      transform: translateY(-50%) rotate(0deg);

    }

    &.collapsed {
      &::before,
      &::after {
        background-color: #333;
      }

      &::after {
        transform: translateY(-50%) rotate(90deg);
      }
    }
  }

  &__text {
    display: block;
    font-size: 13px;
    color: #666;
  }

  &__panel {
    &.collapse {
      &:not(.show) {
        display: none;
      }
    }

    &.collapsing {
      height: 0;
      overflow: hidden;
      @include transition(height .35s ease);
    }

    &.bg-gray {
      padding-top: 30px;

      .product-card {
        background-color: $white;
      }

      #{$root}__inside > .product-card:first-child{
        margin-top: 0;
      }
    }
  }

  &__foot {
    margin: 20px -20px -20px;
    padding: 20px;
    background-color: #f8f8f8;
  }

  &--type1 {
    margin-top: 30px;
    margin-left: -20px;
    margin-right: -20px;

    #{$root}__item {
      border-bottom: 1px solid #d8d8d8;
      background-color: #fff;
    }

    #{$root}__head {
      padding: 26px 52px 26px 20px;
    }

    #{$root}__title {
      @include h2-hb;
      line-height: 24px;
    }

    #{$root}__inside {
      padding: 0 20px 30px 20px;
    }

    &.fs-15 #{$root}__title {
      font-size: 15px;
    }
  }

  &--type2 {
    margin-top: 50px;

    #{$root}__item {
      border: 1px solid #eee;
      border-radius: 5px;

      &:nth-child(n+2) {
        margin-top: 10px;
      }
    }

    #{$root}__head {
      padding: 16px 52px 15px 20px;

      .o-input--check + label {
        z-index: 1;
        min-width: 70%;
        // margin: -16px 0;
        // line-height: 54px;

        &::before {
          top: 50%;
          transform: translateY(-50%);
        }

        &::after {
          top: 50%;
          // top: 27px;
        }
      }
    }

    #{$root}__title {
      font-size: 15px;
      font-weight: 600;
      line-height: 23px;
    }

    #{$root}__panel {
      background-color: #f8f8f8;
    }

    #{$root}__inside {
      padding: 25px 15px;
    }
  }

  &--type3 {
    #{$root}__item {
      border: 1px solid #eee;
      border-radius: 5px;

      &:nth-child(n+2) {
        margin-top: 10px;
      }
    }

    #{$root}__head {
      padding: 20px 52px 20px 20px;
    }

    #{$root}__title {
      font-family: inherit;
      font-size: 15px;
      line-height: 23px;
    }

    #{$root}__panel {
      border-top: 1px solid #eee;
    }

    #{$root}__inside {
      padding: 20px;
    }

    .c-table--highlight + & {
      margin-top: 30px;
    }
  }

  &--ellipsis {
    #{$root}__title {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    #{$root}__title.show {
      overflow: hidden;
      text-overflow: initial;
      white-space: initial;
    }
  }

  &--line {
    margin-top: 50px;
    border-top: 8px solid #f5f5f5;
  }

  &.border-top {
    border-top: 8px solid #f0f0f0;
  }

  &.question {
    margin-top: 0;

    .bg-gray {
      padding: 0;
    }

    .c-accordion__inside {
      padding: 30px 20px;
    }

    #{$root}__title {
      padding-left: 28px;
      font-family: $font-family-sans-serif;
      font-size: 15px;
    }

    .o-text--2 {
      margin: 0;
    }

    .question-mark {
      @include h3-hb;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      text-decoration: none;
      color: #0076c0;
    }

    #{$root}__text {
      display: inline-block;
      padding-left: 28px;
    }

    .answer {
      line-height: 1.8;
    }
  }

  &.font-basic {
    #{$root}__title {
      font-family: $font-family-base;
      font-size: 15px;
    }
  }

  .o-text--dot,
  .o-text--hyphen,
  .c-text-list__item {
    font-size: 13px;
    line-height: 21px;
    color: #666;
  }

  .o-text--dot + .o-text--dot,
  .c-text-list--dot > .c-text-list__item:nth-child(n+2) {
    margin-top: 16px;
  }

  .o-text--dot::before,
  .c-text-list--dot > .c-text-list__item::before {
    top: 9px;
  }

  .o-text--hyphen::before,
  .c-text-list--hyphen > .c-text-list__item::before {
    top: 10px;
  }

  .o-text--2 {
    display: block;
    margin: 15px 0 8px;

    #{$root}__inside > &:first-child {
      margin-top: 0;
    }
  }

  .o-button.link {
    font-size: 13px;
  }
}
