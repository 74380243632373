$modal-full: 100% !default;
$modal-xl: 1140px !default;
$modal-lg: 800px !default;
$modal-md: 500px !default;
$modal-sm: 300px !default;

// $body-drop-shadow: 0 18 100 0.3 #000

.has-modal {
  overflow: hidden !important;
  width: 100%;
  height: 100%;

  .c-modal {
    overflow-x: hidden;
    overflow-y: hidden;
  }
}

.c-modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: $zindex-modal;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  padding-right: 0 !important;

  &:focus .c-modal__content {
    // outline: 1px dotted #fff;
    // outline: 5px auto -webkit-focus-ring-color;
  }

  &__dialog {
    position: relative;
    width: auto;
    margin: 65px 20px 83px;
    pointer-events: none;

    //scroll
    display: flex;
    max-height: subtract(100%, 65px + 83);

    // aligned
    align-items: center;
    min-height: subtract(100%, 65px + 83);

    // aligned scroll
    flex-direction: column;
    justify-content: center;
    height: 100%;

    .c-modal.fade & {
      @include transition(transform .3s ease-out);
      transform: translate(0, -50px);
    }

    .c-modal.is-active & {
      transform: none;
    }

    .c-modal.modal-static & {
      transform: scale(1.02);
    }
  }

  &__content {
    overflow: hidden;
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    max-height: none;
    pointer-events: auto;
    background-clip: border-box;
    @include border-radius(1rem);
    @include box-shadow(0 .125rem .25rem rgba($black, .075));
    // 웹 접근성 확인 필요
  }

  &__dialog--half {
    justify-content: flex-end;
    margin: 0;
    max-height: 100%;
    min-height: 100%;

    .c-modal.fade & {
      transform: translate(0, 50%);
    }

    .c-modal.is-active & {
      transform: none;
    }

     .c-modal__content {
       max-width: 100%;
       border-bottom-left-radius: 0;
       border-bottom-right-radius: 0;
     }

    .c-button-wrap .c-modal__today-close {
      border-color: #d8d8d8 !important;
      color: $black !important;
    }
  }

  &__dialog--top {
    justify-content: initial;

    .c-modal__body {
      padding: 34px 0 !important;
    }

    .c-modal__content {
      margin-top: -40px;
    }
  }

  &--bottom {
    justify-content: flex-end;
    margin: 0;
    max-height: 100%;
    min-height: 100%;

    .c-modal.fade & {
      @include transition(transform .3s cubic-bezier(0.89, 0.1, 0.43, 0.86));
      transform: translate(0, 50%);
    }

    .c-modal.is-active & {
      transform: translate(0, 0);
    }

    .c-modal__content {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  }

  &__close {
    position: absolute;
    top: 33.5px;
    right: 17.5px;
    width: 30px;
    height: 30px;
    background: url('../img/com/btn_modal_close.png') no-repeat 50% 50%;
    background-size: 16px;
    z-index: 1;
  }

  &__title {
    display: flex;
    flex-shrink: 0;
    align-items: center;
    padding: 36.5px 25px 1px;
    @include h2-hb;
    background-color: $white;
    &--center {
      justify-content: center;
    }

    .c-modal--md & {
      padding-right: 50px;
    }
  }

  &__body {
    overflow-y: auto;
    position: relative;
    flex: 1 1 auto;
    padding: 20px 25px 35px;
    background-color: $white;

    .c-modal__content &:first-child {
      padding-top: 40px;
    }
  }

  &__foot {
    display: flex;
    flex-shrink: 0;
    border-top: 1px solid $border-color-d8d;

    .o-button {
      flex: 1;

      &:nth-child(n+2) {
        margin-left: 5px;
      }
    }
  }

  &__button {
    flex: 1;
    height: 52px;
    background-color: $white;
    @include h3-hb;

    &--confirm {
      background-color: #0076c0;
      color: $white;
    }
  }

  .c-button-wrap.t-notice {
    display: block;
    padding-top: 20px;

    .c-modal__button {
      position: relative;
      height: 36px;
      margin: 0 2.5px;
      padding: 0 12px;
      border-radius: 3px;
      background-color: $white;
      font-family: youandiR;
      pointer-events: auto;

      &.c-modal__today-close {
        background-color: transparent;
        border: 1px solid $white;
        color: $white;
      }

      &.darkgray {
        background-color: #666;
        color: $white;
      }
    }
  }

  &__dialog--full {
    margin: 0;
    max-height: 100%;

    .c-modal.fade & {
      @include transition(transform .0 cubic-bezier(0.89, 0.1, 0.43, 0.86));
      transform: translate(0, 0);
    }

    .c-modal.is-active & {
      transform: none;
    }

    &.modal-filter {
      .c-modal.fade & {
        @include transition(transform .3s cubic-bezier(0.89, 0.1, 0.43, 0.86));
        transform: translate(0, 100%);
      }

      .c-modal.is-active & {
        transform: none;
      }
    }

    .c-modal__content {
      overflow: visible;
      min-height:100%;
      max-width: 100%;
      border-radius: 0;
    }

    .c-modal__close {
      top: (28px/2);
      right: 15px;
    }

    .c-modal__title {
      height: (118px/2);
      padding: 0 50px 0 20px;
      border-bottom: 1px solid #eee;
      @include h2-hb;
    }

    .c-modal__title--center {
      border-bottom: none;
    }

    .c-modal__body {
      padding: 20px;
    }

    .c-modal__foot {
      padding: 20px;
      border-top: none;
      background-color: $white;
    }
  }

  .c-tab:first-child {
    margin-top: -21px;
  }

  .step {
    margin-top: -20px;
  }

  &__body > .o-heading:first-child {
    margin-top: 26px;
  }

  &__body > .c-table:first-child {
    margin-top: 0;
  }
}

// Measure scrollbar width for padding body during modal show/hide
.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 0;
  overflow: scroll;
}

@include media-breakpoint-up(sm) {
  .c-modal__content {
    max-width: $modal-lg;

    .c-modal--sm & {
      max-width: $modal-sm;
    }

    .c-modal--md & {
      max-width: $modal-md;
    }

    .c-modal--lg & {
      max-width: $modal-lg;
    }

    .c-modal--xl & {
      max-width: $modal-xl;
    }
  }
}

// filter
.modal-filter {
  &__heading {
    margin-top: 30px;
    margin-bottom: 15px;
    font-family: youandiB;
    font-size: 15px;
    line-height: 23px;
  }

  &__param {
    margin: 15px 0;
    font-size: 13px;
    line-height: 22px;
  }
}

.modal-filter__body > div:first-child .modal-filter__heading {
   margin-top: -15px;
}

.form {
  $root : ".form";

  display: flex;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 10px;
  border: 1px solid #dbdbdb;
  border-radius: 5px;

  &--half {
    #{$root}__input-text[type="date"] {
      height: 54px;
      padding-right: 0;
      background-image: none;
    }
  }

  &__input-text {
    flex: 1;
    height: 46px;
    border: 0;
    border-radius: 6px;
    background-color: transparent;
    padding: 0 20px;

    &[type="date"] {
      min-width: 100px;
      padding: 0 20px;
      background: #fff url('../img/com/bg_date.png') no-repeat right 20px top 50%;
      background-size: 10px auto;
      font-family: youandiR;

      /*&::-webkit-clear-button,
      &::-webkit-inner-spin-button,
      &::-webkit-calendar-picker-indicator {
        -webkit-appearance: none;
        display: none;
      }*/
    }

    .form--half & {
      border: 0;
    }

    &::-webkit-input-placeholder {
      font-weight: normal;
      color: #666;
    }

    &:-ms-input-placeholder {
      font-weight: normal;
      color: #666;
    }

    &::-moz-placeholder {
      font-weight: normal;
      color: #666;
    }

    &:disabled {
      background-color: #f8f8f8;
    }
  }

  &__text {
    display: inline-block;
    width: 20px;
    text-align: center;
  }

  &__select {
    width: 100%;
    height: 46px;
    border: 0;
    background: transparent url('../img/com/bg_select.png') no-repeat right 20px top 50%;
    background-size: 10px auto;
    padding-left: 20px;
    color: #666;
  }
}

.radio-box {
  display: flex;
  font-size: 0;
  margin: -2.5px;

  &--half {
    flex-wrap: wrap;

    .radio-box__item {
      flex: unset;
      width: 50%;
    }
  }

  &__item {
    flex: 1;
    height: 45px;
    padding: 2.5px;

    &:first-child {
      margin-left: 0;
    }
  }

  &__input {
    overflow: hidden;
    position: absolute;
    width: 1px;
    height: 1px;
    opacity: 0;

    &:checked + .radio-box__label {
      background-color: #0076c0;
      color: #fff;
      font-family: youandiB;
    }

    &:checked:focus + .radio-box__label {
      border-color: #0076c0;
      // 포커스 체크시 아웃라인 표시 필요함 현재 임시 처리
    }

    &:focus + .radio-box__label {
      border-color: #0076c0;
    }
  }

  &__label {
    display: flex;
    width: 100%;
    height: 100%;
    border: 1px solid #dbdbdb;
    border-radius: 6px;
    color: #666666;
    font-family: youandiR;
    justify-content: center;
    align-items: center;
    font-size: 15px;
    cursor: pointer;
  }

  & + .form {
    margin-top: 15px;
  }
}

// 주소검색
.modal-address {
  $root : ".modal-address";
  margin: 40px -20px 0;
  border-top: 8px solid #f0f0f0;

  &__item {
    border-bottom: 1px solid #d8d8d8;
  }

  &__code {
    display: block;
    margin-bottom: 3px;
    font-size: 15px;
    color: #0076c0;
  }

  &__button {
    display: block;
    width: 100%;
    padding: 24px 20px 23px;
    font-size: 15px;
    line-height: 27px;
    text-align: left;
  }

  &__detail {
    padding: 30px 20px;
    background-color: #f8f8f8;
  }

  &__form {
    position: relative;

    &--change {
      #{$root}__input {
        padding-right: 137px;
      }
    }
  }

  &__input {
    border-color: #0076c0;
  }

  &__change {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    padding: 0 20px;
  }

  &__text {
    margin: 15px 0 18px;
    font-size: 15px;
    line-height: 22px;
  }

  &__register {
    display: block;
    width: 100%;
    margin-top: 10px;
    border-radius: 6px;
    background-color: #0076c0;
    font-size: 17px;
    font-family: 'youandiB';
    line-height: 48px;
    color: #fff;
  }
}

// 직업찾기
.modal-job {
  margin: 0 -20px;
  padding: 12px 20px;
  border-top: 8px solid #f0f0f0;

  &__button {
    display: block;
    width: 100%;
    padding: 18px 0;
    font-size: 17px;
    text-align: left;
  }

  &__empty {
    margin-top: 18px;
    font-size: 17px;
  }
}
