*,
*::before,
*::after {
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
}

html,
body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
form,
fieldset,
legend,
input,
textarea,
select,
p,
button,
article,
aside,
details,
figcaption,
figure,
footer,
header,
main,
nav,
section,
summary {
  margin: 0;
  padding: 0;
}

html {
  width: 100%;
  min-width: 320px;
  height: 100%;
  -webkit-text-size-adjust: 100%;
}

body {
  letter-spacing: -0.25px;
  font-size: 15px;
  font-weight: 400;
  line-height: 1.5;
  color: #000;
}

article,
aside,
header,
footer,
nav,
section,
main,
figcaption,
figure,
details,
menu {
  display: block;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: 100%;
  font-weight: normal;
  line-height: 1.5;
}

a {
  color: #000;
  text-decoration: none;
  -webkit-tap-highlight-color: transparent;
}

a:hover,
a:visited {
  text-decoration: none;
}

strong,
em {
  font-weight: normal;
  font-style: normal;
}

ul,
ol {
  list-style: none;
}

table {
  table-layout: fixed;
  border-spacing: 0;
}

button,
input,
select,
textarea {
  -webkit-appearance: none;
  appearance: none;
}

select::-ms-expand {
  display: none;
}

button,
input[type="button"],
input[type="reset"],
input[type="submit"] {
  border-style: none;
  background: none;
  cursor: pointer;
}

textarea {
  vertical-align: top;
}


[tabindex="0"] {
  outline: none;
}
