.o-button {
  display: inline-block;
  border: 0;
  border-radius: 0;
  background-color: transparent;
  text-align: center;
  vertical-align: middle;
  -webkit-appearance: none;
  appearance: none;
  -webkit-transition: background 200ms;
  transition: background 200ms;
  font-weight: 400;

  &:disabled,
  &.is-disabled {
    border: 1px solid #e8e8e8 !important;
    background: #f8f8f8 !important;
    color: #c8c8c8 !important;;
    cursor: default;
  }

  &.sm {
    height: 29px;
    padding: 0 10px;
    border-radius: 3px;
    font-size: 15px;
    font-family: youandiR;
    line-height: 27px;
  }

  &.md {
    height: 36px;
    padding: 0 15px;
    border-radius: 3px;
    font-size: 15px;
    font-family: youandiR;
    line-height: 34px;
  }

  &.lg {
    width: 100%;
    height: 45px;
    padding: 0 15px;
    border-radius: 4px;
    font-size: 15px;
    font-family: youandiB;
    line-height: 43px;
  }

  &.xl {
    width: 100%;
    height: 56px;
    border-radius: 6px;
    font-size: 18px;
    font-family: youandiB;
    line-height: 54px;
  }

  &.full {
    display: block;
    width: 100%;
  }

  &.gray,
  &.dark-gray {
    border: 1px solid #d8d8d8;
    background-color: #fff;
    color: #000;
  }

  &.blue {
    border: 1px solid #0076c0;
    background-color: #fff;
    color: #0076c0;
  }

  &.gradient {
    border: none;
    background: linear-gradient(120deg, #0076c0 60%, #00a19c 100%);
    color: #fff;

    &.lg {
      line-height: 45px;
    }
  }

  // 더보기
  &.more {
    $root : ".more";
    display: block;
    width: auto;
    margin: 40px auto 0;
    padding: 0 20px;
    border: 1px solid #d8d8d8;
    border-radius: 3px;
    font-family: youandiR;
    font-size: 15px;
    line-height: 38px;

    #{$root}__current {
      margin-left: 5px;
      font-family: youandiB;
    }

    #{$root}__page {
      opacity: 0.5;
    }
  }

  // link
  &.link {
    font-size: 14px;
    color: #666;
    text-decoration: underline;
    vertical-align: top;

    &.blue {
      border: none !important;
      background-color: transparent !important;
      color: #0076c0;
    }
  }
}

.c-button-wrap {
  display: flex;
  font-size: 0;
  text-align: center;

  .o-button {
    flex: 1;

    &:nth-child(n+2) {
      margin-left: 5px;
    }
  }

  &.sm {
   margin-top: 20px;
  }

  &.md {
    margin-top: 30px;
  }

  &.lg {
    margin-top: 50px;

    & + *:not(.c-modal) {
      margin-top: 50px;
    }
  }
}

// 하단 고정 버튼
.c-button-fixed {
  .on-key--focus & {
    display: none;
  }

  display: flex;
  z-index: $zindex-fixed;
  left: 0;
  bottom: 0;
  background: linear-gradient(rgba(255, 255, 255, 0) 0%, #fff 15%);
  transform: translate(0, 0);
  transition: transform .3s .1s ease-in-out;
  position: fixed;
  width: 100%;
  padding: 25px 20px 20px;

  .c-tab__panel .product-wrap + &.po-static {
    margin-top: 110px;
    padding-top: 0;
    padding-bottom: 0;
  }

  &+.c-bottom-menu {
    margin-top: 50px;
  }

  .o-button {
    flex: 1;

    &:nth-child(n+2) {
      margin-left: 5px;
    }
  }

  &.po-static {
    padding-right: 0;
    padding-left: 0;
  }
}
// 하단 고정 메뉴 (푸터상단)
.c-bottom-menu {
  $root : ".c-bottom-menu";

  display: flex;
  flex-wrap: wrap;
  margin: 0 -20px -50px;
  border-top: 8px solid #f0f0f0;
  background-color: #f8f8f8;
  font-size: 0;

  .c-tab & {
    margin-bottom: -80px;
  }

  &__anchor {
    position: relative;
    display: flex;
    align-items: center;
    width: 50%;
    height: 62px;
    padding: 0 40px 0 20px;
    background-color: #fff;
    font-size: 15px;
    font-weight: 600;
    vertical-align: middle;
    color: #666;

    &:nth-child(odd) {
      border-right: 1px solid #eee;
    }

    &:nth-child(n+3) {
      border-top: 1px solid #eee;
    }

    &::before,
    &::after {
      position: absolute;
      top: 50%;
      right: 20px;
      width: 7px;
      height: 2px;
      background-color: #666;
      content:"";
    }

    &::before {
      margin-top: -3px;
      transform: rotate(45deg);
    }

    &::after {
      margin-top: 1px;
      transform: rotate(-45deg);
    }
  }

  .c-tab__panel + & {
    margin-bottom: -80px;
  }

  &--only {
    justify-content: center;
    background-color: $white;

    #{$root}__anchor {
      width: auto;
      padding: 0 16px 0 0;
      border-right: 0;

      &::before,
      &::after {
        right: 0;
      }
    }
  }
}
