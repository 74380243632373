.o-icon {
  display: inline-block;
  position: relative;
  vertical-align: middle;

  // 우측화살표
  &--right-arrow {
    width: 7px;
    height: 6px;
    margin-left: 5px;

    &::before,
    &::after {
      position: absolute;
      left: 0;
      width: 7px;
      height: 2px;
      background-color: #666;
      content:"";
    }

    &::before {
      top: 0;
      transform: rotate(45deg);
    }

    &::after {
      bottom: 0;
      transform: rotate(-45deg);
    }

    .blue & {
      &::before,
      &::after {
        background-color: #0076c0;
      }
    }
  }

  // 안내
  &--info {
    width: 21px;
    height: 20px;
    margin-right: 12px;
    background: url("../img/com/ico_info.png") no-repeat 50% 50%;
    background-size: 21px;
  }

  // 에러
  &--error-red {
    width: 18px;
    height: 18px;
    margin-right: 10px;
    background: url("../img/com/ico_error_red.png") no-repeat 50% 50%;
    background-size: 18px;
  }

  &--error-black {
    width: 18px;
    height: 18px;
    margin-right: 10px;
    background: url("../img/com/ico_error_black.png") no-repeat 50% 50%;
    background-size: 18px;
  }

  // 다운로드
  &--download {
    width: 11px;
    height: 11px;
    margin: -2px 0 0 8px;
    background: url("../img/com/ico_download.png") no-repeat 50% 50%;
    background-size: 11px;
  }

  // 파일종류
  &--pdf {
    width: 17px;
    height: 19px;
    margin-right: 11px;
    background: url("../img/com/ico_pdf.png") no-repeat 50% 50%;
    background-size: 17px auto;
  }

  // 뷰어
  &--view {
    margin: -2px 0 0 8px;
    width: 18px;
    height: 10px;
    background: url(../img/com/ico_view.png) no-repeat 50% 50%;
    background-size: 18px;
  }

  &--cross {
    width: 20px;
    height: 20px;
    position: relative;

    &:before,
    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      width: 100%;
      height: 5px;
      margin-top: 7px;
      background-color: $blue;
    }

    &:before {
      background-color: #00a19c;
      transform: rotate(90deg);
    }
  }

  //카카오톡
  &.o-icon--kakao {
    width: 36px;
    height: 36px;
    background: url("../img/com/ico_kakao.png") no-repeat 50% 50%;
    background-size: 36px auto;
  }

  //sms
  &.o-icon--sms {
    width: 36px;
    height: 36px;
    background: url("../img/com/ico_sms.png") no-repeat 50% 50%;
    background-size: 36px auto;
  }

  //페이스북
  &.o-icon--facebook {
    width: 36px;
    height: 36px;
    background: url("../img/com/ico_facebook.png") no-repeat 50% 50%;
    background-size: 36px auto;
  }
}
