// 임시

// info
.info__passage-review {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  margin: 37px 0;
  font-family: 'youandiR';
  font-size: 13px;
  color: $co-666;
}

.c-info__icon {
  position: relative;
  padding-top: 55px;
  padding-bottom: 30px;
  background: url('../img/com/bg_notice_top.png') no-repeat 50% 0/32px auto;

  &__head {
    @include h1-hb;
    text-align: center;
    font-size: 24px;
    color: $blue;
  }
}
// bullet-list
.bullet-list {

  &--dot {
    margin: -6px 0;
    padding-left: 13px;
    line-height: 27px;
  }

  &__item {
    margin: 6px 0;

    &::before {
      content: '';
      display: inline-block;
      width: 3px;
      height: 3px;
      margin-top: 11px;
      margin-left: -13px;
      margin-right: 10px;
      border-radius: 50%;
      background-color: #402c2c;
      vertical-align: top;
    }
  }
}

.irp-complete__anchor {
  position: relative;

  * {
    top: 0;
    left: 50%;
  }
}

.ani-wrap {
  // overflow: hidden;

  .line-1 {
    animation: .7s .5s ease-in-out forwards ani1;
  }

  .line-2 {
    animation: .7s .8s forwards ani2;
  }
}

@keyframes ani1 {
  0% {
    transform: translate(-50%, -50%);
    opacity: 0;
  }

  100% {
    transform: translate(0, 0);
    opacity: 1;
  }
}

@keyframes ani2 {
  0% {
    width: 0;
    opacity: 0;
  }

  100% {
    width: 50px;
    opacity: 1;
  }
}
