.c-tab {
  $root : ".c-tab";
  margin: 0 -20px;
  position: relative;

  &__list {
    display: flex;
    border-top: 1px solid #eee;
    border-bottom: 8px solid #f0f0f0;
    background-color: #fff;
    font-size: 0;
    width: 100%;
    overflow-y: auto;
  }

  &__panel {
    display: none;
    padding: 0 20px 30px;

    &.is-active {
      display: block;
    }
  }

  #{$root}__button {
    display: flex;
    flex: 1;
    justify-content: center;
    padding: 0 10px;

    &.is-active #{$root}__text {
      color: #0076c0;

      &::after {
        background-color: #0076c0;
      }
    }

    &:disabled #{$root}__text {
      color: #c8c8c8;
    }
  }

  #{$root}__text {
    position: relative;
    display: flex;
    align-items: center;
    height: 54px;
    @include h3-hb;
    color: #666;
    line-height: 21px;
    white-space: nowrap;

    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 3px;
      background-color: transparent;
    }
  }

  &--multi {
    position: relative;
    // overflow: hidden;

    #{$root}__list {
      overflow-x: auto;
      display: block;
      // margin-left: -5px;
      // margin-right: -5px;
      padding-left: 8px;
      padding-right: 50px;
      white-space: nowrap;
    }

    #{$root}__button {
      display: initial;
      padding: 0 12px;
    }
  }

  &--inside {
    margin: 30px 0 0;

    #{$root}__list {
      display: flex;
      padding: 0;
      border: none;
    }

    #{$root}__button {
      position: relative;
      background-color: #f8f8f8;
      border-left: 1px solid transparent;
      border-right: 1px solid transparent;
      border-bottom: 1px solid #999;

      &.is-active {
        background-color: $white;
        border-left: 1px solid #999;
        border-right: 1px solid #999;
        border-bottom: 1px solid transparent;

        &::after {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 2px;
          background-color: #0076c0;
        }
      }
    }

    #{$root}__text {
      height: 44px;
      justify-content: center;

      &::after {
        display: none;
      }
    }

    #{$root}__panel {
      padding-left: 0;
      padding-right: 0;
    }
  }

  &--col2 {
    #{$root}__list {
      position: relative;
      margin: 50px 20px 40px;
      padding: 0 0 1px 0;
      overflow: hidden;
      border: 0;
      border-radius: 3px;
      @include clearfix;

      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border: 1px solid #d8d8d8;
        border-radius: 3px;
      }

      &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 50%;
        bottom: 0;
        width: 1px;
        background: #d8d8d8;
      }
    }

    #{$root}__button {
      position: relative;
      float: left;
      width: 50%;
      padding: 0;

      &::before {
        content: "";
        position: absolute;
        bottom: -1px;
        left: 0;
        right: 0;
        border-bottom: 1px solid #d8d8d8;
      }

      &.is-active {
        &::after {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          right: -1px;
          bottom: -1px;
          border: 1px solid $blue;
          border-radius: 3px;
          z-index: 1;
        }

        &:nth-child(even)::after {
          right: 0;
        }

        #{$root}__text {
          font-family: youandiB;
        }
      }
    }

    #{$root}__text {
      display: inline;
      padding: 0;
      font-family: youandiR;
      line-height: 45px;

      &::after {
        display: none;
      }
    }
  }
}

.tab-menu {
  $root : ".tab-menu";

  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  z-index: 2;

  &__button {
    position: absolute;
    top: 1px;
    right: 0;
    z-index: 2;
    width: 50px;
    height: 53px;
    // ios에서 transparent 안됨.
    // background: linear-gradient(90deg , transparent 0%, #fff 30%);
    background: linear-gradient(90deg , rgba(255, 255, 255, 0) 0%, #fff 30%);

    &::before {
      display: block;
      position: absolute;
      top: 50%;
      right: 20px;
      transform: translateY(-100%) rotate(-45deg);
      border-width: 4px;
      border-style: solid;
      border-color: transparent transparent #000 #000;
      transition-duration: 200ms;
      content: "";
    }
  }

  &__panel {
    display: none;
    position: relative;
    z-index: 1;
    width: 100%;
    padding-left: 20px;
    border-radius: 0 0 15px 15px;
    background-color: #fff;
    box-shadow: 0 2px 10px 1px rgba(0, 0, 0, 0.15);
    transform: translate3d(0, -110%, 0);
    transition-property: transform;
    transition-duration: 200ms;
    opacity: 0;
  }

  &__title {
    display: block;
    @include h3-hb;
    color: #0076c0;
    line-height: 54px;
  }

  &__list {
    padding: 25px 20px 25px 0;
    border-top: 1px solid #e8e8e8;
  }

  &__anchor {
    display: block;
    padding: 10px 0;
    font-size: 15px;
    font-family: youandiR;

    &:focus {
      background-color: rgba(0,0,0, 0.1);
      outline: none;
    }

    @at-root {
      [aria-disabled="true"] .tab-menu__anchor {
        color: rgba(0, 0, 0, 0.3);
      }
    }
  }

  &.is-active {

    // &::after {
    //   display: block;
    //   position: absolute;
    //   bottom: 0;
    //   width: 100%;
    //   height: 20px;
    //   content: "";
    // }

    #{$root}__button {
      color: #0076c0;

      &::before {
        transform: translateY(-50%) rotate(-225deg);
        border-color: transparent transparent #0076c0 #0076c0;
      }
    }

    #{$root}__panel {
      display: block;
      transform: translate3d(0, 0, 0);
      opacity: 1;
    }
  }
}


[role="tabpanel"] {
  &:not(.is-active) {
    display: none;
  }
}

.scroll-down {
  .l-head {
    transform: translate(0, -100%);
  }
}

.page > .c-tab {

  &:first-child {
    padding-top: 63px;

    > .c-tab__list {
      position: fixed;
      top: 59px;
      transition: transform .3s .1s ease-in-out;
      z-index: $zindex-fixed;
    }

    .tab-menu {
      position: fixed;
      top: 59px;
      z-index: $zindex-fixed;
      transition: transform .3s .1s ease-in-out;
    }

    &.c-tab--fixed_list {
      > .c-tab__list {
        transform: translate(0, -100%);
      }

      > .tab-menu {
        transform: translate(0, -63px);
      }
    }
  }

  &:not(:first-child).c-tab--fixed_list {
    padding-top: 63px;

    > .c-tab__list {
      position: fixed;
      top: 0;
      z-index: $zindex-fixed;
    }

    > .tab-menu {
      position: fixed;
      z-index: $zindex-fixed;
    }
  }
}
