
/* ---------------------------------------------------------------------------------------------------------------------
Layout
- z-index
  .l-head 99
  .l-side 999
  .dimmed 100
  .modal 999
--------------------------------------------------------------------------------------------------------------------- */

// skip navigation
.skip {
  position:fixed;
  top: 0;
  left: 0;
  z-index: 999;
  width: 100%;

  &__anchor {
    display:block;
    width:0;
    height:0;
    font-size:1px;
    opacity:0;

    &:hover,
    &:focus,
    &:active {
      display: block;
      width: 100%;
      height: 50px;
      background-color: #333;
      font-size: 15px;
      text-align: center;
      color: #fff;
      line-height: 50px;
      opacity: 1;
    }
  }
}

/* header */
.l-head {
  position:fixed;
  top: 0;
  left: 0;
  z-index: $zindex-l-head;
  width: 100%;
  height: 59px;
  border-bottom: 1px solid #eee;
  background-color: #fff;
  font-family: youandiR;
  font-size: 17px;
  // transform: translate(0, -100%);
  transition: transform .3s .1s ease-in-out;
  will-change: transform;
  $root: ".l-head";

  &__side {
    position: absolute;
    top: 0;
    right: 5px;
    display: block;
    width: 54px;
    height: 58px;
    background: url("../img/com/bg_side.png") no-repeat 50% 50%;
    background-size: 26px auto;
  }

  &__side--new {
    background: url("../img/com/bg_side_new.png") no-repeat 50% 50%;
    background-size: 26px auto;
  }

  .main  & {
    #{$root}__title {
      font-family: youandiB;
      justify-content: flex-start;
      padding-left: 55px;
      font-size: 21px;
    }
  }

  &__title {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 74px 0 77px;
    height: 58px;
    word-break: break-word;
    overflow: hidden;
    line-height: 19px;
  }
}

.logo {
  position: absolute;
  top: 0;
  left: 20px;
  width: 27px;
  line-height: 58px;

  img {
    width: 100%;
    vertical-align: middle;
  }
}

/* side */
.l-side {
  display: none;
  position: fixed;
  top: 0;
  right: 0;
  z-index: $zindex-l-side;
  width: 100%;
  height: 100%;
  // background-color: #ddd;
  transform: translate3d(100%, 0, 0);
  transition-property: transform;
  transition-duration: 200ms;

  &.is-active {
    transform: translate3d(0, 0, 0);
  }
}

.main .l-wrap {
  transition: margin .8s;
}

/* content */
.l-content {
  overflow: hidden;
  padding: 59px 20px 50px;
  background-color: $white;

  .main & {
    transition: margin-top .8s;
  }
}

/* footer */
.l-foot {
  position: relative;
  padding: 35px 20px 0;
  background-color: #fff;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    width: 100%;
    height: 7px;
    background: #f0f0f0;
    border-top: 1px solid #d8d8d8;
  }
}

.foot-ars {
  font-size: 0;

  $root: ".foot-ars";

  .c-accordion--type1 {
     margin-top: 0;

    .c-accordion__item {
      position: relative;
      border: 0;

      &:after {
        content: '';
        position: absolute;
        right: 20px;
        left: 20px;
        bottom: 0;
        border-bottom: 1px solid #d8d8d8;
      }
    }

    .c-accordion__inside {
      padding-bottom: 40px;
    }
  }

  &__list {
    font-size: 13px;
  }

  &__item {
      ~#{$root}__item {
      margin-top: 12px;
    }

    > :nth-child(3) {
      padding-left: 168px;
    }
  }

  &__title {
    display: inline-block;
    margin-right: 8px;
    color: #666;
  }

  &__text {
    display: inline-block;

    @media (max-width: 359px) {
      display: block;
    }
  }

  &__button-wrap {
    display: flex;
    height: 45px;
    line-height: 43px;
    margin-top: 26px;
    border: 1px solid #d8d8d8;
    border-radius:8px;
  }

  &__anchor {
    flex: 1;
    position: relative;
    text-align: center;
    font-family: youandiB;
    font-size: 15px;

    ~#{$root}__anchor {
      &:before {
        content: '';
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        width: 1px;
        height: 16px;
        background: #d8d8d8;
      }
    }

    span {
      position: relative;
      padding-left: 26px;

      &:before {
        content: '';
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        width: 11px;
        height: 14px;
        background: url(../img/com/ico_tel.png) no-repeat 50% 50%;
        background-size: 11px;
      }
    }
  }
}

.foot-info {
  margin: 44px -10px 0;
  font-family: youandiR;
  font-size: 0;

  $root: ".foot-info";

  &__button {
    position: relative;
    padding: 6px 10px;
    font-size: 15px;
    color: #666;

    &.is-active {
      font-family: youandiB;
      color:#000;

      &:before {
        content: '';
        position: absolute;
        right: 10px;
        left: 10px;
        bottom: 0;
        display: block;
        border-bottom: 2px solid #000;
      }
    }
  }

  &__item {
    position: relative;
    display: inline-block;
    padding: 3px 10px;
    font-size: 13px;

    &:not(:last-child) {
      &:before {
        content: '';
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
        width: 1px;
        height: 10px;
        background: #000;
      }
    }
  }

  .c-form {
    margin-top: 24px;
  }
}

.foot-company {
  margin-top: 13px;
  font-size: 0;

  $root: ".foot-company";

  &__item {
    display: inline-block;
    margin-right: 12px;
    line-height: 20px;
    font-size: 12px;
    color: #666;
  }
}

.foot-util {
  margin: 25px 0 0;
  padding-bottom: 60px;
  font-size: 0;

  $root: ".foot-util";

  &__item {
    display: inline-block;
    height: 30px;

    ~ #{$root}__item {
      margin-left: 16px;
    }
  }

  img {
    height: 100%;
  }
}

.foot-copyright {
  position: relative;
  // position: fixed;
  left: 0;
  bottom: 0;
  z-index: 1;
  // z-index: -2;
  overflow: hidden;
  margin: 60px -20px 0;
  margin-top: 0;
  padding: 26px 0 24px;
  width: calc(100% + 40px);
  height: 95px;
  text-align: center;
  font-family: youandiB;
  font-size: 18px;
  color: #fff;
  background: #0079cc;

  &.active {
    animation-name: copyright-opacity;
    animation-duration: 2s;

    @keyframes copyright-opacity {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }

    &::after {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      z-index: -1;
      width: 200%;
      height: 95px;
      text-align: center;
      background: linear-gradient(-50deg, #18b3af, #0079cc, #0079cc 100% 200%);
      animation-name: copyright-gradinet;
      animation-duration: 2s;
      animation-delay: 1s;
      animation-fill-mode: forwards;

      @keyframes copyright-gradinet {
        0% {
          right: -200%;
          opacity: 0;
        }

        100% {
          right: 0;
          opacity: 1;
        }
      }
    }
  }

  span {
    position: relative;

    &:before {
      content: '';
      position: absolute;
      top: -7px;
      right: -7px;
      width: 8px;
      height: 11px;
      background: url(../img/com/bg_copyright.png) no-repeat 50% 50%;
      background-size: 8px;
    }
  }

  small {
    display: block;
    font-size: 12px;
  }
}

.l-head {
  .main & {
    display: none;
    opacity: 0;
  }

  &.slide-up {
    transition: transform .3s .2s ease-out;
    transform: translate3d(0, -100%, 0);
    .main & {
      opacity: 1;
      z-index: 21;
    }
  }

  &.slide-down {
    transition: transform .2s .3s ease-out;
    .main & {
      opacity: 1;
      z-index: 21;
    }
  }
}

.foot-family-site {
  $root: '.foot-family-site';
  position: relative;
  margin: -15px 0 20px;
  line-height: 53px;

  &:focus {
    border-color: #0076c0;

    #{$root}__button {
      border-top: 1px solid #e8e8e8;
    }
  }

  &:before {
    content: '';
    position: absolute;
    top: 50%;
    right: 0;
    z-index: 3;
    margin-top: -3px;
    border: 5px solid #000;
    border-top-color: transparent;
    border-right-color: transparent;
    transform: translate(-20px, -50%) rotate(-45deg);
  }

  &__button {
    position: relative;
    z-index: 2;
    height: 55px;
    line-height: 53px;
    width: 100%;
    padding: 0 15px;
    text-align: left;
    border: 1px solid #b8b8b8;
    border-radius: 6px;
    background-color: #fff;
  }

  &__nav {
    display: none;
    position: absolute;
    bottom: 55px;
    width: 100%;
    padding: 10px 0;
    border: 1px solid #b8b8b8;
    border-radius: 6px;
    background-color: #fff;

    a {
      display: block;
      padding: 0 15px;
      line-height: 2;
    }
  }

  a:focus {
    color: #0076c0;
  }

  &.is-active {

    &:before {
      margin-top: 3px;
      transform: translate(-20px, -50%) rotate(135deg);
    }

    #{$root}__button {
      border-top: 0;
      border-color: #0076c0;
      border-top-left-radius: 0;
      border-top-right-radius: 0;

      &::before {
        content: '';
        position: absolute;
        top: 0;
        width: calc(100% - 30px);
        height: 1px;
        background-color: #d8d8d8;
      }
    }

    #{$root}__nav {
      display: block;
      border-bottom: 0;
      border-color: #0076c0;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      box-shadow: 0 -10px 35px rgba(0, 0, 0, 0.2);
    }
  }
}

// 개발쪽
#mContents {
  min-height: 100vh;
  background-color: #fff;
}
