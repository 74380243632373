/* ---------------------------------------------------------------------------------------------------------------------
공통
--------------------------------------------------------------------------------------------------------------------- */
// 조회 내역&결과 없음
.empty {
  margin-top: 30px;
  padding: 50px 0;
  text-align: center;

  $root: ".empty";

  &__text {
    font-size: 15px;
    line-height: 18px;
    color: #666;

    &:only-child {
      margin: 33px 0 14px;
    }
  }

  &__text + .o-text--star {
    margin-top: 43px;
  }

  &--type2 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 200px;
    background: #f8f8f8;
    border: 0;
    border-radius: 12px;
    text-align: center;
    font-size: 0;
  
    #{$root}__image {
      display: block;
      width: 28px;
      margin-bottom: 27px;

      img {
        width: 100%;
      }
    }
  
    #{$root}__text {
      font-size: 15px;
      color: #000;
    }
  }
}

// 완료
.complete {
  $root: '.complete';

  padding: 167px 0 50px;
  background: url('../img/com/bg_complete.gif') no-repeat 50% 80px;
  background-size: 60px auto;
  text-align: center;

  &__msg {
    font-size: 21px;
    font-family: youandiB;
    line-height: 31px;
    color: #0076c0;
  }

  &__text {
    margin-top: 30px;
    font-size: 17px;
    line-height: 21px;
  }

  &__error {
    position: relative;
    display: inline-block;
    margin-top: 32px;
    padding: 73px 34px 26px;
    border: 1px solid #ff3c50;
    color: #ff3c50;
    font-size: 15px;
    border-radius: 10px;

    &::before {
      content: "";
      position: absolute;
      top: 28px;
      left: 50%;
      transform: translateX(-50%);
      width: 32px;
      height: 29px;
      background: url('../img/com/ico_complete_error.png') no-repeat;
      background-size: 100%;
    }
  }

  &__box {
    display: inline-block;
    min-width: 270px;
    margin-top: 28px;
    padding: 26px 15px;
    background-color: #f8f8f8;
    color: #666;
    line-height: 21px;
  }

  .o-button {
    margin-top: 24px;

    & + .o-button {
      margin-top: 0;
    }
  }

  &--type2 {
    background-image: url('../img/com/bg_complete_type2.gif');
  }

  &--simulation {
    margin-top: 50px;
    padding-top: 104px;
    padding-bottom: 46px;
    border: 1px solid #e8e8e8;
    border-radius: 6px;
    background: #f8f8f8 url('../img/com/bg_complete_simulation.png') no-repeat 50% 50px;
    background-size: 44px auto;

    #{$root}__text {
      margin-top: 9px;
      font-size: 13px;
      color: #666;
    }
  }
}

// 그래프 영역
.graph {
  overflow: hidden;
}

// 이미지 영역
.image-wrap {
  img {
    max-width: 100%;
    vertical-align: top;
  }
}

// 상단 텍스트 영역
.info-wrap {
  $root: '.info-wrap';
  margin: 0 -20px;
  padding: 25px 20px 24px;
  background-color: #f8f8f8;
  text-align: center;

  &__title {
    display: block;
    margin-bottom: 8px;
    font-size: 17px;
    font-family: youandiB;
    color: #0076c0;
    line-height: 31px;
  }

  &__text {
    font-size: 15px;
    font-family: youandiR;
    line-height: 24px;
    color: #666;
  }

  &__sub-text {
    margin-top: 12px;
    font-family: 'youandiR';
    font-size: 13px;

    .o-button.link {
      font-size: 13px;
    }
  }

  &--blue {
    padding: 33px 20px;
    background-color: #0076c0;

    #{$root}__text {
      font-size: 17px;
      line-height: 30px;
      color: #fff;
    }

    #{$root}__highlight {
      display: block;
      font-size: 21px;
      font-family: youandiB;
    }
  }

  &--simulation {
    padding-top: 40px;
    padding-bottom: 35px;

    #{$root}__label {
      display: inline-block;
      margin-bottom: 18px;
      padding: 0 15px;
      border: 1px solid #fff;
      border-radius: 17px;
      font-size: 15px;
      font-family: youandiR;
      line-height: 23px;
      color: #fff;
    }

    #{$root}__sub-text {
      color: #fff;
    }

    #{$root}__text {
      line-height: 27px;
    }

    #{$root}__highlight {
      display: inline-block;
      // border-bottom: 1px solid #fff;
      font-size: 17px;
    }

    #{$root}__button {
      margin: 20px 0 5px;
    }

    .c-table {
      margin-bottom: 5px;
      border-radius: 10px;
      overflow: hidden;
    }
  }

  .c-modal & {
    margin-top: -20px;
  }
}

// 기타 - heading 레벨과 동일 선상 우측 버튼
.heading-button {
  margin-top: -44px;
  text-align: right;
}

// 검색
.search-form {
  $root: '.search-form';

  margin-top: 25px;

  &__select {
    margin-bottom: 5px;

    .o-input--select {
      font-weight: normal;
      color: #666;

      &:nth-child(n + 2) {
        margin-top: 10px;
      }
    }
  }

  &__input {
    position: relative;
    padding-right: 108px;
    border-bottom: 1px solid #000;

    &.is-active {
      border-bottom-color: #eee;

      #{$root}__reset {
        display: block;
      }
    }

    .o-input--text {
      padding: 0;
      border: none;
      font-family: 'youandiR';
      font-weight: normal;
      font-size: 19px;
      height: 66px;
      color: #0076c0;
    }
  }

  &__reset {
    position: absolute;
    bottom: 15px;
    right: 69px;
    width: 36px;
    height: 36px;
    text-align: center;
  }

  &__icon {
    display: inline-block;
    width: 22px;
    height: 22px;
    border-radius: 50%;
    background-color: #b8b8b8;
    transform: rotate(45deg);
    vertical-align: top;

    &::before,
    &::after {
      position: absolute;
      display: block;
      top: 5px;
      left: 10px;
      width: 2px;
      height: 12px;
      border-radius: 5px;
      background-color: #fff;
      content: '';
    }

    &::after {
      transform: rotate(90deg);
    }
  }

  &__button {
    display: block;
    position: absolute;
    bottom: 15px;
    right: 0;
    width: 56px;
    height: 36px;
    border-radius: 4px;
    background-color: #333;
    color: #fff;
  }
}

.search-input {
  margin: 0 -20px;
  padding: 30px 20px;
  border-bottom: 8px solid #f0f0f0;

  &__inside {
    position: relative;
  }

  &__reset {
    position: absolute;
    top: 50%;
    right: 47px;
    transform: translateY(-50%);
    width: 36px;
    height: 100%;
    text-align: center;
  }

  &__icon {
    display: inline-block;
    width: 22px;
    height: 22px;
    border-radius: 50%;
    background-color: #b8b8b8;
    transform: rotate(45deg);
    vertical-align: top;

    &::before,
    &::after {
      position: absolute;
      display: block;
      top: 5px;
      left: 10px;
      width: 2px;
      height: 12px;
      border-radius: 5px;
      background-color: #fff;
      content: '';
    }

    &::after {
      transform: rotate(90deg);
    }
  }

  &__button {
    display: block;
    position: absolute;
    top: 0;
    right: 10px;
    width: 37px;
    height: 100%;
    background: transparent url('../img/com/bg_search.png') no-repeat 50% 50%;
    background-size: 17px auto;
  }

  .o-input--text {
    padding-right: 85px;
  }
}

// 검색 키워드
.search-keyword {
  $root: '.search-keyword';
  margin: 30px 0 40px;

  &__title {
    margin-bottom: 26px;
    padding-top: 8px;
    font-size: 15px;
  }

  &__list {
    overflow: hidden;
    margin-top: -10px;
    font-size: 0;
  }

  &__button {
    display: inline-block;
    margin: 10px 10px 0 0;
    padding: 0 15px;
    border: 1px solid #dadada;
    border-radius: 17px;
    font-size: 15px;
    line-height: 34px;

    &:last-child {
      margin-right: 0;
    }
  }

  &--scroll {
    #{$root}__list {
      overflow-x: auto;
      white-space: nowrap;
    }
  }
}

/* ---------------------------------------------------------------------------------------------------------------------
Mypage - 공통
--------------------------------------------------------------------------------------------------------------------- */
.filter {
  position: relative;
  margin: 30px 0 40px;
  padding: 0 20px;
  border: 1px solid #b8b8b8;
  border-radius: 6px;
  background-color: #fff;
  font-size: 0;

  &__title {
    display: inline-block;
    margin-right: 20px;
    font-size: 17px;
    font-family: youandiB;
    line-height: 58px;
    vertical-align: top;
  }

  &__date {
    display: inline-block;
    font-size: 15px;
    font-family: youandiB;
    line-height: 58px;
    color: #0076c0;
    vertical-align: top;
  }

  &__button {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url('../img/com/bg_filter.png') no-repeat right 20px top 50%;
    background-size: 18px auto;
  }

  &-wrap {
    margin: 0 -20px;
    padding: 0 20px;
    padding-bottom: 30px;
    border-bottom: 8px solid #f0f0f0;

    .filter {
      margin-bottom: 15px;
    }
  }
}

// 절차
.payment-step {
  padding: 25px 10px;
  border: 1px solid #d8d8d8;
  border-radius: 6px;
  background-color: #f8f8f8;

  &__list {
    display: flex;
  }

  &__item {
    position: relative;
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    padding-top: 27px;
    font-size: 15px;
    font-family: youandiB;
    line-height: 18px;
    color: #666;
    text-align: center;

    &::after {
      display: block;
      position: absolute;
      top: 8px;
      left: 0;
      width: 100%;
      height: 1px;
      background-color: #dfdfdf;
      content: '';
    }

    &:first-child {
      &::after {
        left: auto;
        right: 0;
        width: 50%;
      }
    }

    &:last-child {
      &::after {
        width: 50%;
      }
    }
  }

  &__number {
    position: absolute;
    top: 0;
    left: 50%;
    z-index: 1;
    transform: translateX(-50%);
    display: block;
    width: 30px;
    border: 1px solid #0076c0;
    border-radius: 15px;
    background-color: #fff;
    font-size: 12px;
    line-height: 15px;
    color: #0076c0;
    text-align: center;
  }
}

// 조회 & 계약목록
.product-wrap {
  $root: '.product-wrap';

  margin: 0 -20px;
  padding: 40px 20px 50px;
  background-color: #f8f8f8;

  &--white {
    background-color: #fff;
  }

  &--blue {
    background-color: #0076c0;

    .more {
      border: 1px solid #fff !important;
      color: #fff;
    }

    #{$root}__title,
    #{$root}__text {
      color: #fff;
    }

    #{$root}__check label {
      color: #fff;
    }

    .o-text--star {
      color: #fff;
    }
  }

  &--bottom {
    margin-bottom: -50px;

    .c-tab__panel > & {
      margin-bottom: -80px;
    }
  }

  &__title {
    margin-bottom: 25px;
    font-family: youandiB;
    font-size: 17px;

    .product-card + & {
      margin-top: 45px;
    }

    +.heading-right {
      margin: -45px 0 25px;
      text-align: right;
      font-size: 13px;
      color: #fff;
    }
  }

  &__text {
    margin-bottom: 25px;
    font-family: youandiR;
    font-size: 17px;
    line-height: 27px;
    text-align: center;
  }

  &__check {
    margin-bottom: 30px;

    label {
      font-family: youandiR;
      font-size: 15px !important;
    }
  }

  .o-heading--1 {
    margin-top: 6px;
  }
}

// 상품 카드형
.product-card {
  $root: '.product-card';

  border: 1px solid #d8d8d8;
  border-radius: 6px;

  &--type1 {
    position: relative;
    background-color: #fff;
    box-shadow: 0 8px 8px 1px rgba(0, 0, 0, 0.1);

    & + & {
      margin-top: 20px;
    }

    #{$root}__head {
      display: block;
      padding: 31px 25px 25px;
      text-align: center;
    }

    #{$root}__number {
      display: block;
      margin-bottom: 6px;
      padding: 0 35px;
      font-family: youandiR;
      font-size: 13px;
    }

    #{$root}__title {
      font-family: youandiR;
      padding: 0 35px;
      font-size: 17px;
      line-height: 27px;
    }

    #{$root}__row {
      margin-top: 15px;

      .c-radio-box {
        margin-top: 0;
      }
    }

    #{$root}__title + #{$root}__row {
      margin-top: 20px;
    }

    #{$root}__text {
      margin-top: 10px !important;
      font-family: youandiR;
      font-size: 15px;
      color: #666;
    }

    #{$root}__highlight {
      font-family: youandiB;
      color: #0076c0;
    }

    #{$root}__input {
      position: absolute;
      top: 0;
      left: 0;

      label {
        padding: 29px;

        &::before {
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }

        &::after {
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }

      .o-input--check + label::after {
        transform: translate(-50%, 0%);
      }
    }

    #{$root}__label {
      position: absolute;
      top: 17px;
      right: 15px;
      display: inline-block;
      padding: 0 9px;
      border: 1px solid #eee;
      border-radius: 11px;
      background-color: #f8f8f8;
      font-size: 13px;
      font-weight: 600;
      color: #666;
      line-height: 19px;
    }

    #{$root}__panel {
      border-top: 1px solid #d8d8d8;
      padding: 20px 25px;
    }

    #{$root}__foot {
      padding: 20px;
      border-radius: 0 0 6px 6px;
      background-color: #0076c0;
      text-align: center;
    }

    #{$root}__detail {
      display: block;
      margin: -20px -25px 0;
      padding-left: 25px;
      background-color: #f5f5f5;
      font-size: 13px;
      color: #666;
      line-height: 45px;
    }

    #{$root}__sub-title {
      margin-bottom: 16px;
      padding-top: 20px;
      font-size: 15px;

      &:nth-of-type(n + 2) {
        margin-top: 20px;
        border-top: 1px solid #eee;
      }
    }

    #{$root}__name-ko {
      font-size: 19px;
      font-family: youandiB;
    }

    #{$root}__name-en {
      display: block;
      margin-top: 1px;
      font-size: 13px;
      font-family: youandiR;
      color: #666;
    }

    .o-input--select {
      margin: -5px 0;
      vertical-align: top;
    }
  }

  &--type2 {
    margin-top: 30px;
    background-color: #fff;

    & + & {
      margin-top: 10px;
    }

    #{$root}__head {
      padding: 15px 20px;
      border-bottom: 1px solid #d8d8d8;
    }

    #{$root}__input {
      display: inline-block;
      vertical-align: top;

      label {
        margin: -15px 0 -15px -20px;
        padding-left: 0;
        height: 56px;
        width: 51px;

        &::before {
          top: 50%;
          left: 20px;
          transform: translateY(-50%);
        }

        &::after {
          top: 50%;
          left: 29px;
          transform: translateY(-50%);
        }
      }

      .o-input--check + label::after {
        left: 25px;
        transform: translateY(0%);
      }

      & + #{$root}__title {
        width: calc(100% - 35px);
      }
    }

    #{$root}__number {
      display: block;
      font-size: 13px;
      color: #666;
    }

    #{$root}__title {
      display: inline-block;
      font-size: 15px;
      font-weight: 600;
      line-height: 23px;
    }

    #{$root}__delete {
      position: absolute;
      top: 0;
      right: 0;
      height: 100%;
      padding: 0 20px;
      font-weight: 600;
    }

    #{$root}__panel {
      padding: 20px;
    }

    #{$root}__foot {
      padding: 20px;
      border-radius: 0 0 6px 6px;
      background-color: #f8f8f8;
      text-align: center;
    }
  }

  &--delete {
    #{$root}__head {
      position: relative;
      padding-right: 65px;
    }

    #{$root}__delete {
      height: 56px;
      padding: 0 20px;
      font-weight: 600;
    }
  }

  &__anchor {
    display: block;

    &::after {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      content: '';
    }
  }

  .percent-input {
    &__col {
      display: inline-block;
      vertical-align: top;

      &:first-child {
        margin-right: 15px;
        padding-right: 40px;
        background: transparent url('../img/com/bg_percent_input.png') no-repeat right 0 top 50%;
        background-size: 20px auto;
      }

      .input-text {
        width: 95px;
      }
    }

    &__title {
      display: block;
      margin-bottom: 7px;
      font-size: 13px;
    }

    &__number {
      display: block;
      font-size: 17px;
      line-height: 48px;
    }

    &__input {
      display: inline-block;
      vertical-align: top;
    }
  }

  .percent-select {
    &__title {
      display: block;
      margin-bottom: 16px;
      text-align: left;
    }

    &__form {
      display: flex;
    }

    .o-input--select,
    .input-text {
      width: 50%;
    }

    .input-text {
      margin-left: 5px;
    }
  }

  .order-amount {
    position: relative;
    padding: 0 5px;
    text-align: left;

    &__label {
      position: relative;
      z-index: 1;
      font-size: 15px;
      color: #fff;
      line-height: 36px;
    }

    &__input {
      position: absolute;
      top: 0;
      right: 0;
      width: 100%;
      padding-left: 110px;
    }

    &__price {
      height: 36px;
      color: #fff;
    }
  }

  .c-tooltip--ellipsis {
    .c-tooltip__panel {
      left: auto;
      right: -26px;
      width: calc(100vw - 40px);
      margin-bottom: 20px;
    }
  }
}

// 리스트
.list-title {
  margin: 0 -20px;
  padding: 9px 20px;
  background-color: #f8f8f8;
  color: #666;

  & + .inside-list {
    padding: 20px 0;

    &:last-child {
      margin-bottom: -30px;
    }
  }
}

.inside-list {
  &__item {
    display: flex;

    &:nth-child(n + 2) {
      margin-top: 16px;
    }
  }

  &__title {
    flex: 1 0 auto;
    font-size: 15px;
    color: #666;
    text-align: left;
  }

  &__text {
    flex: 1 0 auto;
    font-size: 15px;
    font-weight: 600;
    vertical-align: top;
    text-align: right;
    white-space: nowrap;
    text-overflow: ellipsis;

    .o-button.sm {
      margin: -2px 0;
    }
  }
}

// Modal : 상담신청
.advice-form {
  margin-top: 30px;
  border-top: 1px solid #d8d8d8;
  padding-top: 30px;
}

/* ---------------------------------------------------------------------------------------------------------------------
Mypage - 퇴직연금
--------------------------------------------------------------------------------------------------------------------- */
// 계약명
.contract-title {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 -20px;
  padding: 18px 10px;
  // height: 56px;
  background-color: #0076c0;
  font-size: 17px;
  font-family: youandiR;
  color: #fff;
  line-height: 1.2;
  text-align: center;

  .contract-number {
    display: inline-block;
    margin: 0 auto 10px;
    padding: 2px 10px 0;
    border-radius: 60rem;
    background-color: rgba(0, 0, 0, 0.3);
    line-height: 17px;
    font-size: 13px;
  }
}

// 조회 - 퇴직연금현황 - 문의내용
.inquiry-list {
  border-top: 1px solid #b8b8b8;

  &__item {
    position: relative;
    padding-right: 90px;
    border-bottom: 1px solid #eee;
  }

  &__button {
    overflow: hidden;
    display: block;
    width: 100%;
    padding-left: 15px;
    font-size: 15px;
    font-weight: 600;
    text-overflow: ellipsis;
    white-space: nowrap;
    line-height: 52px;
    text-align: left !important;
  }

  &__state {
    position: absolute;
    top: 0;
    right: 20px;
    font-size: 15px;
    color: #666;
    line-height: 52px;

    &--complete {
      color: #0076c0;
    }
  }
}

// 신청/변경 - 투자성향, 운용적립금변경
.investment-head {
  margin: 0 -20px;
  padding: 35px 20px 40px;
  background-color: #f8f8f8;
  text-align: center;

  &__title {
    display: block;
    font-size: 21px;
    font-family: youandiB;
    line-height: 31px;
  }

  &__text {
    display: block;
    font-size: 15px;
    font-family: youandiR;
    line-height: 24px;
    color: #666;
  }

  &__text + &__title,
  &__title + &__text {
    margin-top: 4px;
  }

  .o-button {
    margin-top: 14px;
  }
}

.investment-title {
  display: block;
  margin: 0 -20px;
  padding: 0 20px;
  background-color: #f8f8f8;
  font-size: 13px;
  font-family: youandiB;
  line-height: 42px;
  color: #0076c0;

  &--line {
    border-top: 6px solid #f0f0f0;
  }
}

// 서비스/기타 - 목표수익률 알림 - 수익률 설정
.target-return {
  &__item {
    position: relative;
    padding-left: 40px;
  }

  &__label {
    position: absolute !important;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    padding-left: 25px !important;
  }
}

// Modal : 조회 > 퇴직연금현황 > 나의 문의내용 > 상담신청내역
.advice {
  &__head {
    padding: 20px 0 25px;
    border-bottom: 1px solid #333;
  }

  &__state {
    display: inline-block;
    margin-bottom: 20px;
    padding: 0 5px;
    border-radius: 3px;
    background-color: #666;
    font-size: 15px;
    line-height: 24px;
    color: #fff;

    &--complete {
      background-color: #0076c0;
    }
  }

  &__title {
    display: block;
    font-weight: 600;
    font-size: 17px;
    line-height: 23px;
  }

  &__date {
    display: block;
    margin-top: 6px;
    font-size: 15px;
    color: #666;
  }

  &__question {
    padding: 24px 0;
    font-size: 15px;
    line-height: 27px;
  }

  &__answer {
    padding: 24px 20px 21px 50px;
    border-top: 1px solid #eee;
    border-bottom: 1px solid #eee;
    background: #f8f8f8 url('../img/com/bg_advice_answer.png') no-repeat left 20px top 30px;
    background-size: 18px;
    font-size: 15px;
    line-height: 27px;
  }

  &__empty {
    padding: 100px 0;
    border-top: 1px solid #eee;
    border-bottom: 1px solid #eee;
    background-color: #f8f8f8;
    color: #666;
    text-align: center;
  }

  &__answer-date {
    display: block;
    margin-top: 35px;
    font-size: 15px;
    color: #666;
  }
}

// Modal :  서비스/기타 > 증명서발급 > 미리보기
.preview {
  .c-modal__body &.swiper-container {
    margin: 20px -20px 0 !important;
  }

  &__image {
    width: 100%;
    vertical-align: top;
  }

  &__item {
    width: calc(100% - 50px);
  }

  &__page {
    position: static;
    margin-top: 25px;
    font-family: youandiR;
    font-size: 15px;
    text-align: center;
  }

  .swiper-pagination-current {
    font-family: youandiB;
    color: #008cd6;
  }
}

/* ---------------------------------------------------------------------------------------------------------------------
Mypage - 사고보험금
--------------------------------------------------------------------------------------------------------------------- */
// 사고보험금 청구 - 청구서류 등록
.photo-button {
  display: flex;

  &__item {
    position: relative;
    flex: 1;

    &:nth-child(n + 2) {
      margin-left: 5px;
    }
  }

  &__file {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;

    &:focus + .o-button {
      outline: 1px dotted #767676;
    }
  }
}

.photo {
  margin: 30px -20px 0;
  padding: 35px 0 40px 20px;
  background-color: #f8f8f8;

  &__total {
    display: block;
    font-size: 13px;
  }

  &__list {
    margin-top: 22px;
    font-size: 0;
  }

  &__item {
    position: relative;
    display: inline-block;
    width: 33.33%;
    padding-right: 25px;

    &:nth-child(n + 4) {
      margin-top: 25px;
    }
  }

  &__image {
    overflow: hidden;
    position: relative;
    border: 1px solid #eee;
    padding-top: calc(100% - 2px);
    background: #fff url('../img/com/bg_photo_image.png') no-repeat 50% 50%;
    background-size: 30px auto;

    img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      vertical-align: top;
    }
  }

  &__delete {
    position: absolute;
    top: -5px;
    right: 20px;
    z-index: 1;
    width: 25px;
    height: 25px;
    background: url('../img/com/bg_photo_delete.png') no-repeat 0 0;
    background-size: 25px auto;
  }
}

// 사고보험금 처리현황조회 - 처리현황상세 - 보험금청구 진행현황
.progress-step {
  &__list {
    display: flex;
    height: 85px;
    border-radius: 6px;
    background-color: #f8f8f8;
  }

  &__button {
    position: relative;
    flex: 1;
    font-size: 15px;
    font-family: youandiB;
    line-height: 18px;
    color: #666;

    &:nth-child(n + 2) {
      &::before {
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        display: block;
        width: 1px;
        height: 15px;
        background-color: #d8d8d8;
        content: '';
      }
    }

    &.is-active {
      border-radius: 6px;
      background-color: #0076c0;
      color: #fff;
      box-shadow: 2px 2px 8px 1px rgba(218, 233, 241, 1);

      &::before {
        display: none;
      }

      &::after {
        position: absolute;
        bottom: -4px;
        left: 50%;
        transform: translateX(-50%) rotate(-45deg);
        display: block;
        width: 8px;
        height: 8px;
        background-color: #0076c0;
        content: '';
      }
    }

    &--current {
      color: #0076c0;
    }
  }

  &__number {
    display: block;
    margin-bottom: 2px;
    font-size: 12px;
  }

  &__panel {
    margin-top: 20px;
  }

  &__content {
    padding: 20px;
    border: 1px solid #d8d8d8;
    border-radius: 6px;
  }
}

/* ---------------------------------------------------------------------------------------------------------------------
Mypage - 기본정보 조회/변경
--------------------------------------------------------------------------------------------------------------------- */
// 기본정보 조회/변경 > 변경완료
.change-info {
  margin-top: 46px;
  padding: 25px 10px 30px;
  border: 1px solid #d8d8d8;
  border-radius: 6px;
  text-align: center;

  &__text {
    margin-bottom: 20px;
    font-size: 15px;
    line-height: 23px;
  }
}

// 보험계약건별 안내장 수령지
.contract-receipt {
  margin-top: 18px;
  padding: 24px 0 30px;
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;

  &__item {
    &:nth-child(n + 2) {
      margin-top: 24px;
    }
  }

  &__input + label {
    display: block !important;
    margin-bottom: -14px;

    &::before,
    &::after {
      top: 50% !important;
      transform: translateY(-50%);
    }
  }

  &__number {
    display: block;
    font-size: 13px;
    color: #666;
  }

  &__title {
    display: block;
    margin: -4px 0;
    font-size: 17px;
  }

  &__text {
    display: block;
    margin-top: 3px;
    font-size: 17px;
    color: #666;
  }
}

// Modal : 마케팅동의·안내수신변경 > 마케팅 정보동의 변경
.modal-info-text {
  margin-top: 23px;
  padding: 27px 20px;
  background-color: #f5f5f5;
  font-size: 13px;
  color: #666;
  line-height: 17px;
  text-align: center;
}

/* ---------------------------------------------------------------------------------------------------------------------
Mypage - 증명서
--------------------------------------------------------------------------------------------------------------------- */
// Modal : 이메일 정보
.modal-email {
  position: relative;
  padding: 12px 70px 12px 15px;
  border-top: 1px solid #b8b8b8;
  border-bottom: 1px solid #eee;

  &__text {
    word-break: break-all;
  }

  &__anchor {
    position: absolute;
    top: 50%;
    right: 15px;
    transform: translateY(-50%);
  }
}

// Modal : 수령지 선택
.modal-destination {
  $root: '.modal-destination';

  &__item {
    position: relative;

    & + & {
      margin-top: 10px;
    }
  }

  &__label {
    position: absolute !important;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding-left: 25px !important;

    &::before {
      top: 50% !important;
      left: 15px !important;
      transform: translateY(-50%);
    }

    &::after {
      top: 50% !important;
      left: 24px !important;
      transform: translateY(-50%);
    }
  }

  &__panel {
    border: 1px solid #d8d8d8;
    padding: 10px 15px 10px 50px;
    border-radius: 6px;
  }

  .o-input:checked ~ &__panel {
    border-color: #0076c0;

    #{$root}__title {
      color: #0076c0;
    }
  }

  &__title {
    display: block;
    margin-bottom: 3px;
    font-size: 13px;
  }

  &__text {
    font-size: 17px;
    line-height: 24px;
  }

  &__code {
    color: #0076c0;
  }

  .o-text {
    margin-top: 14px;
  }

  .o-button {
    font-size: 13px !important;
  }
}

/* ---------------------------------------------------------------------------------------------------------------------
Mypage - 개인형 IRP
--------------------------------------------------------------------------------------------------------------------- */
// sub main
.irp-main {
  padding-top: 55px;
  text-align: center;

  &__title {
    font-size: 24px;
    font-family: youandiB;
  }

  &__visual {
    position: relative;
    height: 240px;

    video {
      height: 100%;
    }

    &::after {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      content: '';
    }
  }

  &__text {
    margin-top: 7px;
    font-size: 17px;
    line-height: 26px;
    animation-fill-mode: forwards;
    animation-name: irp-main-text;
    animation-duration: 1s;
  }

  @keyframes irp-main-text {
    from {
      transform: translateY(15px);
      opacity: 0;
    }

    to {
      transform: translateY(0);
      opacity: 1;
    }
  }

  &__anchor {
    margin-top: 50px;
    width: 163px !important;
    font-family: youandiB !important;
    animation-fill-mode: forwards;
    animation-name: irp-main-anchor;
    animation-duration: 2s;
  }

  @keyframes irp-main-anchor {
    from {
      transform: translateY(15px);
    }

    to {
      transform: translateY(0);
    }
  }
}

// 가입 - 상세 - 운용상품선택
.recommend-product {
  position: relative;

  &__label {
    position: absolute;
    top: -26px;
    right: 0;
    padding: 0 13px;
    border-radius: 15px;
    background: linear-gradient(120deg, #0076c0 60%, #00a19c 100%);
    font-family: youandiB;
    line-height: 29px;
    color: #fff;
    animation-fill-mode: forwards;
    animation-name: recommend-product;
    animation-duration: 0.7s;
  }

  @keyframes recommend-product {
    from {
      right: -100%;
    }

    to {
      right: 0;
    }
  }
}

// 가입 - 상세 - 정보확인/약관동의
.online-join {
  overflow: hidden;
  border: 1px solid #d8d8d8;
  border-radius: 6px;

  &__list {
    padding: 21px 20px;
  }

  .inside-list__item {
    align-items: center;

    &:nth-child(n + 2) {
      padding-top: 16px;
      border-top: 1px solid #d8d8d8;
    }
  }

  .inside-list__text {
    width: calc(100% - 110px);
  }

  &__foot {
    padding: 20px 25px 50px;
    background: #f8f8f8 url('../img/com/bg_online_join.png') no-repeat right 25px bottom 33px;
    background-size: 55px auto;
    text-align: center;

    .o-button + .o-button {
      margin-top: 10px;
    }
  }

  &__data {
    margin-top: 35px;
    font-family: youandiB;
    line-height: 24px;
  }

  &__text {
    margin-top: 5px;
    font-family: youandiB;
    line-height: 24px;
    color: #0076c0;
  }
}

// 가입 완료
.irp-complete {
  position: relative;
  margin: 0 -20px -50px;

  &__inside {
    padding: 175px 0 80px;
    background: linear-gradient(120deg, #0076c0 20%, #00a19c 100%);
    text-align: center;
  }

  &__title {
    font-family: youandiB;
    font-size: 23px;
    line-height: 31px;
    color: #fff;
    opacity: 0;
    animation-fill-mode: forwards;
    animation-name: irp-complete-title;
    animation-duration: 3s;
  }

  @keyframes irp-complete-title {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }

  &__text {
    margin-top: 20px;
    font-family: youandiR;
    font-size: 15px;
    line-height: 23px;
    color: #fff;
  }

  &__anchor {
    display: inline-block;
    width: 145px;
    margin-top: 55px;
    border-radius: 6px;
    background-color: #fff;
    font-family: youandiB;
    font-size: 19px;
    color: #0076c0;
    line-height: 56px;
  }

  &__banner {
    display: block;
    position: relative;
    height: 120px;
    padding: 34px 50px 0 30px;
    background-color: #1a3452;
    background-repeat: no-repeat;
    background-image: url('../img/com/bg_irp_banner_left.gif'), url('../img/com/bg_irp_banner_right.gif');
    background-position: 0 0, 100% 0;
    background-size: auto 120px;
    font-family: youandiB;
    font-size: 17px;
    line-height: 25px;
    color: #fbfceb;

    &::after {
      display: block;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 30px;
      width: 0;
      padding: 4px;
      border: solid #fff;
      border-width: 0 2px 2px 0;
      transform: rotate(-45deg);
      content: '';
    }
  }

  .ani-wrap {
    position: absolute;
    top: 90px;
    left: 50%;
    transform: translate(-50%, 0);
    width: 65px;
    height: 46px;
    // background-color: rgba(red, 0.2);

    .line-1 {
      position: absolute;
      bottom: 0;
      left: 0;
      z-index: 1;
      width: 43px;
      height: 39px;
      background: url('../img/com/bg_pmp150601000000_1.png') no-repeat;
      background-size: 43px;
      opacity: 0;
    }

    .line-2 {
      position: absolute;
      bottom: 7px;
      left: 15px;
      width: 50px;
      height: 39px;
      background: url('../img/com/bg_pmp150601000000_2.png') no-repeat;
      background-size: 50px;
      opacity: 0;
    }
  }
}

// modal - 푸본현대생명 개인형 퇴직연금(IRP)이 좋은 이유, 투자성향진단 결과
.modal-irp {
  padding-top: 30px;

  &__heading {
    margin-bottom: 35px;
    font-family: youandiB;
    font-size: 21px;
    line-height: 29px;
  }

  &__item {
    &:nth-child(n + 2) {
      margin-top: 29px;
    }

    &--image {
      position: relative;
      padding-left: 85px;
    }
  }

  &__image {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    width: 65px;

    img {
      width: 100%;
      vertical-align: top;
    }
  }

  &__title {
    display: block;
    margin-bottom: 18px;
    font-family: youandiB;
    font-size: 15px;
  }

  .o-text {
    display: block;
  }

  .o-text--star {
    margin-top: 8px;
  }
}

// modal - 스크래핑
.modal-scraping {
  padding-top: 16px;
  text-align: center;

  &__image {
    display: inline-block;
    width: 65px;
    height: 65px;

    img {
      width: 100%;
    }
  }

  &__title {
    display: block;
    margin-top: 10px;
    font-size: 21px;
    font-family: youandiB;
    line-height: 29px;
  }

  &__text {
    margin-top: 18px;
    font-size: 15px;
    line-height: 27px;
  }

  &__loading {
    display: inline-block;
    width: 50px;
    height: 50px;

    .c-loading__bars {
      position: static;
      transform: translate(0);
    }
  }
}

/* ---------------------------------------------------------------------------------------------------------------------
퇴직연금
--------------------------------------------------------------------------------------------------------------------- */
// IRP란?
.irp-description {
  padding-top: 53px;

  &__title {
    font-size: 24px;
    font-family: youandiB;
    text-align: center;
  }

  &__image {
    width: 100%;
    max-width: 360px;
    margin: 33px auto 25px;
    text-align: center;

    img {
      width: 100%;
      vertical-align: top;
    }
  }

  &__benefit {
    margin: 46px -20px 0;
    padding: 0 20px;
    border-top: 8px solid #f0f0f0;

    .o-heading--1 {
      margin-bottom: 23px;
    }

    .o-heading--3 {
      margin: 23px 0 14px;
    }
  }

  &__example {
    margin: 46px -20px 25px;
    padding: 0 20px 58px;
    border-top: 8px solid #f0f0f0;
    background-color: #f8f8f8;
    text-align: center;
  }

  &__label {
    display: inline-block;
    margin-top: 50px;
    padding: 0 30px;
    border: 1px solid #d8d8d8;
    border-radius: 39px;
    background-color: #fff;
    font-size: 15px;
    line-height: 38px;
  }

  &__underline {
    position: relative;
    z-index: 1;
    display: inline-block;
    font-size: 17px;
    color: #000;

    &::before {
      content: '';
      position: absolute;
      z-index: -1;
      bottom: -10px;
      left: -10px;
      width: calc(100% + 20px);
      height: 21px;
      background-color: #f5f17c;
    }
  }

  &__strength {
    margin: 46px -20px 0;
    padding: 0 20px;
    border-top: 8px solid #f0f0f0;

    .irp-description__strength--video {
      position: relative;
      height: 0;
      padding-bottom: 52.6%;
      margin-top: 28px;
      box-shadow: 0 10px 23px 0px rgba(0, 0, 0, 0.15);
    
      .irp-description__strength--video-poster {
        position: absolute;
        width: 100%;
        z-index: 10;
    
        img {
          width: 100%;
        }
      }
    
      iframe {
        position: absolute;
        width: 100%;
        height: 100%;
      }
    }
  }
}

// 정보 상세
.board-download {
  margin-top: 20px;
  padding: 22px 0;
  border-top: 1px solid #d8d8d8;
  border-bottom: 1px solid #d8d8d8;

  &__item {
    position: relative;

    &:nth-child(n + 2) {
      margin-top: 14px;
    }
  }

  &__text {
    line-height: 19px;
    vertical-align: top;
  }

  .o-icon--pdf {
    vertical-align: top;
  }

  .board-detail__button {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
  }
}

// 시뮬레이션 - 연금계산
.simulation-age {
  $root: '.simulation-age';

  overflow: hidden;
  padding-top: 23px;
  border: 1px solid #e8e8e8;
  border-radius: 8px;
  font-size: 0;
  text-align: center;

  &__item {
    display: inline-block;
    position: relative;

    &:nth-child(n + 2) {
      position: relative;
      margin-left: 50px;
      padding-left: 55px;

      &::before {
        position: absolute;
        top: 50%;
        left: -4px;
        display: block;
        border-width: 4px;
        border-style: solid;
        border-color: transparent #666 #666 transparent;
        transform: translateY(-50%) rotate(-45deg);
        content: '';
      }
    }
  }

  &__image {
    display: block;
    width: 70px;

    img {
      width: 100%;
    }
  }

  &__text {
    display: block;
    margin-top: 4px;
    font-size: 21px;
    font-family: youandiR;
  }
  
  .simulation-result {
    display: flex;
    margin-top: 9px;
    padding: 26px 0;
    background-color: #f8f8f8;

    &__item {
      position: relative;
      flex: 1;
      text-align: center;

      &:nth-child(n + 2)::before {
        position: absolute;
        top: 0;
        left: 0;
        width: 1px;
        height: 100%;
        background-color: #e8e8e8;
        content: '';
      }
    }

    &__title {
      display: block;
      font-size: 13px;
      color: #666;
    }

    &__text {
      display: block;
      margin-top: 1px;
      font-size: 17px;
      color: $blue;
    }
  }
}

// modal : 시뮬레이션 > 예상 은퇴 생활비
.living {
  overflow: hidden;
  margin-top: 23px;
  border: 1px solid #d8d8d8;
  border-radius: 5px;

  &__list {
    padding: 10px 20px;
  }

  &__item {
    position: relative;
    padding: 13px 80px 15px 60px;

    &:nth-child(n + 2) {
      border-top: 1px solid #e8e8e8;
    }

    &--plus {
      margin-top: 21px;

      &::before {
        position: absolute;
        top: -22px;
        left: 0;
        width: calc(100% + 40px);
        height: 22px;
        margin: 0 -20px;
        background: #f8f8f8 url('../img/com/bg_living_plus.png') no-repeat 50% 50%;
        background-size: 10px auto;
        content: '';
      }
    }
  }

  &__image {
    position: absolute;
    top: 50%;
    left: 10px;
    transform: translateY(-50%);
    width: 28px;

    img {
      width: 100%;
      vertical-align: top;
    }
  }

  &__title {
    font-size: 14px;
    font-family: youandiR;
    line-height: 21px;
  }

  &__sub-text {
    display: block;
    font-size: 13px;
    color: #666;
  }

  &__text {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    font-size: 15px;
    font-family: youandiR;
    color: $blue;
    line-height: 21px;
  }

  &__sum {
    padding: 26px 0 24px;
    background-color: $blue;
    font-size: 15px;
    font-family: youandiR;
    text-align: center;
    color: #fff;
  }

  &__highlight {
    display: block;
    font-size: 21px;
  }
}

// modal : 시뮬레이션 > 필요자금 분석결과
.modal-simulation {
  position: relative;
  padding: 45px 0 46px;
  font-size: 0;
  text-align: center;

  &__percent {
    position: absolute;
    top: 52%;
    left: 50%;
    transform: translateX(-50%);
    width: 120px;
    height: 42px;
    padding-top: 2px;
    background: url('../img/com/bg_modal_simulation_percent.png') no-repeat 0 0;
    background-size: 120px auto;
    font-family: youandiR;
    font-size: 13px;
    line-height: 25px;
    color: #fff;
  }

  &__content {
    &:nth-child(n + 2) {
      margin-top: 26px;
      padding-top: 68px;
      border-top: 1px solid #d8d8d8;
    }
  }

  &__heading {
    margin-bottom: 22px;
    font-size: 17px;
    font-family: youandiB;
  }

  &__item {
    display: inline-block;
    width: 50%;
  }

  &__title {
    display: block;
    font-size: 13px;
    color: #666;
  }

  &__text {
    display: block;
    font-size: 17px;
    color: #0076c0;
  }
}

.modal-simulation-text {
  margin: 0 -20px -20px;
  padding: 20px;
  background-color: #f8f8f8;
}

// Modal :  서비스/기타 > 은퇴설계 > 준비자금 분석결과
.analysis-result {
  $root: '.analysis-result';
  margin: 25px -20px 0 !important;

  &__item {
    width: calc(100% - 40px) !important;
    height: 113px !important;
    padding: 21px 20px 0 100px;
    border-radius: 10px;

    &--1 {
      background-color: #cce4f2;

      #{$root}__anchor,
      #{$root}__text {
        color: #004b75;
      }

      #{$root}__anchor {
        border-color: #004b75;
      }
    }

    &--2 {
      background-color: #d9f1f0;

      #{$root}__anchor,
      #{$root}__text {
        color: #007688;
      }

      #{$root}__anchor {
        border-color: #007688;
      }
    }

    &--3 {
      background-color: #3391a0;

      #{$root}__anchor,
      #{$root}__text {
        color: #fff;
      }

      #{$root}__anchor {
        border-color: #fff;
      }
    }
  }

  &__text {
    font-size: 15px;
    font-family: youandiR;
    line-height: 21px;
  }

  &__image {
    position: absolute;
    top: 50%;
    left: 25px;
    transform: translateY(-50%);
    width: 61px;

    img {
      width: 100%;
    }
  }

  &__anchor {
    display: inline-block;
    margin-top: 7px;
    font-size: 14px;
    border-bottom: 1px solid #fff;

    &::before {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      content: '';
    }
  }
}

/* ---------------------------------------------------------------------------------------------------------------------
혜택서비스
--------------------------------------------------------------------------------------------------------------------- */
// 고객 혜택
.benefit-text {
  margin: 0 -20px 47px;
  padding: 45px 0;
  background: linear-gradient(120deg, #0076c0 60%, #00a19c 100%);
  font-size: 21px;
  font-family: youandiR;
  line-height: 32px;
  color: #fff;
  text-align: center;

  &__highlight {
    display: block;
    font-family: youandiB;
  }
}

.benefit-list {
  margin-top: 50px;

  &__item {
    position: relative;
    padding-left: 70px;

    &:nth-child(n + 2) {
      margin-top: 35px;
    }
  }

  &__image {
    position: absolute;
    top: 0;
    left: 10px;
    width: 50px;

    img {
      width: 100%;
    }
  }

  &__title {
    display: block;
    font-size: 17px;
    font-family: youandiR;
  }

  &__text {
    margin-top: 5px;
    font-size: 15px;
    line-height: 24px;
    color: #666;
  }
}

.benefit-discount {
  height: 218px;
  margin-top: 50px;
  padding: 44px 20px 0;
  border-radius: 10px;
  background: #f9f9f9 url('../img/com/bg_benefit_discount.gif') no-repeat 50% 100%;
  background-size: 375px auto;
  text-align: center;

  &__heading {
    display: block;
    font-size: 19px;
    font-family: youandiB;
  }

  &__title {
    display: block;
    margin-top: 18px;
    font-size: 18px;
  }

  &__text {
    margin-top: 5px;
    font-size: 15px;
    color: #666;
  }
}

// 헬스케어서비스
.health-head {
  margin: 0 -20px;
  padding: 45px 20px;
  background: linear-gradient(120deg, #0076c0 60%, #00a19c 100%);

  &__text {
    display: block;
    font-family: youandiR;
    font-size: 18px;
    color: #fff;
  }

  &__title {
    display: block;
    margin-top: 2px;
    font-family: youandiB;
    font-size: 24px;
    color: #fff;
  }

  &__description {
    margin-top: 17px;
    font-size: 18px;
    color: #fff;
  }
}

.health-care {
  margin-top: 50px;

  &__item {
    &:nth-child(n + 2) {
      margin-top: 46px;
    }
  }

  &__heading {
    height: 50px;
    border-radius: 3px;
    background-color: $blue;
    font-family: youandiR;
    font-size: 18px;
    text-align: center;
    color: #fff;
    line-height: 50px;
  }

  &__title {
    display: block;
    margin-top: 25px;
    font-family: youandiR;
    font-size: 17px;
  }

  &__text {
    margin-top: 15px;
    font-size: 16px;
    color: #666;
  }

  &__service {
    margin-top: 16px;
    padding: 10px 10px 20px;
    border-radius: 5px;
    background-color: #e5f1f9;
    text-align: center;
  }

  &__label {
    display: inline-block;
    margin: 10px 3px 0;
    padding: 0 15px;
    border: 1px solid #72b3dc;
    border-radius: 15px;
    background-color: #fff;
    font-size: 16px;
    color: $blue;
    line-height: 28px;
  }
}

// H-Coin
.hcoin-info-wrap {
  background-color: #272a44;

  .info-wrap__highlight {
    color: #ff8300;
  }
}

.hcoin-product-wrap {
  background-color: #272a44;
}

.hcoin-complete {
  margin: 0 -20px -80px;
  padding: 70px 0;
  background-color: #272a44;
  text-align: center;

  &__title {
    display: block;
    width: 187px;
    margin: 0 auto;

    img {
      width: 100%;
    }
  }

  &__image {
    display: block;
    width: 70px;
    margin: 30px auto 0;

    img {
      width: 100%;
    }
  }

  &__text {
    margin-top: 21px;
    font-size: 19px;
    line-height: 27px;
    color: #fff;
  }

  &__anchor {
    display: inline-block;
    width: 215px;
    margin: 26px auto 0;
    border-radius: 3px;
    background-color: #ff8300;
    font-size: 15px;
    font-family: youandiB;
    color: #fff;
    line-height: 43px;
    text-align: center;
  }
}

.hcoin-join {
  margin: 0 -20px;
  padding: 60px 0 60px;
  background-color: #272a44;
  text-align: center;

  &__image {
    display: inline-block;
    width: 105px;

    img {
      width: 100%;
    }
  }

  &__title {
    display: block;
    margin: 28px 0 20px;
    font-size: 30px;
    font-family: youandiB;
    line-height: 40px;
    color: #fff;
  }

  &__label {
    display: inline-block;
    font-size: 15px;
    color: #fff;

    & + & {
      margin-left: 10px;
    }
  }

  &__anchor {
    display: block;
    width: 215px;
    margin: 36px auto 0;
    border: 1px solid #ff8300;
    border-radius: 3px;
    font-size: 15px;
    font-family: youandiB;
    color: #ff8300;
    line-height: 43px;
  }
}

.hcoin-info {
  padding-top: 53px;
  text-align: center;

  &__title {
    display: block;
    font-size: 30px;
    font-family: youandiB;
    color: #272a44;
    line-height: 40px;
  }

  &__text {
    margin-top: 27px;
    font-size: 17px;
    line-height: 26px;
  }
}

.hcoin-item {
  margin: 55px -20px 0;
  padding: 49px 20px 0;
  border-top: 8px solid #f0f0f0;

  &__title {
    display: block;
    font-size: 21px;
    font-family: youandiB;
  }

  &__text {
    margin-top: 13px;
    font-size: 17px;
    line-height: 26px;
  }

  &__anchor {
    border: 1px solid #b8b8b8 !important;
    color: #000;
  }
}

.hcoin-save {
  $root: '.hcoin-save';

  overflow: hidden;
  position: relative;
  margin-top: 32px;
  border-radius: 6px;

  &::before {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 104px;
    content: '';
  }

  &__inside {
    position: relative;
    z-index: 1;
    padding: 39px 0 129px;
    text-align: center;

    &::before,
    &::after {
      position: absolute;
      bottom: 92px;
      width: 16px;
      height: 16px;
      border-radius: 50%;
      background-color: #fff;
      content: '';
    }

    &::before {
      left: -8px;
    }

    &::after {
      right: -8px;
    }
  }

  &__title {
    display: block;
    font-family: youandiB;
    font-size: 21px;
    line-height: 30px;
    color: #fff;
  }

  &__number {
    display: inline-block;
    width: 200px;
    height: 40px;
    margin-top: 9px;
    border-radius: 20px;
    background-color: #ee7000;
    font-size: 21px;
    font-family: youandiB;
    color: #272a44;
    line-height: 40px;
  }

  &__text {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 104px;
    padding-top: 27px;
    font-size: 17px;
    line-height: 24px;
    color: #fff;
  }

  &--new {
    background-color: #272a44;

    &::before {
      background-color: #181b31;
    }
  }

  &--mpoint {
    background-color: #333;

    &::before {
      background-color: #000;
    }

    #{$root}__inside {
      background: url('../img/com/bg_hcoin_save_mpoint.gif') no-repeat left 50% bottom 50px;
      background-size: 158px auto;
    }
  }
}

.hcoin-use {
  overflow-x: auto;
  margin: 30px -20px 0;
  padding: 0 20px;
  white-space: nowrap;

  &__item {
    display: inline-block;
    width: 260px;
    height: 220px;
    padding: 40px 0 0 17px;
    border-radius: 7px;
    background-color: #f8f8f8;

    &:nth-child(n + 2) {
      margin-left: 12px;
    }
  }

  &__logo {
    display: inline-block;
    height: 21px;

    img {
      width: auto;
      height: 100%;
    }
  }

  &__title {
    display: block;
    margin: 5px 0 24px;
    font-size: 15px;
    font-family: youandiB;
    line-height: 24px;
  }

  .o-button + .o-button {
    margin-left: 2px;
  }
}

.agree-title {
  margin: 35px 0 18px;
  padding-left: 30px;
  background: url('../img/com/bg_agree_title.png') no-repeat 0 50%;
  background-size: 14px auto;
  font-size: 15px;
  font-family: youandiB;
}

// 이벤트
.event-list {
  margin-top: 50px;

  &__item {
    &:nth-child(n + 2) {
      margin-top: 31px;
    }
  }

  &__inside {
    position: relative;
  }

  &__image {
    display: block;
    text-align: center;

    img {
      // max-width: 100%;
      width: 100%;
      border-radius: 6px;
    }
  }

  &__anchor {
    display: block;
    margin-top: 11px;
    font-size: 17px;
    font-family: youandiR;
    line-height: 24px;

    &::after {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      content: '';
    }
  }

  &__text {
    margin-top: 7px;
    font-size: 13px;
    color: #666;
  }

  &__date {
    display: block;
    margin-top: 12px;
    font-size: 13px;
  }
}

.event-view {
  &__head {
    position: relative;
    padding-right: 70px !important;
  }

  &__label {
    position: absolute;
    top: 27px;
    right: 0;
    display: inline-block;
    padding: 0 9px;
    border: 1px solid #eee;
    border-radius: 10px;
    background-color: #f8f8f8;
    font-size: 13px;
    font-weight: 600;
    color: #666;
    line-height: 18px;
  }
}

/* ---------------------------------------------------------------------------------------------------------------------
로그인
--------------------------------------------------------------------------------------------------------------------- */
.login {
  $root: '.login';

  margin: 0 -20px -50px;
  padding: 74px 0 105px;
  text-align: center;

  // 간편비밀번호
  &--type1 {
    background-color: #89d6d4;
  }

  // 카카오패이
  &--type2 {
    background-color: #fae104;
  }

  // 지문인증, FACE ID 인증, 공인인증서, 휴대폰
  &--type3 {
    background-color: #e9ecf6;

    #{$root}__button {
      background-color: #0076c0 !important;
      color: #fff !important;
    }
  }

  &__title {
    font-family: youandiB;
    font-size: 21px;
    line-height: 29px;
    height: 93px;
  }

  &__title-text {
    display: block;
    font-family: initial;
    font-size: 15px;
  }

  &__content {
    height: 170px;
  }

  &__button {
    width: 280px;
    background-color: #fff !important;
    border-radius: 6px !important;
    font-family: youandiB;
    font-size: 17px;
    line-height: 56px;
    box-shadow: 0 14px 20px -7px rgba(0, 0, 0, 0.1);
  }

  &__etc {
    margin-top: 20px;
    font-size: 15px;
  }

  &__link {
    display: inline-block;
    font-size: 15px;
    font-weight: 600;
    line-height: 22px;
    text-decoration: underline !important;

    &:nth-child(n + 2) {
      position: relative;
      margin-left: 38px;

      &::before {
        position: absolute;
        top: 5px;
        left: -21px;
        width: 1px;
        height: 13px;
        background-color: #b2b2b2;
        content: '';
      }
    }
  }

  &__password {
    position: relative;
    width: 280px;
    height: 56px;
    margin: 0 auto;
    background-color: #fff;
    border-radius: 6px;
    box-shadow: 0 14px 20px -7px rgba(0, 0, 0, 0.1);
  }

  &__input {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100% !important;
    border: 1px solid #0076c0 !important;
    border-radius: 6px !important;
    background-color: transparent !important;
    color: transparent !important;

    &.has-error {
      border-color: #ff3c50 !important;
    }
  }

  &__dots {
    padding-top: 20px;
    font-size: 0;
  }

  &__dot {
    display: inline-block;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-color: #c8c8c8;

    &:nth-child(n + 2) {
      margin-left: 15px;
    }

    &.is-active {
      background-color: #0076c0;
    }
  }

  &__error {
    margin-top: 18px;
    font-size: 15px;
    color: #ff3c50;
    line-height: 18px;

    .o-icon {
      vertical-align: top;
    }
  }
}

.login-list {
  &__item {
    &:nth-child(n + 2) {
      margin-top: 19px;
    }
  }

  &__anchor {
    overflow: hidden;
    display: inline-block;
    padding: 0 7px;
    font-family: youandiB;
    font-size: 15px;
    color: #333;

    &.is-active {
      position: relative;
      z-index: 1;
      color: #000;

      &::before {
        content: '';
        position: absolute;
        z-index: -1;
        bottom: 0;
        left: -100%;
        width: 100%;
        height: 11px;
        background-color: rgba(255, 255, 255, 0.8);
        animation-fill-mode: forwards;
        animation-name: login-list-anchor;
        animation-duration: 0.7s;
      }

      @keyframes login-list-anchor {
        from {
          left: -100%;
        }

        to {
          left: 0;
        }
      }
    }
  }
}

.captcha {
  margin-top: 30px;
  padding-top: 30px;
  border-top: 1px solid #eee;

  &__panel {
    overflow: hidden;
    position: relative;
    height: 76px;
    margin-bottom: 10px;
    padding-right: 80px;
    border: 1px solid #d8d8d8;
    border-radius: 5px;
  }

  &__image {
    background-color: #f0f0f0;
    text-align: center;
    height: 74px;
  }

  &__button {
    position: absolute;
    top: 0;
    right: 1px;
    width: 80px;
    height: 100%;
    border-left: 1px solid #d8d8d8;
    font-size: 13px;
  }
}

.owner-agree {
  margin: 50px -20px 0;
  padding: 35px 20px 40px;
  background-color: #f8f8f8;
  text-align: center;

  &__title {
    display: block;
    margin-bottom: 21px;
    font-size: 17px;
  }
}

/* ---------------------------------------------------------------------------------------------------------------------
Sub main
--------------------------------------------------------------------------------------------------------------------- */
// 보험상품, 퇴직연금
.product-main-head {
  margin: 0 -20px;
  padding: 55px 0 60px;
  background: linear-gradient(120deg, #0076c0 60%, #00a19c 100%);
  text-align: center;
  overflow: hidden;
  color: $white;

  &__title {
    overflow: hidden;
    height: 32px;
    padding: 0 20px;
    font-family: 'youandiB';
    font-size: 24px;
    line-height: 32px;

    &--line2 {
      height: 64px;
    }
  }

  &__animation {
    display: block;
    position: relative;
    top: 100%;
    animation: ani-up 0.5s forwards;
  }

  @keyframes ani-up {
    to {
      top: 0;
    }
  }

  &__text {
    display: inline-block;
    margin: 15px 20px 0;
    padding: 3px 15px;
    min-height: 30px;
    border: 1px solid #87d6ff;
    border-radius: 15px;
    font-family: 'youandiR';
  }
}

.product-main-slider {
  overflow: initial !important;
  margin-top: 30px;

  .swiper-slide {
    width: calc(100% - 106px);
    // width: 58.666%;
    transition: opacity 0.5s;

    &-prev {
      opacity: 0;
    }

    &-next {
      opacity: 0.5;
    }

    &-active {
      opacity: 1;
    }
  }

  &__title {
    position: relative;
    display: inline-block;
    padding: 0 7px;
    margin: 0 auto 20px;
    font-family: 'youandiB';
    font-size: 21px;
    color: $white;

    &::before {
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      display: inline-block;
      width: 0;
      height: 16px;
      box-sizing: initial;
      background-color: #0070ab;
      z-index: -1;
      transition: width 0.5s 0.2s;
    }

    .swiper-slide-active &::before {
      width: 100%;
    }
  }
}

.product-main-card {
  $root: '.product-main-card';

  display: block;
  position: relative;
  width: 100%;
  height: 200px;
  border-radius: 20px;
  background-color: $white;
  box-shadow: 20px 25px 40px rgba(0, 0, 0, 0.14);
  font-size: 13px;
  color: $black;

  &__text {
    padding: 8px 10px 0;
    font-family: 'youandiB';
    font-size: 17px;
    line-height: 1.4;
  }

  &__sub-text {
    display: block;
    position: relative;
    z-index: 1;
    margin-top: 10px;
    font-family: initial;
    font-size: 13px;
    line-height: 13px;
  }

  &__bg {
    position: absolute;
    bottom: 0;
    left: 50%;
    display: block;
    width: 220px;
    transform: translateX(-50%);

    img {
      width: 100%;
      vertical-align: top;
    }
  }

  &__image {
    overflow: hidden;
    display: inline-block;
    width: 80px;
    height: 80px;
    margin-top: 30px;

    img {
      width: 100%;
      vertical-align: top;
    }
  }

  &--search {
    .icon-cross {
      position: relative;
      display: block;
      width: 26px;
      height: 26px;
      margin: 0 auto;
      transform: rotate(0deg);
      transition: transform 0.5s 0.1s ease-in;

      &::before {
        display: block;
        margin: 0 auto;
        width: 6px;
        height: 26px;
        background-color: #0070ab;
        content: '';
      }

      &::after {
        display: block;
        position: absolute;
        top: 10px;
        left: 0;
        width: 26px;
        height: 6px;
        background-color: #00a19c;
        content: '';
      }

      .swiper-slide-active & {
        transform: rotate(360deg);
      }
    }

    #{$root}__text {
      padding-top: 16px;
      font-size: 17px;
    }

    #{$root}__shortcut {
      display: inline-block;
      margin-top: 20px;
      border-bottom: 1px solid #000;
      color: $black;
    }
  }
}

.product-main-banner {
  $root: '.product-main-banner';

  display: none;
  position: fixed;
  left: 120px;
  right: 120px;
  z-index: 100;
  width: auto;
  height: 64px;
  bottom: 20px;
  padding: 10px;
  border-radius: 50px;
  background-color: #333;
  font-family: 'youandiR';
  text-align: center;
  color: $white;
  transition: left, right 0.8s cubic-bezier(0.66, 0.1, 0.43, 1.11);

  &.shown {
    left: 20px;
    right: 20px;
    bottom: 20px;
    opacity: 1;
    transition: 0.5s;

    #{$root}__text {
      opacity: 1;
    }

    &.slide-down {
      bottom: -100px;
      opacity: 0;
      transition: 0.5s;
    }
  }

  &__text {
    opacity: 0;
    transition: opacity 0.2s 0.5s;
  }

  &__highlight {
    display: block;
    font-family: youandiB;
    color: #69fffb;
  }
}

.product-main-menu {
  margin: 0 -20px -50px;

  &__head {
    overflow: hidden;
    display: block;
    position: relative;
    padding: 32px 20px;

    a {
      &::before {
        position: absolute;
        top: 50%;
        right: 20px;
        display: inline-block;
        margin-left: auto;
        width: 3px;
        height: 3px;
        padding: 4px;
        border: solid #000;
        border-width: 0 2px 2px 0;
        transform: translateY(-50%) rotate(-45deg);
        content: '';
      }

      &::after {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        content: '';
      }
    }

    & ~ & {
      border-top: 8px solid #f0f0f0;
    }
  }

  &__title {
    display: inline-block;
    // font-size: 14px;
    font-family: youandiB;
    vertical-align: top;
  }

  &__text {
    display: block;
    margin-top: 4px;
    font-size: 14px;
    color: #666;
  }

  &__list {
    margin: -8px 20px 0;
    padding: 17px 0;
    border-top: 1px solid #eee;
  }

  &__item {
    position: relative;

    &::before {
      content: '';
      position: absolute;
      top: 50%;
      right: 0;
      display: inline-block;
      margin-left: auto;
      width: 3px;
      height: 3px;
      padding: 4px;
      border: solid #000;
      border-width: 0 2px 2px 0;
      transform: translateY(-50%) rotate(-45deg);
    }
  }

  &__anchor {
    display: block;
    padding: 14px 0;
    font-size: 17px;
  }
}

// 고객안내
.customer-main-menu {
  margin: 0 -20px;
  padding: 40px 20px;
  background: linear-gradient(120deg, #0076c0 60%, #00a19c 100%);

  &__item {
    position: relative;
    padding: 30px 90px 30px 25px;
    border-radius: 15px;
    background-color: #fff;

    &:nth-child(n + 2) {
      margin-top: 15px;
    }
  }

  &__anchor {
    font-size: 17px;
    font-family: 'youandiB';

    &::after {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      content: '';
    }
  }

  &__text {
    margin-top: 7px;
    font-size: 14px;
    font-family: 'youandiR';
    line-height: 21px;
    color: #666;
  }

  &__image {
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
    width: 50px;

    img {
      width: 100%;
      vertical-align: top;
    }
  }
}

.customer-main-ars {
  margin: 0 -20px;
  padding: 31px 0 36px;
  background-color: #1d4c64;
  text-align: center;

  &__anchor {
    margin-bottom: 10px;
    font-size: 27px;
    font-family: 'youandiB';
    color: #fff;
  }

  &__text {
    margin-top: 7px;
    font-size: 15px;
    color: #fff;
  }
}

.customer-main-notice {
  margin-top: 40px;
  text-align: center;

  &__anchor {
    overflow: hidden;
    display: inline-block;
    width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__title {
    display: inline-block;
    margin-right: 12px;
    padding: 0 9px;
    border: 1px solid #eee;
    border-radius: 10px;
    background-color: #f8f8f8;
    font-size: 13px;
    color: #666;
    line-height: 20px;
    vertical-align: middle;
  }

  &__text {
    font-size: 17px;
    text-decoration: underline !important;
    vertical-align: middle;
  }
}

/* ---------------------------------------------------------------------------------------------------------------------
Mypage - 계약조회
--------------------------------------------------------------------------------------------------------------------- */
// 통합계약조회 보장내역
.c-table--guarantee {
  thead th,
  tbody td {
    padding: 5px;
    font-size: 12px;
    line-height: 16px;
    font-weight: normal;
  }

  td:last-child {
    text-align: right;
  }
}
