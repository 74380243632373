.c-table {
  $root : ".c-table";

  position: relative;
  margin-top: 25px;
  border-top: 1px solid #b8b8b8;

  &__text {
    padding: 11px 0 9px;
    background-color: #fff;
    font-family: youandiB;
    font-size: 13px;
    line-height: 20px;
    color: #000;
    text-align: center;

    &--product {
      color: #333;
      text-align: left;
    }

    & + table,
    & + .table-fixed {
      border-top: 1px solid #d2d2d2;
    }
  }

  table {
    width: 100%;
    border-collapse:collapse;
  }

  th,
  td {
    padding: 12px 15px;
    height: 43px;
    border-bottom: 1px solid #eee;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: -0.2px;
  }

  th {
    font-weight: normal;
    color: #666;
    text-align: left;
  }

  thead th {
    font-size: 15px;
  }

  td {
    background-color: #fff;
    color: #000;
    text-align: left;
  }

  &__info {
    display: none;

    &.is-active {
      display: block;
      position: absolute;
      bottom: 0;
      left: 0;
      z-index: 2;
      width: 100%;
      height: calc(100% - 1px);
      background: rgba(255, 255, 255, .85);
      content: "";
    }
  }

  &__info-text {
    position: absolute;
    top: 18px;
    left: 50%;
    z-index: 2;
    padding-top: 68px;
    background: url("../img/com/img_table_info.gif") no-repeat 50% 0;
    background-size: 64px auto;
    transform: translateX(-50%);
    @include h3-hb;
    line-height: 23px;
    color: #000;
    text-align: center;

    strong {
      color: #000;
    }
  }

  &--row {
    th {
      background-color: #f8f8f8;
    }

    tbody th {
      border-right: 1px solid #eee;
      vertical-align: top;
    }
  }

  &--col {
    thead {
      th {
        background-color: #f8f8f8;
        text-align: center;
      }

      tr:only-child {
        th {
          border-right: none;
        }
      }
    }

    th,
    td {
      border-right: 1px solid #eee;

      &:last-child {
        border-right: none;
      }
    }
  }

  &--highlight {
    overflow: hidden;
    border: 1px solid #0076c0;
    border-radius: 6px;
    background-color: #f8f8f8;

    th {
      background-color: transparent;
    }

    tr:last-child th,
    tr:last-child td {
      border-bottom: none;
    }

    td {
      color: #0076c0;
    }

    &#{$root}--col {

      thead th {
        border-bottom: 1px solid #eee;
      }

      tbody th {
       background-color: $white;
      }
    }
  }

  &--center {
    th,
    td {
      text-align: center;
    }
  }

  &--scroll {
    overflow-x: auto;
    padding-bottom: 20px;
  }

  &--line {
    thead th:not(:first-child),
    td {
      border-left: 1px solid #eee;
    }

    &.c-table--row tbody th {
      background-color: #f8f8f8;
    }
  }

  .o-button.sm:only-child {
    margin: -10px 0 -6px;
  }

  br + .o-button.sm {
    margin-top: 13px;
  }

  .input-wrap + &,
  .c-accordion--type1 .c-accordion__inside > &:first-child {
    margin-top: 0;
  }
}

.table-fixed {
  display: flex;
  position: relative;

  &__left {
    flex: none;
    position: relative;
    z-index: 1;

    table {
      width: auto;
      border-right: 1px solid #eee;
    }

    thead {
      th {
        border-right: 1px solid #eee !important;
      }
    }
  }

  &__right {
    flex: 1;
    overflow-y: auto;
    padding-bottom: 20px;
  }
}
