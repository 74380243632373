// 공통
// Link Button in Table
.c-table .o-button.link {
  vertical-align: top;
}

// ==========================================
// ================ 00.공통 =================
// [00.공통 - 로그인 - 카카오페이지 인증] - 전체동의+아코디언 타입 - com020201010000
input[data-toggle="check-all"] + label + .c-accordion--type2 {
  margin-top: 15px;
}

// ==========================================
// ============== 01.마이페이지 ==============
// [01.마이페이지 - 계약변경 - 필요서류안내] downloadList - pmp091000000000
.download-list {
  $root: ".download-list";

  &__item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 -20px;
    padding: 30px 20px;
    border-bottom: 1px solid #d8d8d8;

    #{$root}__title {
      padding-right: 30px;
      font-family: youandiR;
    }

    .o-button {
      min-width: 109px;
      margin-left: auto;
    }
  }
}

// [01.마이페이지 - 계약변경 - 보험료감액] product-card / price-input - pmp090101000000
.price-input .inside-list__title {
  width: 124px;
  color: $black;
}

// ========================================
// ============== 02.보험상품 ==============
// [건강보장]나를위한암보험, 암보험, 성인병, 메디컬, 치아, 정기
// [사망보장]종신, 종신라이트, 종신선지급
// [노후자금]연금, 연금하이파이브
// [목적자금]저축
// [기업복지]단체상해, 연금, 연금저축

// [02.보험상품 공통] 보장내역 - 보장내용, 보험료예시, 해지환급금예시
.guarantee-info {
  padding: 30px 22px;
  font-size: 14px;
  color: $white;
  background-color: #0076c0;
  border-radius: 5px;

  *:nth-of-type(n+2) {
    margin-top: 20px;
  }

  .guarantee-info__ref {
    font-weight: 700;
  }
}

// [02.보험상품 공통] 안내사항
.source-box {
  margin: 43px 0 20px;
  padding: 18px 0 28px;
  text-align: center;
  background-color: #f5f5f5;
}

// ========================================
// ============== 03.금융상품 ==============
// [03.금융상품] SubMain - fp010000000000
.loan-main {
  $root: ".loan-main";

  &__top {
    margin: 0 -20px;
    padding: 50px 20px;
    text-align: center;
    background: #f8f8f8;

    #{$root}__text {
      margin-top: 20px;
      text-align: center;
      line-height: 24px;
      font-family: $font-family-sans-serif;
      font-size: 15px;
      color: #666;
    }
  }

  &__maincopy {
    font-size: 24px;
    font-family: youandiB;

    span {
      display: block;
      color: #0076c0;
    }
  }
}

.loan-menu {
  margin: 0 -20px;
  padding: 38px 20px;
  background: linear-gradient(120deg, #0076c0 60%, #00a19c 100%);

  &__item {
    padding: 25px 25px 40px;
    border-radius: 15px;
    background: #fff;

    & + & {
      margin-top: 18px;
    }
  }

  &__anchor {
    position: relative;
    display: flex;
    width: 100%;
    padding: 10px 60px 22px 0;
  }

  &__img {
    position: absolute;
    top: 11px;
    right: 0;
    width: 50px;

    img {
      width: 100%;
    }
  }

  &__title {
    color: #666;
    font-size: 13px;
    font-family: youandiR;

    strong {
      position: relative;
      display: inline-block;
      padding-right: 14px;
      margin-bottom: 5px;
      font-size: 19px;
      font-family: youandiB;
      color: #000;

      &::after {
        content: "";
        position: absolute;
        top: 50%;
        right: 0;
        width: 8px;
        height: 8px;
        border-bottom: 2px solid #000;
        border-right: 2px solid #000;
        transform: translateY(-50%) rotate(-45deg);
      }
    }
  }

  .inside-list {
    border-top: 1px solid #666;

    &__item {
      align-items: center;
      padding: 10px 0;
      border-bottom: 1px solid #eee;

      &:nth-child(n+2) {
        margin-top: 0;
      }
    }

    &__title {
      color: $black;
    }

    &__text {
      font-family: youandiB;
      font-size: 26px;
      color: #0076c0;

      small {
        font-size: 19px;
      }
    }
  }
}

// [03.금융상품] 상세페이지(보험계약대출, 주택담보대출) - fp020000000000, fp030000000000
.product-submain.loan {
  .product-submain-head__title + .product-submain-head__text {
    font-size: 15px;
  }

  .product-submain-detail {
    padding: 60px 20px;
  }
}

.product-submain {
  .percent-list {
    @include clearfix;
    display: flex;
    margin: 22px -5px 40px;
  
    li {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 33.33%;
      padding: 25px 5px;
      margin: 0 5px;
      text-align: center;
      border-top: 2px solid #00a39f;
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
      font-size: 14px;
      line-height: 19px;
      color: #666;
      background: #f8f8f8;
    }
  
    .num {
      flex: 1;
      display: flex;
      align-items: center;
      margin-bottom: 13px;
      color: $black;
      font-size: 19px;
      font-family: youandiB;
    }
  }
}

// [03.금융상품] 지도영역 - 주택담보대출(서울, 부산, 중부, 안양) - cusi160101000000
.map {
  width: 100%;
  height: 200px;
  margin-top: 26px;
  overflow: hidden;
}

// ==========================================
// =============== 04.퇴직연금 ===============
// [04.퇴직연금] 원리금보장형상품, 실적배당형상품 - IRP030100000000, IRP030100000000
.info-box__irp {
  $root: ".info-box__irp";
  margin-top: 30px;
  overflow: hidden;
  border: 1px solid #d8d8d8;
  border-radius: 6px;
  background-color: $white;

  &__tit {
    font-size: 17px;
  }

  &__head {
    padding: 28px 20px 14px;
  }

  &__content {
    padding: 15px 20px;
    background: #f8f8f8;
  }

  &--type2 {
    padding: 25px 19px;
  }
}