// 배너
.link-box {
  position: relative;
  margin-top: 30px;
  padding: 20px;
  border-radius: 10px;

  $root: ".link-box";

  &__text {
    color: $white;
  }

  &__button {
    margin-top: 10px;

    &::before {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      content: '';
    }
  }

  &__image {
    &::before {
      position: absolute;
      top: 50%;
      left: 20px;
      width: 50px;
      height: 60px;
      content: '';
      transform: translateY(-50%);
    }

    ~ .link-box__text,
    ~ .link-box__button {
      margin-left: 70px;
    }
  }

  &.green {
    background: #008100;

    #{$root}__image {
      &::before {
        background: url(../img/com/bg_link_box.gif) no-repeat 50% 50%;
        background-size: 100%;
      }
    }
  }

  &.gray {
    background: #999;
  }

  &--event {
    padding: 0;
    border-radius: 0;
    margin: 0;

    a {
      display: block;

      img {
        width: 100%;
      }
    }
  }
}

// 게시판
.board-list {
  margin: 0 -20px 40px;
  font-size: 0;

  $root: '.board-list';

  &__item {
    position: relative;
    padding: 25px 20px 21px;
    border-top: 1px solid #d8d8d8;
    background-color: $white;
    font-size: 15px;

    &:last-of-type {
      border-bottom: 1px solid #d8d8d8;
    }
  }

  &__title {
    display: block;

    &:not(:only-child) {
      margin-bottom: 5px;
    }
  }

  a#{$root}__title {
    position: relative;
    padding-right: 30px;

    &::after {
      position: absolute;
      top: 7px;
      right: 2px;
      display: block;
      padding: 3px;
      width: 0;
      border: solid #666;
      border-width: 0 2px 2px 0;
      content: '';
      transform: rotate(-45deg);
    }
  }

  &__sub {
    #{root}__button {
      vertical-align: text-top;
    }
  }

  &__text {
    display: inline-block;
    color: #666;
    line-height: 30px;

    & + & {
      position: relative;
      margin-left: 6px;
      padding-left: 10px;

      &::before {
        position: absolute;
        top: 50%;
        left: 0;
        width: 1px;
        height: 9px;
        background-color: #d8d8d8;
        content: '';
        transform: translateY(-50%);
      }
    }
  }

  &__button {
    margin: 11px 0 9px;
    padding: 0;
    width: 30px;
    height: 30px;
    line-height: 30px;
    border-radius: 3px;
    font-size: 15px;
    font-family: youandiR;
    vertical-align: unset;

    [class^='o-icon'] {
      margin-left: 0;
    }

    & + & {
      margin-left: 7px;
    }

    #{$root}__text + & {
      margin-left: 20px;
    }
  }

  + .link-box {
    margin-top: 50px;
  }
}

.board-detail,
.event-view {
  width: 100%;
  overflow: hidden;
  $root: 'board-detail';

  &__head {
    padding: 25px 0;
  }

  &__content {
    padding: 26px 0;
    border-top: 2px solid #333;

    img {
      max-width: 100%;
    }
  }

  &__title {
    display: block;
    line-height: 26px;

    @include h2-hb;

    &:not(:only-child) {
      margin-bottom: 8px;
    }
  }

  &__sub {
    margin: 14px 0 7px;

    #{root}__button {
      vertical-align: text-top;
    }
  }

  &__text,
  &__date {
    display: inline-block;
    margin-right: 20px;
    color: #666;
    line-height: 26px;
  }

  &__button {
    padding: 0;
    width: 29px;
    height: 26px;
    border-radius: 3px;
    font-size: 15px;
    font-family: youandiR;
    line-height: 26px;

    [class^='o-icon'] {
      margin-left: 0;
    }

    & + & {
      margin-left: 7px;
    }
  }

  ~ .c-button-wrap.lg {
    .o-button {
      margin-top: 0;
    }
  }
}

.c-modal {
  .c-modal__body {
    > .board-list:first-child,
    > .board-detail:first-child {
      margin-top: -20px;
    }
  }
}

// 고객안내 - 지점안내
.info-box__customer {
  overflow: hidden;
  margin-top: 30px;
  border: 1px solid #d8d8d8;
  border-radius: 6px;
  background-color: $white;

  $root: '.info-box__customer';

  & + & {
    margin-top: 10px;
  }

  #{$root}__head {
    padding: 14px 20px;
    text-align: center;
  }

  #{$root}__text {
    color: $blue;
    font-size: 21px;
    font-family: 'youandiB';
  }

  #{$root}__sub {
    display: inline-block;
    margin-right: 9px;
    padding: 0 5px;
    height: 20px;
    border-radius: 4px;
    background: $blue;
    color: $white;
    vertical-align: text-top;
    font-size: 13px;
    font-family: 'youandiR';
    line-height: 21px;
  }

  #{$root}__content {
    padding: 7px 20px;
    border-top: 1px solid #d8d8d8;
    background-color: #f8f8f8;
    text-align: center;

    .c-text-list {
      display: inline-block;

      @include clearfix;

      .c-text-list__item {
        float: left;
        margin: 0;
        color: #666;
        letter-spacing: -0.7px;
        font-size: 13px;
        line-height: 21px;

        &::before {
          background-color: #666;
        }

        &:not(:last-of-type) {
          padding-right: 20px;
        }
      }
    }
  }
}

// 푸터 - 휴먼보험금조회
.bg-box {
  position: relative;
  padding: 20px 0;
  margin-bottom: 20px;
  z-index: 0;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: -25px;
    right: -25px;
    bottom: 0;
    z-index: -1;
  }

  &--gray:before {
    background: #f8f8f8;
  }
}

// 전체메뉴 - 메뉴검색
.search-result {
  &__message {
    position: relative;
    margin-top: 40px;
    height: 40px;
    line-height: 40px;
    font-size: 14px;
    color: #777;
    z-index: 1;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: -20px;
      right: -20px;
      width: calc(100% + 40px);
      height: 40px;
      background: #f5f5f5;
      z-index: -1;
    }

    span {
      color: $blue;
    }
  }

  &__list {
    margin: 0 -20px;
  }

  &__item {
    border-bottom: 1px solid #d8d8d8;

    a {
      display: block;
      position: relative;
      padding: 24px 46px 26px 20px;

      &::after {
        position: absolute;
        top: 50%;
        right: 20px;
        display: block;
        padding: 3px;
        width: 0;
        border: solid #000;
        border-width: 0 2px 2px 0;
        content: '';
        transform: rotate(-45deg) translateY(-50%);
      }
    }
  }

  &__title {
    display: block;
    font-size: 17px;
  }

  &__text {
    margin-top: 5px;
    display: block;
    font-size: 15px;
    color: #666;
  }
}

// 전체메뉴 - 알림메시지
.alarm {
  font-size: 0;

  $root: '.alarm';

  &__tab {
    margin: 26px auto;
    display: flex;
    justify-content: center;

    button {
      position: relative;
      padding: 0 20px;
      font-family: youandiR;
      font-size: 15px;
      color: #666;

      ~ button {
        &::before {
          content: '';
          position: absolute;
          top: 50%;
          left: 0;
          transform: translateY(-50%);
          width: 1px;
          height: 14px;
          background: #d2d2d2;
        }
      }

      &.is-active {
        font-family: youandiB;
        color: $blue;
      }
    }
  }

  &__list {
    border-top: 1px solid #d8d8d8;
  }

  &__item {
    position: relative;
    padding: 24px 0 26px;
    border-bottom: 1px solid #d8d8d8;
  }

  &__new {
    #{$root}__date {
      position: relative;
      display: inline-block;

      &::before {
        content: '';
        position: absolute;
        top: -2px;
        right: -5px;
        width: 4px;
        height: 4px;
        background: #ff0000;
        border-radius: 50%;
      }
    }
  }

  &__date {
    font-family: youandiB;
    font-size: 19px;
    color: $blue;
  }

  &__sub {
    position: absolute;
    top: 31px;
    right: 0;
    font-family: youandiR;
    font-size: 12px;
    color: #666;
  }

  &__title {
    display: block;
    margin-top: 6px;
    font-size: 16px;
  }

  &__text {
    display: block;
    margin-top: 6px;
    font-size: 14px;
    color: #666;
  }

  .empty {
    margin-top: 0;
  }
}

// 고객안내 - 금융소비자보호 - 전자민원접수
.customer-slider {
  color: #000;

  .swiper-slide {
    width: 260px;
    height: 220px;
    padding: 35px 30px 53px;
    background: #f8f8f8;
    border-radius: 8px;

    &:nth-child(odd) {
      background: #ebf4fa;
    }
  }

  &__title {
    font-family: youandiR;
    font-size: 17px;
  }

  &__text {
    margin-top: 6px;
    font-size: 14px;
    color: #666;
  }

  &__button {
    margin-top: 20px;
    font-size: 15px;
  }
}

// 고객안내 - 모바일 접수방법 안내
.cs-step {
  margin-top: 15px;
  text-align: center;

  > li {
    font-family: youandiB;
    font-size: 17px;

    ~ li {
      margin-top: 44px;
    }
  }

  img {
    display: block;
    margin: 24px auto 0;
    width: 240px;
  }

  ol {
    margin-top: -18px;
    padding: 36px 20px 33px;
    background: #f8f8f8;

    li {
      font-family: -apple-system, 'Noto Sans', '맑은 고딕', sans-serif;
    }
  }
}

// 전자서명
.c-modal__body {
  [class*='login--type']:first-child {
    min-height: calc(100vh - 59px);
    margin-top: -20px;
  }
}

// 보험상품
.product-spec {
  .o-heading--1 {
    margin: 39px 0 19px;

    &:first-child {
      margin-top: 21px;
    }
  }
}

.product-submain-detail.link {
  padding: 35px 0;
  font-family: youandiB;

  .o-icon--right-arrow {
    margin-left: 10px;
  }

  &:last-child {
    padding-bottom: 0;
    margin-bottom: -13px;
  }
}

.product-submain {
  .webfont-r {
    font-family: youandiR !important;
  }
}

.product-submain-detail__list {
  font-size: 15px;
}

// 찐 트리플 양로저축보험 */
.real-triple {
  font-size: 0;

  ul {
    margin: -6px 0 0 -6px;
    @include clearfix;

    li {
      float: left;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      position: relative;
      width: calc(50% - 6px);
      height: 90px;
      padding: 0 20px;
      margin: 6px 0 0 6px;
      border: 1px solid $blue;
      border-radius: 5px;
      background: #f8f8f8;
      box-shadow: 5px 12px 18px 0px rgba(211, 211, 211, 0.16);
      line-height: 18px;
      text-align: center;
      font-family: youandiB;
      font-size: 14px;
      color: $blue;

      &:nth-child(1) {
        border-color: #7ab5db;
      }

      &:nth-child(2) {
        background: $blue;
        color: #fff;

        &::before {
          content: '';
          position: absolute;
          top: 50%;
          left: -1px;
          transform: translateY(-50%);
          width: 6px;
          height: 23px;
          background: #fff;
        }

        &:after {
          content: '';
          position: absolute;
          top: 50%;
          left: 5px;
          transform: translateY(-50%);
          width: 0;
          height: 0;
          border-top: 15px solid transparent;
          border-left: 10px solid #fff;
          border-bottom: 15px solid transparent;
        }

        span {
          color: inherit;
        }
      }
    }

    span {
      display: block;
      margin-top: 6px;
      line-height: 14px;
      font-family: $font-family-base;
      font-size: 12px;
      color: #666;
    }
  }

  ol {
    position: relative;
    height: 105px;
    margin-top: 15px;
    padding: 48px 20px 0;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 36px;
      border-radius: 12px;
      background: url('../img/insurance/bg_real_triple.png') $blue no-repeat center center;
      background-size: auto 100%;
    }

    li {
      display: inline-block;
      font-family: youandiR;
      font-size: 13px;

      &:nth-child(3) {
        position: absolute;
        top: 48px;
        left: 50%;
        transform: translateX(-50%);

        span {
          display: block;
          position: absolute;
          bottom: -38px;
          left: 50%;
          transform: translateX(-50%);
          width: 130px;
          line-height: 27px;
          background: #ffde00;
          border-radius: 15px;
          text-align: center;
          font-size: 12px;
        }
      }
    }
  }

  + .c-text-list--star {
    .c-text-list__item {
      font-size: 12px;
    }
  }
}

// 컨설턴트
.csult-submain {
  position: relative;

  $root: '.csult-submain';

  &__toggle {
    position: absolute;
    top: 60px;
    left: 20px;
    display: inline-block;
    margin-bottom: 36px;
    border: 1px solid #46a9d5;
    border-radius: 8px;
    color: $white;
    font-size: 0;

    input[type='radio'] {

      &:focus {
        + label {
          outline: 1px solid blue;
        }
      }

      + label {
        display: inline-block;
        min-width: 50px;
        height: 31px;
        line-height: 31px;
        padding: 0 13px;
        border-radius: 7px;
        font-family: youandiB;
        font-size: 15px;
        text-align: center;
        cursor: pointer;
      }

      &:checked + label {
        background: $white;
        color: #007ab5;
      }
    }
  }

  &__content {
    margin: 0 -20px;
    padding: 130px 30px 60px;
    background: linear-gradient(0, #18b3af, #0079cc);
    color: $white;
  }

  &__title {
    overflow: hidden;
    height: 96px;
    margin: 0 10px;
    line-height: 32px;
    font-family: youandiB;
    font-size: 24px;

    @media (max-width: 320px) {
      height: 128px;
    }
  }

  &__animation {
    display: block;
    position: relative;
    top: 100%;
    animation: ani-up 0.5s forwards;

    @keyframes ani-up {
      to {
        top: 0;
      }
    }
  }

  &__text {
    margin: 26px 10px 0;
    font-size: 16px;
  }

  &__button {
    border: 1px solid $white;
    color: $white;

    ~ #{$root}__button {
      margin-top: 6px;
    }
  }

  &__swiper {
    margin: 26px -40px 40px 0 !important;

    .swiper-slide {
      padding: 18px 28px 26px;
      width: 160px;
      background: $white;
      border-radius: 8px;
      box-shadow: 11px 26px 23px 0px rgba(130, 130, 130, 0.15);
      text-align: center;

      &:last-child {
        margin-right: 40px;
      }

      i {
        display: block;
        margin: 0 auto;
        width: 50px;

        img {
          width: 100%;
        }
      }

      #{$root}__swiper-title {
        display: block;
        font-size: 15px;
        color: #000;
      }

      #{$root}__swiper-text {
        display: block;
        margin-top: 7px;
        line-height: 21px;
        font-size: 13px;
        color: #666;
      }
    }
  }
}

.csult {
  [class*='o-heading'] {
    &.line {
      position: relative;
      margin-top: 30px;
      padding-top: 54px;

      &::after {
        content: '';
        position: absolute;
        top: 0;
        right: -20px;
        left: -20px;
        height: 8px;
        background: #f0f0f0;
      }
    }

    .o-text--underline {
      font-size: inherit;
    }
  }

  .o-text.ta-center {
    display: block;
  }

  .o-text--2 {
    display: block;
    margin: 19px 0 9px;

    &.line {
      position: relative;
      margin-top: 30px;
      padding-top: 24px;

      &::before {
        content: '';
        border-top: 1px solid #eee;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
      }
    }

    + img {
      margin-top: 23px;
    }
  }

  img {
    display: block;
    width: 100%;
    margin: 0 auto;

    + .c-text-list--dot {
      margin-top: 24px;
    }
  }

  .info-wrap {
    position: relative;
    padding: 52px 20px 45px;

    .info-wrap__text {
      line-height: 29px;
      font-size: 21px;
      font-family: youandiB;
      color: #000;
      word-break: keep-all;
    }

    .info-wrap__sub {
      margin-top: 10px;
      font-family: youandiR;
      color: #666;
    }
  }
}

.csult-fp {
  margin: 0 20px;
  text-align: center;

  p:nth-of-type(1) {
    margin-top: -6px;
    font-size: 18px;
  }

  p:nth-of-type(2) {
    margin-top: 12px;
    line-height: 24px;
    font-size: 15px;
  }
}

.blue-fp {
  position: relative;
  margin: 27px 0 60px;
  padding: 160px 40px 40px;
  border-radius: 28px;
  background: linear-gradient(-45deg, #00b293, #0076c0);
  box-shadow: 0px 30px 50px 0px rgba(0, 0, 0, 0.1);
  @include clearfix;

  i {
    position: absolute;
    top: 40px;
    left: 50%;
    transform: translateX(-50%);
    width: 80px;

    img {
      width: 100%;
    }
  }

  li {
    width: 100%;
    line-height: 36px;
    background: #fff;
    border-radius: 16px;
    font-family: youandiR;
    font-size: 15px;
    color: $blue;
    text-align: center;

    ~ li {
      margin-top: 10px;
    }
  }
}

.csult-process {
  margin-top: 16px;

  li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #d8d8d8;
    word-break: keep-all;
    text-align: right;
    padding: 12px 20px;
    border-radius: 4px;

    ~ li {
      margin-top: 10px;
    }
  }

  &__title {
    font-family: youandiB;
    color: $blue;
    text-align: left;
  }

  &__text {
    color: #666;
  }
}

.csult-list {
  margin-top: -34px;
  text-align: right;

  > .o-text--star {
    display: inline-block;
    margin: 0 0 16px;
  }

  li {
    position: relative;
    padding: 16px 25px 20px 30px;
    border-radius: 4px;
    text-align: left;

    &:nth-of-type(1) {
      background: #f8f7e9;
      color: #4c4333;
    }

    &:nth-of-type(2) {
      background: rgba(0, 161, 156, 0.15);
      color: #007688;
    }

    &:nth-of-type(3) {
      background: rgba(0, 118, 192, 0.2);
      color: #004b75;
    }

    ~ li {
      margin-top: 10px;
    }
  }

  &__title {
    display: block;
    font-size: 14px;
    color: #000;
  }

  &__text {
    font-size: 18px;
  }

  &__image {
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
    width: 50px;
  }
}

.csult-image {
  position: relative;
  margin: 35px 0 50px;
  text-align: center;

  img {
    z-index: 1;
    display: block;
    margin: 0 auto;
  }

  &__title {
    margin: 21px 0 11px;
    display: block;
    font-family: youandiB;
    font-size: 17px;

    &:nth-of-type(1) {
      color: $blue;
    }
    &:nth-of-type(2) {
      color: #00a19c;
    }
  }

  &.bg-gray {
    margin: 23px 0 0;

    ::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: #f8f8f8;
      z-index: -1;
    }
  }
}

.csult-swiper {
  .swiper-pagination {
    position: relative;
    bottom: 0 !important;
    display: block;
    margin: -25px auto 0;
    padding-top: 10px;
    width: calc(100% - 50px) !important;
    z-index: 1;
    background: $white;

    &::before {
      content: '';
      background: $white;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: -1;
    }
  }

  .swiper-pagination-bullet {
    width: 6px;
    height: 6px;
  }

  &.swiper-container-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet {
    margin: 0 8px;
  }
}

.faq-text {
  line-height: 24px;

  $root: '.faq-text';

  &__question {
    position: relative;
    display: block;

    &::before {
      content: 'Q. ';
    }

    & ~ & {
      margin-top: 27px;
    }
  }

  &__answer {
    position: relative;
    display: block;

    &::before {
      content: 'A. ';
    }
  }
}

.c-accordion__inside {
  .faq-text + [class*='o-text--'] {
    margin-top: 6px;
  }
}

.process {
  margin: 36px 20px 50px;

  &__item {
    position: relative;
    border-radius: 4px;
    padding: 21px 20px 20px;
    text-align: center;

    &:not(:last-of-type)::after {
      content: '';
      position: absolute;
      bottom: -7px;
      left: 50%;
      transform: translateX(-50%);
      width: 0;
      height: 0;
      border-left: 15px solid transparent;
      border-right: 15px solid transparent;
      border-top: 7px solid #e8e8e8;
    }

    ~ li {
      margin-top: 20px;

      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        width: 0;
        height: 0;
        border-left: 15px solid transparent;
        border-right: 15px solid transparent;
        border-top: 7px solid $white;
      }
    }

    &:nth-of-type(1) {
      background: #e8e8e8;
      color: #333;
    }

    &:nth-of-type(2) {
      background: #d9f1f0;
      color: #007688;

      &::after {
        border-top-color: #d9f1f0;
      }
    }

    &:nth-of-type(3) {
      background: #cce4f2;
      color: #004b75;
    }
  }

  &__title {
    display: block;
    font-family: youandiB;
    font-size: 18px;
  }
}

.image-card {
  text-align: center;

  $root: '.image-card';

  &__item {
    margin-bottom: 30px;
  }

  &__text {
    position: relative;
    display: block;
    margin: -35px auto 0;
    padding: 16px 20px;
    width: calc(100% - 40px);
    color: #666;
    z-index: 1;

    &::before {
      content: '';
      background: $white;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: -1;
    }

    strong {
      display: block;
      margin-bottom: 6px;
      font-family: youandiR;
      font-size: 17px;
      color: #000;
    }
  }
}

// 텍스트
.c-table + .c-text-list {
  margin-top: 15px;
}

.c-text-list {
  $root: '.c-text-list';

  &--number {
    counter-reset: number;

    > #{$root}__item {
      font-size: 15px;
      line-height: 27px;

      &::before {
        position: initial;
        display: inline;
        content: '' counter(number) '. ';
        counter-increment: number;
      }

      ~ #{$root}__item {
        margin-top: 27px;
      }
    }
  }
}

.c-accordion {
  .c-text-list--number {
    > .c-text-list__item {
      font-family: youandiB;
    }

    .c-text-list__item {
      color: #000;
      font-size: 15px;

      .o-text,
      .c-text-list {
        font-family: $font-family-base;
      }

      .o-text--2 {
        margin: 20px 0 8px;
      }

      .c-text-list {
        margin: 23px 0 8px;
      }
    }
  }
}

// 상품 카드형
.product-card {
  $root: '.product-card';

  &--type3 {
    position: relative;
    background-color: $white;

    & + & {
      margin-top: 20px;
    }

    #{$root}__head {
      position: relative;
      display: block;
      padding: 27px 25px 19px;
      text-align: center;
    }

    #{$root}__title {
      padding: 0 35px;
      font-size: 13px;
      font-family: youandiR;
    }

    #{$root}__text {
      margin-top: 5px;
      color: #666;
      font-size: 17px;
      font-family: youandiR;
      line-height: 27px;
    }

    #{$root}__highlight {
      color: $blue;
      font-family: youandiB;
    }

    #{$root}__panel {
      padding: 10px 25px;
      border-top: 1px solid #d8d8d8;
      color: #666;
      text-align: center;
      font-size: 13px;
    }
  }
}

// .page-link
.o-heading + .page-link {
  margin-top: 50px;
}

.c-tab--inside {
  .c-tab__panel {
    .page-link {
      margin: 20px 0 0 0;
      border-top: 0;

      > a,
      > button {
        padding: 25px 0;

        &::after {
          right: 2px;
        }
      }
    }
  }
}

// 동의 체크
.o-heading--2 + .agree-check {
  margin-top: 0;
}

/* 회사소개 - IR */
.ci-mail {
  text-align: center;

  img {
    display: block;
    margin: 80px auto 24px;
    width: 52px;
  }

  p {
    &:first-of-type {
      font-family: youandiB;
      font-size: 21px;
    }

    &:last-of-type {
      margin-top: 30px;
      font-size: 15px;
      color: #666;
    }
  }

  a {
    position: relative;
    display: block;
    margin-top: 12px;
  }
}

/* 회사소개 - CEO 메시지 */
.ceo-head {
  position: relative;
  padding: 54px 20px 0;
  height: 523px;
  font-family: youandiR;
  z-index: 1;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: -20px;
    right: -20px;
    bottom: 0;
    background: #eee;
    z-index: -1;
  }

  &__image {
    position: absolute;
    bottom: 0;
    right: -20px;
    height: 100%;
  }

  &__title {
    margin-bottom: 28px;
    line-height: 34px;
    font-family: youandiB;
    font-size: 29px;
  }

  &__description {
    font-size: 18px;
  }

  &__sign {
    width: calc(100% - 120px);
    margin-top: 17px;
    font-size: 12px;
    color: #666;

    img {
      display: block;
      margin-top: 20px;
      width: 59px;
    }
  }
}

.ceo-content {
  padding: 26px 20px 0;

  &__title {
    display: block;
    margin: 48px 0 18px;
    line-height: 29px;
    font-family: youandiB;
    font-size: 19px;
  }

  &__text {
    line-height: 26px;
    font-size: 16px;
    color: #666;
  }

  &__highlight {
    margin-top: 50px;
    font-size: 18px;
  }
}

/* 회사소개 - 푸본현대생명봉사단 */
.ci-image-wrap {
  margin: 30px -20px 0;
  overflow-x: auto;

  &__list {
    width: 1420px;
    @include clearfix;
  }

  &__item {
    position: relative;
    float: left;
    margin-left: 20px;
    padding-top: 99px;
    width: 260px;
    height: 140px;
    line-height: 40px;
    overflow: hidden;
    border-radius: 26px;
    text-align: center;
    font-family: youandiR;
    font-size: 15px;
    color: $white;
    z-index: 1;

    &::before {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 40px;
      background: rgba(0, 0, 0, 0.6);
      z-index: -1;
    }
  }

  img {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -2;
  }
}

.ci-text-wrap {
  margin-top: 30px;
  background: #f9fafc;
  border: 1px solid #eaedf3;
  border-radius: 6px;
  font-size: 17px;

  &__list {
    margin: 60px;
    display: flex;
    flex-wrap: wrap;
  }

  &__item {
    flex: 1;

    > img {
      display: block;
      margin: 0 auto 25px;
    }
  }

  &__foot {
    padding: 26px 30px;
    background: $white;
    border-top: 1px solid #eaedf3;
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px;
  }
}

.ci-info {
  position: relative;
  background: #f9fafc;

  p {
    position: relative;
    padding: 30px 25px;
    font-size: 18px;
    line-height: 24px;

    i {
      position: absolute;
      right: 30px;
      top: 50%;
      transform: translateY(-50%);
      display: block;
      margin: 0 auto 10px;
      width: 50px;

      img {
        width: 100%;
      }
    }
  }

  span {
    display: block;
    font-size: 15px;
    color: #666;

    &:first-of-type {
      margin-top: 4px;
    }

    &:only-of-type {
      margin-top: 6px;
    }
  }

  > i {
    display: block;
    margin: 0 auto;
    width: 31px;

    img {
      width: 100%;
    }

    + p {
      margin-top: -5px;
    }
  }
}

/* 회사소개 - 연혁 */
.history {
  margin: 0 10px;

  $root: '.history';

  &__item {
    position: relative;
    margin-top: 43px;
    font-size: 15px;

    &--type2 {
      #{$root}__title {
        margin-bottom: 18px;
      }
    }
  }

  &__date {
    display: block;
    margin-bottom: 20px;
    font-family: youandiB;
    font-size: 13px;
  }

  &__image {
    margin-bottom: 25px;
    width: 100%;
    border-radius: 22px;
    overflow: hidden;
  }

  &__title {
    display: block;
    margin-bottom: 7px;
    font-family: youandiR;
    font-size: 18px;
  }

  &__text {
    display: block;
    font-size: 14px;
    color: #000;

    &.c-text-list--dot {
      color: #666;

      > .c-text-list__item::before {
        background-color: #666;
      }
    }

    > .c-text-list__item {
      margin-top: 0 !important;
      font-size: 14px;
    }

    + #{$root}__text {
      margin-top: 14px;
    }
  }
}

/* 회사소개 - 인재채용 */
.text-box {
  margin: 0 -20px;
  padding: 0 20px;
  overflow-x: auto;

  &__list {
    width: 870px;
    display: flex;
  }

  &__item {
    padding: 34px 30px 36px;
    width: 270px;
    border: 1px solid #eaedf3;
    border-radius: 10px;

    & ~ & {
      margin-left: 20px;
    }
  }

  &__image {
    display: block;
    margin-bottom: 17px;
    height: 50px;

    img {
      height: 100%;
    }
  }

  &__title {
    display: block;
    font-family: youandiR;
    font-size: 17px;
  }

  &__text {
    display: block;
    margin-top: 13px;
    font-size: 15px;
    color: #666;
  }
}

.recruit-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  &__item {
    margin-top: 50px;
    width: calc(50% - 7px);
    font-size: 15px;
  }

  &__anchor {
    display: block;
  }

  &__image {
    display: block;
    margin: 0 auto 26px;
    overflow: hidden;
    border-radius: 10px;

    img {
      width: 100%;
    }
  }

  &__name {
    display: block;
    margin-top: 3px;
    color: #666;
  }
}

.recruit-visual {
  position: relative;
  height: 390px;
  padding-bottom: 65px;
  font-family: youandiR;
  color: $white;
  text-align: center;
  z-index: 1;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: url('../img/ci/bg_recruit_visual.gif') #e5e9e9;
    background-size: cover;
    z-index: -5;
  }

  &::after {
    content: '';
    height: 65px;
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: -1;
  }

  &__image {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    max-width: 375px;
    z-index: -1;
  }

  &__info {
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    font-size: 14px;
  }

  &__name {
    display: block;
    font-size: 15px;
  }
}

.recruit__content {
  margin-top: 44px;

  &__text {
    font-family: youandiB;
    font-size: 20px;
  }

  .o-heading--5 {
    font-family: youandiR;

    .co-blue {
      display: block;
      margin-bottom: 6px;
    }

    + .o-text--2 {
      margin-top: -15px;
    }
  }
}

.recruit-swiper {
  margin: 0 -20px;
  position: relative;

  .recruit__content {
    margin: 44px 20px;
  }

  .swiper-custom-controller {
    position: absolute;
    top: 325px;
    left: 0;
    right: 0;
    height: 65px;
    @include clearfix;
  }
}

.swiper-custom-pagination--type2 {
  position: absolute;
  top: 25px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 10;

  .swiper-pagination-bullet {
    width: 6px;
    height: 6px;
    background-color: #c0c0c0;

    &-active {
      background-color: $blue;
    }
    ~ .swiper-pagination-bullet {
      margin-left: 12px;
    }
  }
}

.swiper-custom-button--type2 {
  position: relative;
  width: 50px;
  height: 65px;
  z-index: 10;

  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 10px;
    height: 18px;
    background: url(../img/com/ico_swiper_prev.png) no-repeat;
    background-size: 100%;
  }

  &--prev {
    float: left;
  }

  &--next {
    float: right;
    transform: rotate(180deg);
  }

  &.swiper-button-disabled {
    display: none;
  }
}

.recruit-video {
  position: relative;
  height: 0;
  padding-bottom: 52.6%;
  margin-top: 50px;
  box-shadow: 0 10px 23px 0px rgba(0, 0, 0, 0.15);

  &__img,
  &__poster {
    position: absolute;
    width: 100%;
    z-index: 10;

    img {
      width: 100%;
    }
  }

  iframe {
    position: absolute;
    width: 100%;
    height: 100%;
  }
}

/* 회사소개 - 조직문화 */
.ci-value {
  $root: '.ci-value';

  &__heading {
    width: 240px;
    height: 50px;
    line-height: 48px;
    margin: 45px auto 40px;
    border: 1px solid $blue;
    border-radius: 25px;
    text-align: center;
    font-family: youandiR;
    font-size: 17px;
    color: $blue;
  }

  > #{$root}__text {
    margin-top: 45px;
    text-align: center;
    line-height: 27px;
    font-family: youandiR;
    font-size: 18px;
    word-break: keep-all;
  }

  &__item {
    position: relative;
    padding: 26px 70px 27px 0;
    border-top: 1px solid #e8e8e8;
  }

  &__title {
    display: block;
    margin-bottom: 12px;
    font-family: youandiR;
    font-size: 17px;
  }

  &__text {
    font-size: 15px;
    color: #666;
    word-break: keep-all;
  }

  &__image {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    height: 50px;

    img {
      height: 100%;
    }
  }
}

.ci-value-swiper {
  padding: 0 20px !important;
  margin: 0 -20px !important;

  @media (max-width: 320px) {
    margin-left: 0 !important;
  }

  .swiper-slide {
    width: 285px;
    height: 365px;
    padding: 40px 0 0;
    border: 1px solid #eaedf4;
    border-radius: 10px;
    overflow: hidden;
    text-align: center;

    @media (max-width: 320px) {
      width: 280px;
    }
  }

  &__image {
    display: block;
    margin: 0 auto 18px;
    height: 50px;

    img {
      height: 100%;
    }
  }

  &__title {
    display: block;
    margin-bottom: 12px;
    font-family: youandiB;
    font-size: 21px;
  }

  &__text {
    padding: 0 40px;
    color: #666;
    word-break: keep-all;
  }

  &__list {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 20px 0 30px;
    height: 130px;
    background: #f8f8f8;

    li {
      display: inline-block;
      height: 30px;
      line-height: 28px;
      padding: 0 15px;
      margin-top: 10px;
      border: 1px solid #d8d8d8;
      border-radius: 15px;
      background: $white;
      color: $blue;
    }
  }
}

/* 회사소개 - 메인 */
.ci-main {
  margin-bottom: -50px;
  font-size: 0;
  text-align: center;

  $root: '.ci-main';

  &__content {
    position: relative;
    padding: 87px 0 90px;
    z-index: 1;

    &:nth-of-type(odd)::before,
    &:last-of-type::before {
      content: '';
      position: absolute;
      top: 0;
      left: -20px;
      bottom: 0;
      right: -20px;
      z-index: -1;
      background-color: #f9fafc;
    }

    &:nth-of-type(3)::before {
      background: repeating-linear-gradient(-45deg, #f9fafc, #f9fafc 2px, #f1f2f4 0, #f1f2f4 4px);
    }

    &:last-of-type {
      color: $white;

      &::before {
        background: linear-gradient(to bottom, #0077c6, #00c4b0);
      }
    }

    &.active {
      .ci-irp__visual i {
        animation-name: ani-size;
        animation-duration: 2s;

        @keyframes ani-size {
          0% {
            transform: scale(0.2);
          }

          50% {
            transform: scale(1.4);
          }

          100% {
            transform: scale(1);
          }
        }
      }
    }
  }

  &__title {
    margin-bottom: 22px;
    line-height: 34px;
    background: linear-gradient(-50deg, #0077c6, #00c4b0);
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;
    font-family: youandiB;
    font-size: 29px;
  }

  &__text {
    margin-bottom: 44px;
    line-height: 24px;
    font-family: youandiR;
    font-size: 17px;

    #{$root}__highlight {
      margin-top: 30px;
    }
  }

  &__highlight {
    display: block;
    margin: 0 40px 24px;
    line-height: 36px;
    font-family: youandiB;
    font-size: 27px;
    word-break: keep-all;

    .co-blue {
      display: block;
    }
  }
}

.ci-main-visual {
  position: relative;
  height: calc(100vh - 59px);
  // max-height: 445px;
  overflow: hidden;
  margin: 0 -20px;
  font-family: youandiR;
  color: $white;
  z-index: 1;

  $root: '.ci-main-visual';

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: -20px;
    right: -20px;
    bottom: 0;
    background: url(../img/ci/ci_main_visual.jpg) no-repeat 50% 0/cover;
  }

  &__text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 16px;

    #{$root}__highlight {
      margin-bottom: 20px;
    }
  }

  &__highlight {
    display: block;
    line-height: 40px;
    font-family: youandiB;
    font-size: 36px;
  }

  &__sub {
    position: absolute;
    bottom: 0;
    left: 50%;
    height: 54px;
    transform: translateX(-50%);
    font-size: 13px;

    &::before {
      content: '';
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      width: 2px;
      height: 30px;
      background: $white;
      animation: ani-down 0.8s infinite;

      @keyframes ani-down {
        0% {
          bottom: 0;
        }

        100% {
          bottom: -30px;
        }
      }
    }
  }
}

.ci-main-ins-swiper {
  padding: 0 20px !important;
  margin: 0 -20px !important;

  .swiper-slide {
    padding-top: 33px;
    width: 275px;
    height: 404px;
    overflow: hidden;
    border-radius: 20px;
    color: $white;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: linear-gradient(-50deg, #0083a4, #00a49d);
      z-index: -1;
    }

    &:nth-child(2)::before {
      background: linear-gradient(-50deg, #0077c6, #00b4c1);
    }

    ~ .swiper-slide {
      margin-left: 20px;
    }
  }

  &__list {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 211px;
    line-height: 30px;
    font-size: 12px;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgba(0, 0, 0, 0.08);
    }
  }

  &__image {
    position: relative;
    display: block;
    margin: 0 auto 15px;
    width: 50px;

    img {
      width: 100%;
    }
  }

  &__label {
    display: inline-block;
    padding: 0 12px;
    margin-bottom: 13px;
    line-height: 19px;
    font-family: youandiR;
    font-size: 12px;
    border: 1px solid $white;
    border-radius: 5px;
  }

  &__text {
    font-family: youandiR;
    font-size: 16px;
  }
}

.ci-irp {
  margin: 0 -20px;

  p {
    position: relative;
    width: 295px;
    height: 295px;
    overflow: hidden;
    border-radius: 50%;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 295px;
      height: 295px;
      overflow: hidden;
      border: 1px solid #eaedf4;
      border-radius: 50%;
    }
  }

  &__visual {
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 11px;
    box-shadow: 11px 22px 30px 0px rgba(167, 180, 185, 0.1);

    &::before {
      background-color: $white;
      z-index: -1;
    }

    i {
      display: block;
      margin: 20px auto 12px;
      width: 48px;
      height: 61px;

      img {
        width: 100%;
      }
    }

    em {
      margin-bottom: 9px;
      line-height: 25px;
      font-family: youandiR;
      font-size: 19px;
    }

    span {
      font-size: 10px;
      color: #666;
    }
  }

  &__text {
    margin: -98px auto 0;
    padding: 134px 30px 0;
    font-family: youandiR;
    font-size: 14px;

    &::before {
      background-color: #f9fafc;
      z-index: -2;
    }
  }
}

.ci-news {
  margin-top: 44px;

  &__company {
    display: block;
    margin: 0 auto 20px;
    width: 248px;
    height: 40px;
    line-height: 40px;
    background: #f8f8f8;
    font-family: youandiR;
    font-size: 14px;
    color: #666;
  }

  &__title {
    display: block;
    font-size: 15px;
  }

  &__date {
    display: block;
    font-size: 14px;
    color: #666;
  }
}

.ci-main-service-swiper {
  margin: 0 -20px !important;

  .swiper-slide {
    width: 230px;

    &:not([class*='swiper-slide-active']) {
      opacity: 0.5;
    }
  }

  &__image {
    display: block;
    margin-bottom: 35px;
    width: 230px;
    overflow: hidden;
    background: $white;
    border-radius: 10px;

    img {
      width: 100%;
    }
  }

  &__text {
    font-family: youandiR;
    font-size: 17px;

    span {
      display: block;
      font-family: youandiB;
    }
  }
}

.text-wrap {
  margin-top: 60px;
  border: 5px solid #f9fafc;
  border-radius: 6px;

  &__title {
    display: inline-block;
    padding: 12px 25px 13px;
    margin-top: -20px;
    background: #00a49d;
    border-radius: 10px;
    font-family: youandiR;
    font-size: 18px;
    color: $white;

    @media (max-width: 320px) {
      font-size: 17px;
    }
  }

  img {
    width: 100%;
  }
}

.ci-main-company {
  position: relative;
  padding: 66px 0 170px;

  &::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 114px;
    background: #f9fafc;
    z-index: -1;
  }

  &::after {
    content: '';
    position: absolute;
    bottom: 103px;
    left: 50%;
    transform: translateX(-50%);
    width: 0;
    height: 0;
    border-left: 41px solid transparent;
    border-right: 41px solid transparent;
    border-top: 11px solid $white;
  }

  img {
    display: inline-block;
    width: auto;
    height: 64px;
    z-index: 99;

    ~ img {
      margin-left: 46px;
    }

    &:last-child {
      position: absolute;
      bottom: 33px;
      left: 50%;
      transform: translateX(-50%);
      display: block;
      margin: 0 auto;
      height: 40px;
    }
  }
}

.ci-main-history {
  padding: 34px 30px;
  text-align: left;
  font-family: youandiR;

  $root: '.ci-main-history';

  > li {
    &:last-child {
      #{$root}__text {
        padding-bottom: 0;

        &::before {
          display: none;
        }
      }
    }
  }

  &__date {
    position: relative;
    display: block;
    padding-left: 30px;
    margin: 5px 0 9px;
    line-height: 1;
    font-size: 12px;
    color: $blue;

    &::before {
      content: '';
      position: absolute;
      top: 2px;
      left: 0;
      width: 9px;
      height: 9px;
      border: 2px double transparent;
      border-radius: 50%;
      background: linear-gradient($white, $white), radial-gradient(circle at top, #18b3af, #0079cc);
      background-clip: content-box, border-box;
      background-origin: border-box;
    }
  }

  &__text {
    position: relative;
    display: block;
    padding: 0 0 20px 30px;
    font-size: 15px;

    &::before {
      content: '';
      position: absolute;
      top: 6px;
      left: 4px;
      bottom: 0;
      width: 2px;
      background: url(../img/ci/bg_ci_main_history.png);
      background-size: 100%;
    }
  }
}

.ci-main-recruit-swiper {
  margin: 50px -20px 0 !important;

  .swiper-slide {
    width: 300px;

    &:not([class*='swiper-slide-active']) {
      opacity: 0.5;
    }
  }

  &__image {
    display: block;
    margin: 0 auto 36px;
    width: 200px;
    overflow: hidden;
    border-radius: 10px;
    box-shadow: 8px 16px 28px 0px rgba(0, 0, 0, 0.2);
  }

  &__text {
    margin-bottom: 20px;
    line-height: 24px;
    font-family: youandiR;
    font-size: 15px;
    color: #666;
  }

  &__info {
    font-family: youandiR;
    font-size: 14px;
    color: #666;
  }

  &__name {
    position: relative;
    display: block;
    font-family: youandiR;
    font-size: 17px;
  }
}

.ci-sns {
  a {
    display: inline-block;
    width: 60px;
    height: 60px;

    ~ a {
      margin-left: 17px;

      @media (max-width: 320px) {
        margin-left: 12px;
      }
    }

    img {
      width: 100%;
    }
  }
}

/* 모달필터 */
.c-modal__body > .modal-filter:first-child {
  position: relative;
  margin-top: -20px;
  padding: 20px 0 30px;
  z-index: 1;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: -20px;
    right: -20px;
    bottom: 0;
    background: #f8f8f8;
    border-bottom: 1px solid #eee;
    z-index: -1;
  }

  .form {
    background-color: $white;
  }

  .radio-box__label {
    background-color: $white;
  }

  .radio-box__input:checked + .radio-box__label {
    background-color: $blue;
  }
}

/* 고객안내 - 금융소비자보호 */
.cs-image {
  width: 100%;
}

.cs-process {
  position: relative;
  padding-top: 64px;
  border: 1px solid #e8e8e8;
  border-radius: 12px;

  ol {
    display: flex;
    flex-direction: column;
    border-top: 4px solid #f8f8f8;

    &::before {
      content: '고객';
      position: absolute;
      top: 20px;
      left: calc(50% - 55px);
      width: 50px;
      height: 24px;
      background: #007688;
      border-radius: 20px;
      font-size: 14px;
      color: #fff;
      text-align: center;
    }

    &::after {
      content: '회사';
      position: absolute;
      top: 20px;
      left: 50%;
      width: 50px;
      height: 24px;
      background: $blue;
      border-radius: 20px;
      font-size: 14px;
      color: #fff;
      text-align: center;
    }

    > li {
      position: relative;
      flex: 1;
      padding: 33px 100px 36px 20px;
      font-size: 14px;
      color: #666;

      &:not(:last-child) {
        &::before {
          content: '';
          position: absolute;
          left: 50%;
          bottom: -14px;
          transform: translateX(-50%);
          width: 0;
          height: 0;
          border-left: 33px solid transparent;
          border-top: 14px solid $white;
          border-right: 33px solid transparent;
          z-index: 1;
        }
      }

      &:nth-child(even) {
        background-color: #f8f8f8;

        &::before {
          border-top-color: #f8f8f8;
        }
      }

      &:first-child {
        strong {
          color: #007688;
        }
      }
    }

    strong {
      display: block;
      margin-bottom: 13px;
      font-size: 17px;
      color: $blue;
    }

    i {
      position: absolute;
      top: 50%;
      right: 20px;
      transform: translateY(-50%);
      display: block;
      width: 60px;

      img {
        width: 100%;
      }
    }
  }
}

/* 금융상품 */
.banner {
  width: 100%;
  padding: 35px 30px;
  border-radius: 10px;
  background: url('../img/com/bg_fp_banner.png') #2a567e no-repeat right bottom;
  background-size: auto 100%;
  font-size: 0;
  color: #fff;

  &__highlight {
    display: block;
    margin-bottom: 13px;
    font-family: youandiB;
    font-size: 17px;
  }

  &__text {
    font-family: youandiR;
    font-size: 15px;
  }
}

/* COM020504010000 - 공인인증서복사 안내 */
.info-certificate {
  margin: 0 -25px;
  font-size: 0;

  &-text__bind {
    padding: 0 20px;
    background: #f8f8f8;
  }

  &__text {
    padding-top: 37px;
    text-align: center;

    dt {
      font-size: 17px;
      font-family: youandiB;
    }

    dd {
      padding-top: 18px;
      font-size: 15px;
      color: #666;
    }
  }

  &__desc {
    margin-top: 24px;
    margin-bottom: 36px;
    font-size: 15px;
  }

  .img-wrap {
    // position: relative;
    // width: 100%;
    // height: 200px;

    // &::before {
    //   content: '';
    //   position: absolute;
    //   top: 0;
    //   left: 0;
    //   width: 100%;
    //   height: 200px;
    //   background: rgba(#ff3c50, 0.2);
    // }
    padding: 35px 0 0 20px;

    img {
      width: 100%;
    }
  }

  .c-button-wrap {
    padding: 25px 0 37px;
  }

  .info-certificate__item {
    & + .info-certificate__item {
      border-top: 8px solid #f0f0f0;
    }

    .o-heading--1 {
      padding-left: 20px;
      color: #0089d1;
    }

    .o-text {
      margin-left: 20px;
      padding-right: 20px;
      color: #666;
    }

    .c-text-list {
      padding: 0 20px;
      .c-text-list__item {
        color: #666;
      }
    }
  }
}

/* 서비스 이용시간 안내 */
.info-service-hours {
  padding-top: 172px;
  background: url('../img/com/ico_service_hours.gif') no-repeat 50% 78px;
  background-size: 65px auto;
  text-align: center;

  &__msg {
    font-size: 21px;
    font-family: youandiB;
    line-height: 31px;
    color: #0076c0;
  }

  .o-heading--1 {
    margin-top: 33px;
  }
}

// [선택]금융상품 소개 등을 위한 개인(신용)정보 처리 동의서
.agree-check--type2 {
  margin-top: 23px;
  border: 0;

  > .agree-check__item {
    border: 1px solid #d8d8d8;
    border-radius: 6px;

    > .o-input--check {
      &:checked + label + .agree-check__inside{
        display: block;
      }
    }

    > .agree-check__inside {
      display: none;
      padding-top: 15px;
      margin: 15px -54px 0 -20px;
      border-top: 1px solid #d8d8d8;
      background-color: #fff;

      .input-group--small {
        margin-bottom: 0;
      }
    }
  }

  >.c-accordion--type2 {
    margin-top: 20px;

    .c-accordion__sub {
      padding: 20px 20px 4px;
      border-top: 1px solid #d8d8d8;
  
      .o-text {
        font-size: 15px;
      }

      .o-input--check + label {
        width: auto;
      }
    }
  
    .c-accordion__inside {
      >*:first-child {
        margin-top: 0;
      }
  
      .o-text {
        line-height: 21px;
        font-size: 13px !important;
      }
  
      .o-heading--1 {
        margin: 20px 0 16px;
        font-size: 15px;
      }
    }
  }
}

.event-modal {
  // margin: 38px 0 0 0 !important;
  padding: 0 20px !important;
  font-size: 0;

  $root: ".event-modal";

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #f3f7fa;
  }

  &__head {
    position: relative;
    padding: 42px 0 48px;
    font-family: youandiR;
    color: #fff;
    z-index: 1;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: -20px;
      right: -20px;
      bottom: 0;
      background: $blue;
      z-index: -1;
    }

    #{$root}__highlight {
      position: relative;
      font-size: 20px;
      padding-bottom: 26px;
      border-bottom: 2px solid #fff;
      margin-bottom: 26px;
    }

    #{$root}__text {
      font-size: 14px;
    }
  }

  &__content {
    position: relative;
    padding: 60px 0;
    z-index: 1;

    > img {
      display: block;
      height: 15px;
    }
  }
}

.event-renewal {
  margin: 40px auto;

  > li {
    position: relative;
    height: 110px;
    border-radius: 10px;
    border-bottom-right-radius: 25px;
    background: #fff;
    box-shadow: 0px 15px 45px 0px rgba(39,96,136,.15);

    ~ li {
      margin-top: 20px;
    }

    img {
      height: 100%;
    }

    > a {
      position: absolute;
      top: 50%;
      right: 20px;
      transform: translateY(-50%);
      width: 66px;
      height: 66px;
      z-index: 2;
      background: url(../img/com/img_event_renewal_btn.png) no-repeat;
      background-size: 100%;
    }

    &.is-disabled {
      a {
        width: 77px;
        right: 10px;
        cursor: default;
        background: url(../img/com/img_event_renewal_btn_disabled.png) no-repeat 50% 50%;
        background-size: 100%;
      }
    }
  }
}

.simulation-graph-wrap {
  font-size: 0;
  margin-top: 50px;
  padding-bottom: 53px;
  background: #f8f8f8;
}

.simulation-graph {
  position: relative;
  border-bottom: 1px solid $blue;
  background-color: #fff;
  background-image: repeating-linear-gradient(#fff, #fff 20px, #e8e8e8 20px, #e8e8e8 21px);

  $root: '.graph';

  #{$root} {
    width: 265px;
    margin: 0 auto;
    font-size: 0;
    overflow: initial;

    @media screen and (max-width: 320px) {
      width: 100%;
      padding: 0 10px;
    }
  }

  #{$root}__item {
    position: relative;
    display: inline-block;
    width: 110px;
    height: 180px;

    ~ #{$root}__item {
      margin-left: 40px;
    }
  }

  #{$root}__title {
    position: absolute;
    left: 0;
    right: 0;
    bottom: -53px;
    line-height: 53px;
    display: block;
    font-family: youandiR;
    font-size: 13px;
    text-align: center;
    color: #666;
    z-index: 1;
  }

  #{$root}__value {
    position: static;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    color: $white;
    opacity: 0;
    transition: opacity 2s .5s;
    font-family: 'youandiR';
    font-size: 18px;

    &--diff {
      position: relative;
      height: 92px;
      background: #d9f1f0;
      border: 1px solid #17a6a2;
      color: #007688;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;

      span:not(.sr-only) {
        display: block;
        font-size: 15px;
      }
    }

    &--base {
      align-items: center;
      flex: 1;
    }

    > span:not(.sr-only) {
      display: block;
      font-size: 15px;
    }
  }

  #{$root}__bar {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 0;
    box-sizing: border-box;
    transition: height 2s .5s;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    overflow: hidden;
    color: #fff;
    font-family: youandiB;
    font-size: 19px;

    &--1 {
      background-color: #666;
    }

    &--2 {
      background-color: $blue;
    }
  }

  &--case1 {
    #{$root}__bar--1 {
      height: 100%;
    }
  
    #{$root}__bar--2 {
      height: 100%;
    }

    .graph__value,
    .graph__value {
      opacity: 1;
    }
  }

  &--case2 {
    #{$root}__bar--1 {
      height: 96px;
    }
  
    #{$root}__bar--2 {
      height: 100%;
    }

    .graph__value--diff {
      display: none !important;
    }

    .graph__value,
    .graph__value {
      opacity: 1;
    }
  }

  &--case3 {
    #{$root}__bar--1 {
      height: 100%;
    }
  
    #{$root}__bar--2 {
      height: 100%;
    }

    .graph__value--diff {
      display: none !important;
    }

    .graph__value,
    .graph__value {
      opacity: 1;
    }
  }
}

/* 홈페이지 리뉴얼 이벤트 동의팝업 */
.event-swiper {
  position: relative;
  padding-bottom: 80px;
  margin-bottom: 46px;

  &::before {
    content: "";
    position: absolute;
    top: -20px;
    left: -20px;
    right: -20px;
    bottom: 0;
    background: #f8f8f8;
  }

  > p {
    text-align: center;
    padding: 26px 0 26px;
    
    .o-text--underline {
      font-size: 20px;
    }
  }

  .swiper-container {
    overflow: visible;
    margin: 0 auto;
    font-size: 0;

    .swiper-slide {
      width: 335px;
      height: 180px;
      box-shadow: 0px 18px 10px 0px rgba(0,0,0,.1);

      img {
        height: 100%;
      }

      p {
        font-family: youandiR;
        font-size: 18px;
        text-align: center;
      }

      .o-button {
        margin: 10px auto 0;
      }
    }

    @media screen and (max-width: 320px) {
      .swiper-slide {
        width: 100%;
        height: auto;
        
        img {
          height: auto;
          width: 100%;
        }
      }
    }
  }

  .swiper-custom-pagination--type2 {
    top: auto;
    bottom: 55px;

    .swiper-pagination-bullet {
      width: 7px;
      height: 7px;

      &:not(.swiper-pagination-bullet-active) {
        background-color: #989898;
        opacity: 1;
      }
    }
  }

  .c-button-wrap {
    position: absolute;
    bottom: 0px;
    left: 50%;
    transform: translateX(-50%);
    justify-content: center;
    margin-bottom: -20px;

    .o-button {
      flex: none;
      background: #fff;
      z-index: 1;
    }
  }
}

.co-red {
  color: $red;
}


.product-card--type2.product-card--except {
  .product-card__panel {
    padding: 12px 20px;
    
    .inside-list {
      display: table;
      table-layout: fixed;
      width: 100%;

      .inside-list__item {
        display: table-row;
        
        .inside-list__title,
        .inside-list__text {
          display: table-cell;
          padding: 8px 0;
          white-space: normal;
          word-wrap: break-word;
          word-break: keep-all;
        }

        .inside-list__title {
          width: 40%;
        }

        .inside-list__text {
          width: 60%;
          padding-left: 10px;
          text-align: right;
        }
      }
    }
  }
}

.simulation-result {
  margin-top: 25px;
  padding: 40px 20px;
  background: #f8f8f8;

  &__tab {
    margin: 30px auto 25px;
    text-align: center;

    button {
      position: relative;
      padding: 0 10px;
      color: #666;
      
      ~ button::before {
        content: "";
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        width: 1px;
        height: 12px;
        background: #d2d2d2;
      }

      &.is-active {
        color: $blue;
      }
    }
  }

  &__panel {
    margin-bottom: 15px;
    border: 1px solid #eee;
    border-radius: 10px;
    background: #ffffff;
    padding: 0 30px;
    text-align: center;

    ul {
      display: table;
      table-layout: fixed;
      width: 100%;

      li {
        position: relative;
        display: table-row;

        strong, 
        span {
          display: table-cell;
          padding: 13px 0;
          color: #666;
          font-size: 15px;
        }

        strong {
          text-align: left;
        }

        span {
          text-align: right;
          color: #000;
        }

        ~ li {
          strong,
          span {
            border-top: 1px solid #eee;
          }
        }
      }
    }
  }

  .c-text-list__item.fs-13 {
    line-height: 1.5;

    ~.c-text-list__item {
      margin-top: 16px;
    }
  }

  .o-heading + .o-text {
    margin-top: -5px;
    display: block;
    padding: 12px 15px;
    background: #fff;
  }
}


.c-table--event {
  margin-top: 0;
  border-top: 0;

  table::after {
    display: none;
  }

  th,
  td {
    border-right: 0;
    font-size: 13px;
  }

  th {
    vertical-align: top;
    padding: 12px 0;
  }

  td {
    padding-right: 0;
  }

  tr:last-child {
    th, 
    td {
      border-bottom: 0;
    }
  }
}

.event-info {
  margin-top: 10px;
  
  .o-label {
    display: inline-block;
    padding: 0 12px;
    border: 1px solid #d8d8d8;
    border-radius: 15px;
    background-color: #f8f8f8;
    font-size: 12px;
    color: #666;
    text-align: center;
    line-height: 16px;
    margin: -9px 0 -7px;
    margin-right: 10px;
    border-radius: 4px;
    padding: 0 4px;
  }

  li ~ li {
    margin-top: 5px;
  }
}

#irp-event {
  img {
    margin: -20px -25px 10px;
    width: calc(100% + 50px);
  }

  .c-table--event {
    margin-bottom: -10px;
  }
}