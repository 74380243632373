.co-green {
  color: #00a19c;
}

.fb-top-banner {
  // display: none;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  background-color: $dark;
  color: $white;
  transition: height 0.8s;

  .inner {
    padding: 26px 20px;
    opacity: 1;
    transition: opacity 0.4s;
  }

  .banner-subtext {
    display: block;
    margin-top: 15px;
    font-size: 13px;
    color: #9c9c9c;
  }

  .banner-bottom {
    @include clearfix;
    margin-top: 25px;

    .o-input--check + label {
      margin-top: 2px;
      line-height: 24px;
      font-size: 13px;

      &:before {
        width: 24px;
        height: 24px;
        background-color: transparent;
      }
    }

    .o-input--check + label:after {
      display: none;
    }

    .o-input--check:checked + label {
      &:before {
        border-color: #d8d8d8;
      }

      &:after {
        display: block;
      }
    }

    .top-banner-close {
      float: right;
      border: 1px solid #d8d8d8;
      color: $white;
    }
  }
}

.fb-main {
  position: relative;
  z-index: 20;
  margin: 0 -20px;
  margin-top: -59px;
  $root: '.fb-main';
  transition: margin-top 1s;
  // background-color: #f2f6f8;

  &-top {
    display: flex;
    height: 84px;
    align-items: center;
    // margin-bottom: 35px;
    // border-bottom: 2px solid rgba(255,255,255, .3);

    &__logo {
      width: 146px;
      line-height: 84px;

      img {
        width: 100%;
        height: 30px;
        vertical-align: middle;
      }
    }

    &__button {
      width: 64px;
      height: 100%;
      margin-left: auto;
      margin-right: -17px;
      background: url('../img/com/ico_side_menu.png') no-repeat 50% 50%/30px auto;
    }
  }

  &-head {
    // position: fixed;
    // top: 59px;
    // width: 100%;
    // z-index: -1;
    padding: 0 20px 152px;
    background-image: url('../img/com/bg_main_head_1.png'), url('../img/com/bg_main_head_2.png'), url('../img/com/bg_main_head_3.png'), linear-gradient(120deg, $blue 60%, #00a19c 100%);
    background-repeat: no-repeat, no-repeat, no-repeat;
    background-size: 30px auto, 122px auto, 55px auto, cover;
    background-position: 0 0, 100% 0, 100% 100%;
    text-align: center;
    font-family: youandiR;

    &__detail {
      padding-top: 35px;
      padding-bottom: 40px;
      border-top: 2px solid rgba(255, 255, 255, 0.3);
      color: $white;

      #{$root}-alarm + & {
        border-top: none;
      }
    }

    &__button {
      display: inline-block;
      margin-bottom: 10px;
      font-family: youandiB;
      font-size: 21px;
      color: $white;

      .o-icon::before,
      .o-icon::after {
        background-color: $white;
      }
    }

    &__text {
      font-size: 14px;
    }

    &__username {
      font-family: youandiB;
      font-size: 24px;

      &:after {
        content: '님!';
        font-family: youandiR;
      }
    }

    .contracts-summary-list {
      display: flex;
      margin-top: 25px;

      & > li {
        flex: 1;
        font-size: 13px;
      }

      &__text {
        display: block;
        margin-bottom: 12px;
      }

      &__anchor {
        display: inline-block;
        border-bottom: 1px solid $white;
        line-height: 1;
        font-size: 26px;
        color: $white;
      }

      &__button {
        display: inline-block;
        margin-top: 2px;
        height: 24px;
        padding: 0 10px;
        border-radius: 5px;
        line-height: 24px;
        border: 1px solid $white;
        color: $white;
      }
    }
  }

  &-alarm {
    width: 1px;
    margin: 0 auto;
    min-height: 40px;
    padding: 9px;
    background-color: $white;
    border-radius: 60rem;
    box-shadow: 0 25px 25px rgba(17, 57, 78, 0.1);
    animation: open-ani 0.5s forwards;

    &__text {
      height: 0;
      opacity: 0;
      transition: opacity 0.3s;
    }

    &.shown .fb-main-alarm__text {
      height: auto;
      opacity: 1;
    }

    @keyframes open-ani {
      to {
        width: 100%;
      }
    }
  }
  // .ovf {
  //   margin-top: 373px;
  //   background-color: #f2f6f8;
  // }

  &-quickmenu {
    position: relative;
    width: calc(100% - 40px);
    margin: -152px auto 0;
    height: 200px;
    border-radius: 15px;
    border-bottom-right-radius: 30px;
    background-color: $white;
    box-shadow: 5px 25px 25px rgba(65, 81, 84, 0.15);
    font-family: youandiR;
    overflow: hidden;
    transform: translateY(10px);
    animation: show-up 0.5s forwards;

    .fb-main-detail & {
      margin-top: -212px;
    }

    @keyframes show-up {
      to {
        transform: translateY(0);
      }
    }

    &__tab {
      display: block;
      width: 30%;
      height: 100%;
      float: left;
      background-color: #f8f8f8;
      overflow: hidden;
    }

    &__button {
      position: relative;
      display: block;
      width: 100%;
      height: 50%;
      font-family: youandiB;
      font-size: 14px;
      color: #666;

      span {
        display: inline-block;
        text-align: left;
      }

      &.is-active {
        position: relative;
        background-color: $white;
        color: $blue;
        z-index: 10;

        span:before {
          content: '';
          display: block;
          margin-bottom: 10px;
          width: 0;
          height: 2px;
          background-color: $blue;
          animation: draw-line 0.3s forwards;
        }

        @keyframes draw-line {
          to {
            width: 100%;
          }
        }
      }

      &:not(.is-active) {
        &::before,
        &::after {
          content: '';
          display: block;
          position: absolute;
        }

        &::before {
          top: -10px;
          right: 0;
          width: calc(100% + 30px);
          height: calc(100% + 40px);
          background-color: transparent;
          box-shadow: 0px 5px 15px 13px rgba(119, 119, 119, 0.15) inset;
        }

        &::after {
          width: 15px;
          height: 15px;
          top: 0;
          right: 0;
          background: url('../img/com/img_main_quickmenu_corner.png') no-repeat 0 0 / cover;
        }
      }
    }

    &__button:first-child {
      border-bottom-left-radius: 10px;

      &::before {
        top: initial;
        transform: rotate(180deg);
        bottom: -10px;
      }

      &::after {
        top: initial;
        transform: rotate(90deg);
        bottom: 0;
      }
    }

    &__button:nth-child(2) {
      border-top-left-radius: 10px;
    }

    &__panel {
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      width: 70%;
      height: 100%;
      padding: 0 4.5%;
      opacity: 0;
      transition: opacity 0.4s;

      &.show {
        opacity: 1;
      }
    }

    &__link {
      float: left;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      width: 50%;
      height: 50%;
      font-size: 13px;

      &:nth-child(2) ~ .fb-main-quickmenu__link {
        border-top: 1px solid #eee;
      }

      span {
        display: flex;
        align-items: center;
        flex-grow: 1;
        max-height: 35px;
      }
    }

    &__img {
      display: block;
      width: 30px;
      height: 30px;

      img {
        width: 100%;
      }
    }
  }

  &-detail {
    padding-top: 60px;
  }

  // 스와이퍼 공통
  &-slider {
    //
    .swiper-container {
      // overflow: visible;
    }

    &__link {
      display: block;
    }

    .slide {
      font-size: 0;
    }

    @media screen and (max-width: 320px) {
      .slide {
        width: 100% !important;
      }

      .slide img {
        width: 100%;
        height: auto !important;
      }
    }
  }

  &-products {
    text-align: center;

    &__tab {
      position: relative;
      padding-bottom: 30px;

      .tab-button {
        padding: 0 10px;
        font-family: youandiR;
        font-size: 21px;
        color: #666;
        transition: color 0.5s;

        &.is-active {
          font-family: youandiB;
          color: $black;

          // & + .tab-text {
          //   opacity: 1;
          // }
        }
      }

      .tab-text {
        position: absolute;
        bottom: 25px;
        width: 100%;
        color: $dark;
        opacity: 0;
        transition: opacity 0.5s;
      }
    }

    .swiper-container {
      padding-bottom: 55px;

      &:not(.swiper-container-initialized) {
        left: 50%;

        .swiper-slide {
          margin-right: 10px;
          transform: translateX(-50%);
        }
      }

      @media screen and (max-width: 320px) {
        & {
          padding: 0 20px 55px;
        }
      }
    }

    .swiper-wrapper {
      align-items: stretch;
    }

    .slide {
      width: auto;
      height: auto;
      border-radius: 15px;
      border-bottom-right-radius: 35px;
      box-shadow: 5px 25px 25px rgba(65, 81, 84, 0.15);
      overflow: hidden;

      img {
        height: 152px;
      }
    }

    // 퇴직연금 - 더 많은 상품 보기 슬라이드
    .more-products {
      position: absolute;
      left: 0;
      top: 0;
      z-index: 1;
      display: flex;
      width: 100%;
      height: 100%;
      flex-direction: column;
      justify-content: center;
      font-family: youandiR;

      &__title {
        margin-bottom: 10px;
        font-size: 18px;

        span {
          display: block;
        }
      }

      .o-button {
        margin: 0 10px;
        line-height: 2;
      }
    }
  }

  &-notice {
    margin: 0 0 -5px;
    padding: 0 30px;

    li {
      overflow: hidden;
      font-size: 14px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &__link {
      padding: 5px 0;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      flex-grow: 1;
    }

    .date {
      font-family: 'Noto Sans';
      padding-left: 20px;
      color: #666;
    }
  }

  &-event {
    text-align: center;

    .slide img {
      width: 100%;
    }

    &__pagination.swiper-pagination-fraction {
      left: 50%;
      bottom: -12px;
      transform: translateX(-50%);
      width: auto;
      padding: 0 20px;
      border-radius: 60rem;
      line-height: 24px;
      background-color: $white;
      border: 1px solid #d8d8d8;
      box-shadow: 5px 10px 15px rgba(0, 0, 0, 0.1);
      font-family: youandiB;

      .swiper-pagination-current {
        color: $blue;
      }
    }
  }
}

// 메인용 푸터
.l-foot.t-main {
  margin-top: -35px;
  padding-top: 0;
  border-top: none;

  &:before {
    content: none;
  }
}

.page-bottom-link {
  margin-bottom: -50px;
  padding: 50px 20px;
  border-top: 8px solid #f0f0f0;

  .info__passage-review + .c-button-fixed {
    margin-top: -10px;

    &.po-static {
      padding-left: 0;
      padding-right: 0;
      padding-bottom: 0;
    }
  }

  .c-button-wrap .o-button {
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 1.1;
  }

  .info__passage-review:last-child {
    margin-bottom: 0;
  }
}

/* 모니터링 질문 pmp020702000000 */
.survey-pd-wrap {
  margin: 0 -20px;
  padding: 30px 20px;
  background-color: $blue;

  .product-card:first-child {
    margin-top: 0;
  }
}

.survey-notice {
  text-align: center;

  &--top {
    margin: 0 -20px;
    padding: 25px 20px;
    background-color: #f8f8f8;
    font-size: 15px;
    font-family: youandiR;
    line-height: 24px;
    color: #666;
    // color: $white;

    & ~ & {
      margin-top: 50px;
    }
  }

  &--sub {
    padding: 14px 20px;
    background-color: #f8f8f8;
  }
}

.survey-list {
  padding: 50px 0 0;

  .o-heading {
    margin: 37px 0 15px;
  }

  .o-text + p {
    margin-top: 15px;
  }

  & > .o-text--1:first-child .o-heading,
  & > .o-heading:first-child {
    margin-top: 0;
  }

  &__textbox {
    padding: 25px 20px;
    background-color: #f8f8f8;
  }

  &__answer {
    position: relative;
    margin-top: 20px;
    padding-left: 24px;
    font-family: 'youandiB';
    color: $blue;

    &::before {
      content: '';
      position: absolute;
      top: 5px;
      left: 0;
      display: block;
      padding: 3px 5px;
      border: solid $blue;
      border-width: 0 0 2px 2px;
      transform: rotate(-45deg);
    }
  }

  & + .survey-list {
    position: relative;
    margin-top: 50px;
    padding-top: 58px;

    &::before {
      content: '';
      position: absolute;
      display: block;
      top: 0;
      left: 0;
      right: 0;
      height: 8px;
      margin: 0 -20px 0;
      background-color: #f0f0f0;
    }
  }
}

/* step 1 - 선택계약 */
.selected-contracts {
  margin: 0 -20px !important;
  padding: 30px 10px !important;
  background-color: $blue;
  overflow: hidden;

  .c-accordion:not(.swiper-wrapper) {
    display: flex;

    .c-accordion__item {
      margin-left: 10px;

      &:first-child {
        opacity: 1;
      }
    }
  }

  .c-accordion__item {
    width: calc(100% - 20px);
    height: auto;
    flex-shrink: 0;
    margin-top: 0 !important;

    &:not(.first) {
      transition: opacity 0.5s;
    }

    &:not(.active) {
      opacity: 0.5;
    }

    &.first {
      opacity: 1;
    }
  }

  .c-accordion__button.collapsed::before,
  .c-accordion__button.collapsed::after {
    background-color: #999;
  }
}

/* 공통 - 계좌선택 */
.slct-account {
  position: relative;
  @include clearfix;

  &__button {
    position: absolute;
    top: 0;
    right: 0;
    padding: 2px 11px;

    &.is-active {
      color: $blue;
    }

    &:first-child {
      right: 66px;

      &::after {
        content: '';
        position: absolute;
        display: block;
        right: 0;
        top: 5px;
        width: 1px;
        height: 12px;
        border-left: 1px solid #666;
      }
    }

    &:last-child {
      padding-right: 0;
    }
  }

  .c-form {
    clear: both;
  }

  .agree-check {
    margin-top: 10px;
  }
}

// /* 보험상품 서브메인 */
// .ins-submain {

//   &__head {
//     margin: 0 -20px;
//     padding: 60px 0;
//     background-color: $blue;
//     text-align: center;
//     overflow: hidden;
//     color: $white;
//   }

//   &__maincopy {
//     overflow: hidden;
//     height: 36px;
//     padding: 0 20px;
//     font-family: 'youandiB';
//     font-size: 24px;

//     span {
//       display: block;
//       position: relative;
//       top: 100%;
//       animation: ani-up .5s forwards;
//     }
//   }

//   @keyframes ani-up {
//     to {
//       top: 0;
//     }
//   }

//   &__subcopy {
//     display: inline-block;
//     margin: 20px 20px 0;
//     padding: 3px 15px;
//     min-height: 30px;
//     border: 1px solid #87d6ff;
//     border-radius: 15px;
//     font-family: 'youandiR';
//   }

//   .card-slider {

//     .swiper-slide {
//       width: calc(100% - 155px);
//       // width: 58.666%;
//     }

//     @for $i from 0 to 5 {
//       $order: $i + 1;
//       .swiper-slide:nth-child(#{$order}):not(#slide-search) .card {
//         background-image: url('../img/com/img_submain_card_0#{$order}.png');
//       }
//     }

//     .slide__title {
//       margin: 40px auto 30px;
//       font-family: 'youandiB';
//       font-size: 21px;
//       color: $white;

//       span {
//         position: relative;
//         display: inline-block;
//         padding: 0 7px;
//       }

//       span:before {
//         content: '';
//         position: absolute;
//         left: 0;
//         bottom: 0;
//         display: inline-block;
//         width: 0%;
//         height: 16px;
//         box-sizing: initial;
//         background-color: #0070ab;
//         z-index: -1;
//         transition: width .5s .2s;
//       }
//     }

//     .card {
//       display: block;
//       width: 100%;
//       height: 260px;
//       border-radius: 20px;
//       background-position: 50% 100%;
//       background-repeat: no-repeat;
//       background-size: auto 125px;
//       font-size: 13px;
//       color: $black;

//       &__text {
//         height: 135px;
//         padding: 30px 10px 0;

//         strong {
//           display: block;
//           margin-bottom: 10px;
//           line-height: 1.2;
//           font-family: 'youandiB';
//           font-size: 23px;
//         }
//       }

//       &__img img {
//         width: 100%;
//       }
//     }

//     .swiper-slide {
//       transition: opacity .5s;

//       &-prev {
//         opacity: 0;
//       }

//       &-next {
//         opacity: .5;
//       }

//       &-active {
//         opacity: 1;

//         .slide__title span:before {
//           width: 100%;
//         }

//         &#slide-search .icon-cross {
//           transform: rotate(360deg);
//         }
//       }
//     }

//     #slide-search {

//       .icon-cross {
//         position: relative;
//         display: block;
//         width: 25px;
//         height: 25px;
//         margin: 20px auto 25px;
//         transform: rotate(0deg);
//         transition: transform .5s .1s ease-in;

//         &:before,
//         &:after {
//           content: '';
//           display: block;
//         }

//         &:before {
//           margin: 0 auto;
//           width: 5px;
//           height: 25px;
//           background-color: #0070ab;
//         }

//         &:after {
//           position: absolute;
//           top: 10px;
//           left: 0;
//           width: 25px;
//           height: 5px;
//           background-color: #18b3af;
//         }
//       }

//       .card__text {
//         height: auto;
//         line-height: 1.3;
//         font-family: 'youandiB';
//         font-size: 23px;
//         color: #18b3af;
//       }

//       .go-search {
//         display: block;
//         padding-top: 20px;
//         text-decoration: underline;
//         color: $black;
//       }
//     }
//   }

//   .event-banner {
//     display: none;
//     position: fixed;
//     height: 64px;
//     left: 45%;
//     right: 45%;
//     width: auto;
//     bottom: 20px;
//     padding: 10px;
//     border-radius: 50px;
//     background-color: #333;
//     text-align: center;
//     font-family: 'youandiR';
//     color: $white;
//     z-index: 100;
//     animation: open-ani .8s forwards cubic-bezier(0.66, 0.1, 0.43, 1.11);

//     &.shown {
//       bottom: 20px;
//       opacity: 1;
//       transition: .5s;

//       .event-banner__text {
//         opacity: 1;
//       }

//       &.slide-down {
//         bottom: -100px;
//         opacity: 0;
//         transition: .5s;
//       }
//     }

//     .em {
//       display: block;
//       font-family: youandiB;
//       color: #69fffb;
//     }

//     &__text {
//       opacity: 0;
//       transition: opacity .2s .5s;
//     }

//     @keyframes open-ani {
//       to {
//         left: 20px;
//         right: 20px;
//       }
//     }
//   }
// }

// // 퀵메뉴 링크들 (공통)
// .quickmenu {
//   margin: 0 -20px -50px;

//   &__title {
//     min-height: 40px;
//     padding: 8px 20px;
//     background-color: #f5f5f5;
//     color: #666;
//     overflow: hidden;
//     display: flex;
//     align-items: center;

//     > :first-child {
//       margin-right: auto;
//     }
//   }

//   &__list {
//     padding: 20px 0;

//     li {
//       position: relative;

//       &:after {
//         content: '';
//         position: absolute;
//         top: 22px;
//         right: 20px;
//         display: inline-block;
//         margin-left: auto;
//         width: 3px;
//         height: 3px;
//         padding: 4px;
//         border: solid #000;
//         border-width: 0 2px 2px 0;
//         transform: rotate(-45deg);
//       }
//     }
//   }

//   &__link {
//     display: block;
//     padding: 16px 20px;
//   }
// }

// 문서 로드되기 전에 스와이퍼 콘텐츠 가운데 정렬
.swiper-container:not(.swiper-container-initialized) {
  left: 50%;

  .swiper-slide {
    margin-right: 20px;
    transform: translateX(-50%);
  }
}

// 내게 맞는 보험상품 찾기
.ins-search {
  margin: 0 -20px;
  overflow-x: hidden;
  background-color: #f8f8f8;
  text-align: center;

  .heading {
    padding: 130px 20px 30px;
    background: url('../img/com/img_mark_q.png') no-repeat 50% 60px/45px auto;
    line-height: 1.4;
    font-family: 'youandiB';
    font-size: 19px;
    color: $black;
  }

  .card-slider--search {
    overflow: initial;

    .swiper-slide {
      width: calc(100% - 125px);
      height: initial;
    }

    .card {
      position: relative;
      height: 100%;
      border-radius: 15px;
      background-color: $white;
      box-shadow: 20px 25px 40px rgba(0, 0, 0, 0.14);

      &__text {
        font-family: 'youandiB';
        font-size: 19px;
      }

      &__input {
        display: block;
        padding: 58px 20px 45px;
        font-family: 'youandiB';
        font-size: 19px;

        &:before {
          content: '';
          display: block;
          width: 130px;
          height: 130px;
          margin: 0 auto;
          background-image: url('../img/com/img_ins_search.gif');
          background-size: cover;
          background-position: 0 0;
        }

        .checkmark {
          position: absolute;
          display: block;
          width: 25px;
          height: 25px;
          left: 20px;
          top: 20px;
        }
      }
    }

    @for $i from 0 to 6 {
      $order: $i + 1;
      .swiper-slide:nth-child(#{$order}) label:before {
        background-position-x: $i * -130px;
      }
    }
  }

  .selection {
    &__item {
      position: relative;
      width: calc(100% - 125px);
      margin: 0 auto;
      border-radius: 15px;
      background-color: $white;
      box-shadow: 20px 25px 40px rgba(0, 0, 0, 0.14);

      + .selection__item {
        margin-top: 10px;
      }

      .checkmark {
        position: absolute;
        left: 20px;
        top: 20px;
      }
    }

    label.custom-input {
      display: block;
      width: 100%;
      height: 100%;
      padding: 18px 65px;
      font-family: 'youandiB';
      font-size: 19px;
    }

    input:checked ~ .line {
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      border-radius: 15px;
      border: 1px solid $blue;
    }
  }
}

.custom-input {
  cursor: pointer;

  input {
    position: absolute;
    opacity: 0;
    width: 0;
    height: 0;
    top: 0;
    left: 0;
  }

  .checkmark {
    position: relative;
    display: inline-block;
    width: 25px;
    height: 25px;
    background-color: $white;
    border: 1px solid #d8d8d8;
    border-radius: 50%;
    vertical-align: middle;

    &:before {
      content: none;
      position: absolute;
      top: 8px;
      left: 9px;
      display: block;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background-color: $white;
    }
  }

  input:checked {
    & ~ .checkmark {
      background-color: $blue;
      border: none;
    }

    & ~ .checkmark:before {
      content: '';
    }

    & ~ .line {
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      border-radius: 15px;
      border: 1px solid $blue;
    }
  }
}

// 내게 맞는 보험상품 찾기 결과
.ins-search--result {
  .product-spec {
    border-radius: 5px;
    border: 1px solid $blue;

    &__item {
      padding-left: 0;
      padding-right: 75px;

      & + li {
        border-top: 1px solid #eee;
      }
    }

    &__img {
      left: initial;
      right: 0;
    }

    .o-text--star,
    .c-text-list--star {
      margin-top: 5px;
    }
  }

  .related-banner {
    position: fixed;
    bottom: 0;
    width: 100%;
    display: flex;
    align-items: center;
    margin: 0 -20px;
    padding: 0 20px;
    height: 117px;
    background: #1f2946 url('../img/com/bg_related_banner.png') no-repeat calc(100% - 20px) 50%/50px auto;
    font-family: 'youandiR';
    z-index: 50;

    &__text {
      color: #fdea47;

      .em {
        display: block;
        font-size: 17px;
        font-weight: bold;
      }
    }
  }
}

// 본인인증
.c-tab.certification {
  $root: '.certification';

  .c-tab__panel {
    padding-top: 30px;
    text-align: center;
    color: #666;
  }

  .certify-cellphone {
    padding: 30px 20px;
    padding-top: 88px;
    border-radius: 6px;
    background: url('../img/com/bg_certify_cellphone1.png') no-repeat 50% 29px/28px auto;
    border: 1px solid #d8d8d8;
  }

  .certifi-security {
    $root: '.certifi-security';
    padding-top: 75px;
    border-radius: 6px;
    border: 1px solid #d8d8d8;
    background: url('../img/com/bg_certify_card.png') no-repeat 50% 29px/40px auto;
    overflow: hidden;

    &__item {
      display: flex;
      border-top: 1px solid #eeeeee;

      &:first-of-type {
        margin-top: 30px;
        border-top-color: #d8d8d8;
      }
    }

    label {
      width: 50%;
      background-color: #f8f8f8;
      padding-top: 13px;
      text-align: center;
    }

    &__form {
      width: 120px;
      height: 36px;
      margin: 6px auto;
      padding-right: 59px;
      background: url('../img/com/img_security_card.gif') no-repeat 100% 50%/59px auto;

      .input {
        position: relative;
        height: 100%;

        input {
          position: absolute;
          left: 0;
          top: 0;
          height: 100%;
          background-color: transparent;
        }
      }
    }

    &__dots {
      padding-top: 13px;
      text-align: center;
      font-size: 0;
    }

    &__dot {
      display: inline-block;
      width: 11px;
      height: 11px;
      background-color: #666;
      border-radius: 50%;

      & + #{$root}__dot {
        margin-left: 6px;
      }

      &.is-active {
        background-color: $blue;
      }
    }

    &__item:nth-of-type(2) #{$root}__form {
      padding-left: 59px;
      padding-right: 0;
      background-position: 0 0;
    }

    &__dots + input {
      color: transparent;
    }
  }

  // 인증완료 시
  .certified {
    &.certify-cellphone {
      padding-top: 75px;
      background-image: url('../img/com/bg_certify_cellphone2.png');
      background-size: 35px auto;
    }

    &.certifi-security {
      .certifi-security__item {
        display: none;
      }

      .certified-msg:last-of-type {
        margin-bottom: 30px;
      }
    }

    .certified-msg {
      display: inline-block;
    }

    & + .o-button {
      display: none;
    }
  }

  .certified-msg {
    display: none;
    margin-top: 16px;
    padding: 3px 10px;
    padding-left: 35px;
    background: url('../img/com/bg_agree_title.png') no-repeat 14px 50%/14px auto;
    border-radius: 60rem;
    border: 1px solid $blue;
    color: $blue;
  }

  .c-tab__panel .o-button {
    margin-top: 20px;
  }
}

// 고객상담 cusi060000000000
.cs {
  .o-heading.t-cs {
    padding: 14px 0 14px 122px;
  }

  .info-wrap.centerd {
    $root: '.info-wrap';
    padding-left: 0;
    padding-right: 0;
    display: flex;
    flex-direction: column;

    #{$root}__text {
      @include clearfix;
      margin: 0 auto;
      text-align: left;
    }

    span {
      display: inline-block;
    }
  }

  .info-wrap {
    .tit {
      width: 120px;
    }

    .co-black {
      width: 124px;
    }
  }

  .cs-slider {
    margin: 0 -20px;
    padding: 0 20px;

    .slide {
      width: 260px;
      height: auto;
      padding: 40px 20px 30px;
      border-radius: 5px;
      background-color: #f8f8f8;
    }

    .cs-slider__text {
      font-family: youandiB;
      height: 105px;
    }
  }
}

.page-link {
  margin-left: -20px;
  margin-right: -20px;
  border-top: 1px solid #d8d8d8;

  a,
  button {
    position: relative;
    display: block;
    width: 100%;
    padding: 25px 20px;
    border-bottom: 1px solid #d8d8d8;
    font-family: 'youandiB';
    text-align: left;

    &:after {
      content: '';
      position: absolute;
      right: 20px;
      top: 50%;
      margin-top: -3px;
      display: block;
      width: 0;
      padding: 3px;
      border: solid #666;
      border-width: 0 2px 2px 0;
      transform: rotate(-45deg);
    }
  }
}

// 이용절차 fp020000000000
.info-step {
  border: 1px solid #eee;
  border-radius: 8px;
  text-align: center;
  font-family: youandiR;
  font-size: 17px;

  li {
    position: relative;
    padding: 20px 0;

    &:not(:last-child) {
      border-bottom: 1px solid #eee;

      &::before {
        content: '';
        position: absolute;
        bottom: -8px;
        left: 50%;
        transform: translateX(-50%);
        width: 0;
        height: 0;
        border-left: 30px solid transparent;
        border-right: 30px solid transparent;
        border-top: 9px solid #fff;
        z-index: 2;
      }

      &::after {
        content: '';
        position: absolute;
        bottom: -9px;
        left: 50%;
        transform: translateX(-50%);
        width: 0;
        height: 0;
        border-left: 30px solid transparent;
        border-right: 30px solid transparent;
        border-top: 9px solid #eee;
        z-index: 1;
      }
    }

    &:nth-child(even) {
      background-color: #f8f8f8;

      &::before {
        border-top-color: #f8f8f8;
      }
    }
  }
}

// 마이페이지 submain
.noti-top {
  padding: 10px;
  background-color: #f8f8f8;
  text-align: center;
  font-size: 13px;

  .o-icon--right-arrow {
    width: 5px;
    height: 5px;
    margin-left: 7px;
    margin-top: -2px;

    &::before,
    &::after {
      width: 5px;
      height: 2px;
    }
  }
}

.mypage {
  .mypage-head {
    position: relative;
    padding: 50px 20px 50px;
    background: linear-gradient(120deg, $blue 60%, #00a19c 100%);

    &__button {
      display: block;
      width: 40px;
      height: 40px;
      position: absolute;
      top: 17px;
      right: 12px;
      background: url('../img/com/ico_setting.png') no-repeat 50% 50%/24px auto;
    }

    &__name {
      display: block;
      padding-bottom: 5px;
      text-align: center;
      font-size: 24px;
      font-family: youandiB;
      color: $white;
    }

    &__text {
      font-family: 'youandiR';
      font-size: 13px;
      line-height: 24px;
      text-align: center;
      color: #fff;
    }

    &__list {
      display: flex;
      flex-wrap: wrap;
      margin: 36px auto 0;
      padding: 5px 20px;
      border-radius: 14px;
      background-color: #fff;
      font-size: 0;

      &--vertical {
        .mypage-head__anchor {
          width: 100%;
          padding-left: 0 !important;

          &:nth-child(2) {
            border-top: 1px solid #eee;
          }

          &:after {
            right: 6px !important;
          }
        }
      }
    }

    &__anchor {
      position: relative;
      display: flex;
      align-items: center;
      width: 50%;
      height: 55px;
      padding: 0 25px 0 5px;
      font-size: 14px;
      font-family: 'youandiR';
      line-height: 18px;

      &::after {
        position: absolute;
        top: 50%;
        right: 6px;
        display: inline-block;
        width: 3px;
        height: 3px;
        padding: 2px;
        border: solid #666;
        border-width: 0 2px 2px 0;
        transform: translateY(-50%) rotate(-45deg);
        content: '';
      }

      &:nth-child(odd)::after {
        right: 14px;
      }

      &:nth-child(even) {
        padding-left: 13px;
      }

      &:nth-child(n + 3) {
        border-top: 1px solid #eee;
      }
    }
  }

  .mypage-hcoin {
    @include clearfix;
    height: 56px;
    padding: 0 20px;
    background-color: #162645;
    font-family: youandiB;
    color: $white;

    &__title a {
      display: block;
      line-height: 56px;
      font-size: 17px;
      color: #fff;

      .o-icon::before,
      .o-icon::after {
        background-color: #fff;
      }
    }

    &__text {
      line-height: 56px;
    }

    &__button {
      margin-top: 13px;
      border: 1px solid $white;
      color: $white;
    }
  }

  .mypage-detail {
    padding: 0 20px;
    background-color: $white;

    &__title {
      padding: 46px 0 25px;
      font-family: youandiB;
      font-size: 17px;

      &:not(:first-child) {
        padding-top: 0;

        &:before {
          content: '';
          display: block;
          height: 6px;
          margin: 46px -20px;
          background-color: #f5f5f5;
        }
      }
    }

    .heading-button {
      margin-top: -51px;
    }

    .heading-button .o-button {
      line-height: 2;
      font-size: 13px;
      color: #666;
    }

    &__item {
      &.bt {
        margin-top: 26px;
        margin-bottom: 30px;
        padding-top: 30px;
        border-top-color: #ddd !important;
      }

      .inside-list__item {
        position: relative;
        align-items: center;

        &:nth-child(n + 2) {
          margin-top: 25px;
        }

        .c-tooltip {
          position: absolute;
          left: 0;
          right: 0;
        }
      }

      & > .inside-list .inside-list__text,
      & > .inside-list .inside-list__text .link {
        font-size: 17px;
      }
    }
  }

  .product-guide {
    padding: 30px 20px;
    border-radius: 12px;
    border: 5px solid rgba(0, 161, 156, 0.15);
    text-align: center;
    font-size: 19px;
  }

  .payment-step {
    border: none;

    &__number {
      background-color: $blue;
      color: $white;
    }

    .c-tooltip {
      position: absolute;
      left: -100%;
      right: -100%;
    }
  }
}

.ovf-fixed {
  position: fixed;
  top: 59px;
  left: -20px;
  right: -20px;
}

.ovf {
  position: relative;
  z-index: 1;
  background-color: $white;
  transition: transform 0.5s ease-out;
}

// 고객안내 - 내 상담내역 cusi060201000000
.c-accordion__panel.qna .c-accordion__inside {
  padding-top: 30px;
  background-color: #f8f8f8;

  .d-block {
    font-weight: bolder;
  }

  .answer {
    margin-top: 30px;
    padding-top: 30px;
    border-top: 1px solid #d8d8d8;
  }
}

// 보험계약대출 즉시신청
.button-box {
  display: flex;
  flex: 1;
  margin: 0 -2.5px;

  .o-button {
    height: 40px;
    line-height: 40px;
    margin: 0 2.5px;
    font-weight: normal;

    &:active {
      background-color: $blue;
      border-color: $blue;
      color: $white;
    }
  }
}

// 변액보험 펀드 성향 pmp090303030000
.fund-type {
  @include clearfix;
  position: relative;
  height: 360px;
  margin-top: 40px;
  padding-left: 96px;
  border: 1px solid #d8d8d8;
  border-radius: 5px;
  overflow: hidden;

  &__tab {
    position: absolute;
    left: 0;
    top: 0;
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  &__button {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 96px;
    min-height: 71px;
    font-family: youandiB;
    color: #909090;

    & + button {
      border-top: 1px solid #d8d8d8;
    }

    &.is-active {
      background-color: $blue;
      color: $white;

      &.myType:before {
        background-color: $white;
      }
    }

    &.myType:before {
      content: '';
      display: block;
      margin-bottom: 5px;
      width: 4px;
      height: 4px;
      border-radius: 50%;
      background-color: $blue;
    }
  }

  &__panel {
    height: 100%;
    padding: 20px;
    border-left: 1px solid #d8d8d8;
    overflow-y: scroll;

    .o-heading {
      margin-bottom: 5px;
      font-size: 17px;

      & ~ .o-heading {
        margin-top: 15px;
      }
    }

    .c-text-list.half li {
      float: left;
      margin-right: 10px;
      min-width: 50%;
    }
  }
}

// 보험상품 특장점 (보험상품별 서브메인 & 내게 맞는 보험상품 찾기 결과)
.product-spec {
  $root: '.product-spec';
  padding: 0 25px;

  &__item {
    position: relative;
    padding: 25px 15px;
    padding-left: 75px;
  }

  &__text {
    display: flex;
    flex-grow: 1;
    justify-content: center;
    flex-direction: column;
    min-height: 60px;
    font-size: 17px;

    .o-text--star,
    .c-text-list {
      margin-top: 5px;

      .c-text-list__item {
        font-size: 13px;
      }
    }
  }

  &__img {
    position: absolute;
    left: 0;
    display: block;
    width: 60px;
    height: 60px;

    img {
      width: 100%;
    }
  }
}

// step 계약목록에서 상품 없을 경우
.product-wrap {
  .empty {
    margin-top: 0;
  }

  &--blue .empty__text {
    color: white;
  }
}

// 퇴직연금
.irp {
  margin: 0 -20px;

  &-head {
    width: 100%;
    position: relative;
    padding: 55px 20px;
    background-color: $blue;
    text-align: center;
    color: $white;

    &::after {
      content: '';
      display: block;
      position: absolute;
      top: 100%;
      left: 50%;
      margin-left: -62px;
      border-top: 15px solid $blue;
      border-left: 61px solid transparent;
      border-right: 61px solid transparent;
    }

    &__title {
      font-family: youandiB;
      font-size: 24px;

      .o-text--underline::before {
        background-color: #0067a8;
      }
    }

    &__text {
      margin-top: 20px;
      font-size: 17px;
    }

    &.bg-light {
      color: $black;

      &::after {
        border-top-color: #f8f8f8;
      }
    }
  }

  &-panel {
    padding: 60px 20px;
    border-top: 8px solid #f0f0f0;

    .irp-head + & {
      border-top: none;
    }

    &__title {
      margin-bottom: 35px;
      text-align: center;
      font-size: 21px;

      .small {
        font-size: 18px;
      }
    }

    &__text {
      margin-top: -5px;
    }

    &:last-child {
      margin-bottom: -50px;
    }

    .inner {
      padding: 0 17px;
    }

    .inner-cont {
      margin-top: 40px;
      padding-top: 40px;
      border-top: 1px solid #d2d2d2;
    }
  }
}

// 퇴직연금 irp020000000000
.irp {
  .fb-partner {
    padding: 0 15px;

    li {
      max-width: 305px;
      margin: 0 auto;

      &:not(:first-child) {
        margin-top: 20px;
        border-top: 2px solid $blue;
      }

      &:first-child:before {
        content: '';
        display: block;
        height: 2px;
        background-image: linear-gradient(90deg, $blue 0%, #00a19c 100%);
      }
    }

    &__img {
      display: flex;
      align-items: center;
      justify-content: center;

      height: 170px;
      padding-bottom: 25px;
      background-color: #f8f8f8;

      &.fb img {
        width: 58px;
      }

      &.hdmotor img {
        width: 131px;
      }
    }

    &__name {
      display: block;
      margin-bottom: 5px;
      font-size: 18px;
      color: $black;
    }

    &__text {
      margin: -25px 25px 0;
      padding: 20px;
      background-color: $white;
      text-align: center;
      color: #666;
    }
  }

  .performance {
    padding-left: 40px;
    padding-right: 40px;
    text-align: center;

    &-summary {
      border-radius: 8px;
      background-color: #edf6fb;
      border: 1px solid $blue;
      text-align: center;

      li {
        margin: 0 18px;
        padding: 8px 0;
        color: #666;

        strong {
          color: $black;

          @media screen and (max-width: 320px) {
            display: block;
          }
        }

        &:not(:first-child) {
          border-top: 1px solid rgba(0, 118, 192, 0.2);
        }
      }
    }

    &-graph {
      display: inline-block;
      margin-top: 35px;

      img {
        width: 100%;
        max-width: 295px;
      }
    }
  }

  .consulting {
    text-align: center;

    &__img img {
      max-width: 302px;
      width: 100%;
    }

    &__slider {
      padding-top: 330px;
      margin-top: -330px !important;
      text-align: center;

      .slide {
        text-align: center;

        &-title {
          margin-top: 25px;
          font-size: 18px;
          font-weight: bolder;
          color: $blue;
        }

        .o-text {
          text-align: inherit;
        }
      }

      .swiper-pagination-bullet {
        width: 6px;
        height: 6px;
        margin: 0 7px !important;

        &-active {
          background-color: $blue;
        }
      }
    }
  }
}

// 나에게 맞는 퇴직연금 (irp030000000000)
.irp {
  .versus {
    position: relative;
    display: table;
    width: calc(100% - 17px);
    margin: 0 auto;
    border-radius: 10px;
    background: linear-gradient(90deg, $blue 0%, #00a19c 100%);
    box-shadow: 2px 15px 20px 5px rgba(38, 91, 118, 0.15);
    line-height: 1.3;
    font-family: youandiR;
    font-size: 18px;
    color: $white;

    &:before {
      content: '';
      position: absolute;
      left: 50%;
      top: 0;
      display: block;
      width: 1px;
      height: 100%;
      background-color: $white;
    }

    &__item {
      display: table-cell;
      width: 50%;
      vertical-align: middle;
      padding: 28px 10px;
    }

    .vs {
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      margin: -23px 0 0 -23px;
      width: 46px;
      height: 46px;
      background: url('../img/com/img_irp_vs.png') no-repeat 0 0 / cover;
    }

    .em {
      font-family: youandiB;
      font-size: 24px;
    }
  }

  .calc-box {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 25px;
    padding: 23px 10px;
    border-radius: 8px;
    background-color: #edf5fb;

    .operator {
      padding: 0 15px;
      font-weight: bolder;
      font-size: 30px;

      @media screen and (max-width: 320px) {
        padding: 0 10px;
        font-size: 20px;
      }
    }

    .result {
      font-size: 18px;

      @media screen and (max-width: 320px) {
        font-size: 16px;
      }
    }
  }

  .bar-graph {
    li {
      display: flex;
      margin: 5px 0;
    }

    .bar {
      flex: 1;
      display: flex;
      align-items: center;
      height: 60px;
      padding: 0 15px;
      border-radius: 3px;
      background-color: #d9f1f0;
      font-weight: 600;
      color: #007688;

      & ~ .bar {
        margin-left: 5px;
      }

      &.c-blue {
        background-color: #cce4f2;
        color: #004b75;
      }

      &.c-gradient {
        background: linear-gradient(90deg, $blue 0%, #00a19c 100%);
        color: $white;

        &.bar-fixed {
          justify-content: center;
        }
      }
    }

    .bar-fixed {
      flex-grow: 0;
      flex-basis: auto;
    }
  }

  .irp-point-compare {
    text-align: center;

    .compare-text {
      padding-top: 5px;
      @include clearfix;

      dt {
        position: relative;
        display: inline-block;
        padding: 25px;

        .em {
          display: block;
          font-family: youandiB;
          font-size: 18px;
        }

        &:not(:first-of-type):before {
          content: '';
          display: block;
          position: absolute;
          left: 0;
          top: 50%;
          margin-top: -16px;
          width: 1px;
          height: 32px;
          background-color: #d2d2d2;
        }
      }
    }

    .compare-diagram {
      position: relative;
      margin: 0 10px;
      font-family: youandiB;
      color: $white;

      &__item {
        width: calc(50% + 24px);
        padding: 50px 0;
        border-radius: 40px;
        line-height: 1.2;
        font-size: 18px;
        color: $white;

        &:first-child {
          position: relative;
          background: $blue;
          padding-right: 24px;
          overflow: hidden;
          z-index: 1;

          &:before {
            content: '';
            position: absolute;
            left: 100%;
            top: 0;
            margin-left: -48px;
            width: 100%;
            height: 100%;
            border-radius: inherit;
            background: $blue url('../img/com/bg_irp_point.gif') repeat-y 0 100%/48px 1px;
            background-blend-mode: multiply;
          }
        }

        &:last-child {
          position: absolute;
          right: 0;
          top: 0;
          padding-left: 24px;
          background: #00a19c;
        }
      }

      .point {
        display: block;
        margin-bottom: 10px;
        text-transform: uppercase;
        font-family: youandiR;
        font-size: 11px;
      }

      &__sign {
        position: absolute;
        left: 50%;
        top: 0;
        margin-left: -24px;
        width: 48px;
        height: 100%;
        background: url('../img/com/bg_irp_point_operator.png') no-repeat 50% 50%/9px auto;
        z-index: 1;
      }
    }
  }

  .info-wrap.t-dl {
    text-align: left;

    dt:not(:first-of-type) {
      margin-top: 20px;
      padding-top: 20px;
      border-top: 1px solid #d2d2d2;
    }

    dd {
      margin-top: 5px;
    }
  }

  .irp-link-card {
    @include clearfix;
    position: relative;
    min-height: 135px;
    padding: 25px 25px 25px 125px;
    border-radius: 10px;
    background-color: #f8f8f8;
    border: 1px solid #d2d2d2;

    &__img {
      position: absolute;
      left: 25px;
      top: 25px;
      display: block;
      width: 75px;
      height: 75px;
      margin-right: 25px;
      margin-bottom: 20px;

      img {
        width: 100%;
      }
    }

    .o-button {
      display: block;
      border-color: #ddd;
    }

    & + .irp-link-card {
      margin-top: 5px;
    }
  }
}

// 해상도별 개행 처리
.newline {
  // width 320 이하에서 display: none;
  &-md {
    display: block;

    @media screen and (max-width: 320px) {
      display: none;
    }
  }

  // width 320 이하에서 display: block;
  &-sm {
    display: none;

    @media screen and (max-width: 320px) {
      display: block;
    }
  }
}

// 보험계약대출 즉시신청 약관동의 체크하기 전, 약관동의 요소의 윗여백
.checkbox-area .d-none + .agree-check {
  margin-top: 20px;
}

// 공통 (에러 화면)
.error {
  $root: '.error';
  margin-top: 50px;
  padding-top: 80px;
  text-align: center;
  background: url('../img/com/img_error_mark.gif') no-repeat 50% 0/60px auto;

  &-msg {
    font-family: 'youandiR';

    .em {
      display: block;
      margin-bottom: 15px;
      line-height: 1.3;
      font-family: 'youandiB';
      font-size: 21px;
    }
  }

  &-desc {
    margin: 26px auto 0;
    padding: 27px 20px;
    border-radius: 6px;
    background-color: #f8f8f8;
    text-align: center;
    color: #666;
  }

  .c-table {
    margin-top: 44px;
  }

  &-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: calc(100vh - 59px);

    #{$root} {
      margin-top: 0;
    }
  }
}
