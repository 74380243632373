

/* IP020106000000 */
.info-box {
  margin-top: 50px;

  $root: '.info-box';

  &--type1 {
    margin-right: -20px;
    margin-left: -20px;
    padding: 37px 32px 0;
    border-top: 5px solid #ccc;
    text-align: center;

    #{$root}__sub {
      margin-bottom: 10px;

      @include h3-hb;
    }

    #{$root}__desc {
      color: $co-777;
      line-height: 26px;
    }

    #{$root}__button-wrap {
      margin-top: 34px;

      .o-button {
        flex: none;
        padding: 0;
      }

      .o-button ~ .o-button {
        margin-left: 15px;
      }
    }
  }

  &--type2 {
    padding: 21px 20px;
    background-color: $bg-color-f5f;

    .o-text {
      color: $co-666;
    }
  }
}

/* IP020106000000 */
.info-box__my-insurance-fee {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  height: 135px;
  border-radius: 8px;
  background-color: $bg-color-008;
  box-shadow: 0 10px 35px rgba($black, .1);
  color: $white;

  b {
    display: block;
    margin: 5px 0;
    font-weight: normal;
    font-size: 24px;
    font-family: youandiB;
  }

  span {
    display: block;
    margin: 5px 0;
    font-size: 17px;
    font-family: youandiR;
  }
}

.has-l-side {
  overflow: hidden;
}

.l-side {
  background-color: #fff;

  $root: ".l-side";

  &__header {
    position: relative;
    height: 64px;

    [class^="l-side__button"]  {
      position: absolute;
      top: 0;
      font-size: 0;
      width: 50px;
      height: 64px;
    }
  }

  &__logo-link {
    position: absolute;
    top: 0;
    left: 0;
    width: 70px;
    height: 64px;
    background: url('../img/com/logo2.png') no-repeat 50% 50%;
    background-size: 30px;
    font-size: 0;
  }

  &__button--alarm {
    right: 112px;
    background: url('../img/com/icon_side_alarm.png') no-repeat 50% 50%;
    background-size: 25px auto;
  }

  &__button--alarm-new {
    right: 112px;
    background: url('../img/com/icon_side_alarm_new.png') no-repeat 50% 50%;
    background-size: 25px auto;
  }

  &__button--search {
    right: 58px;
    background: url('../img/com/icon_side_search.png') no-repeat 50% 50%;
    background-size: 21px auto;
  }

  &__button--close {
    right: 5px;
    background: url('../img/com/icon_side_close.png') no-repeat 50% 50%;
    background-size: 20px auto;
  }

  &__login {
    display: flex;
    align-items: center;
    padding: 0 20px;
    height: 89px;
    background: linear-gradient(126deg, #0089d1, #18b3af);
  }

  &__login--message {
    font-size: 17px;
    font-family: youandiB;
    color: #fff;

    span {
      display: block;
      margin-top: 3px;
      font-size: 13px;
      font-family: youandiR;
    }
  }

  &__button--login {
    margin-left: auto;
    padding:0 14px;
    height: 36px;
    line-height: 34px;
    border: 1px solid #fff;
    border-radius: 6px;
    color: #fff;
    font-size: 15px;
    font-family: youandiB;
  }
}

.navi-left {
  position: absolute;
  top: 153px;
  right: 0;
  bottom: 0;
  left: 0;
  background: linear-gradient(to right, #f8f8f8 115px, #fff 115px);
  overflow-y: auto;

  &__list-1 {
    margin-top: 30px;
    margin-left: 10px;

    li {
      margin: 6px 0;

      a, b {
        display: flex;
        align-items: center;
        min-height: 43px;
        padding: 10px 15px 10px;
        font-size: 15px;
        font-family: youandiR;
        font-weight: normal;
        color: #000;
      }
    }

    > li {
      &.is-active {
        > a {
          background: linear-gradient(126deg, #0089d1, #18b3af);
          box-shadow: 0px 21px 22px 0px rgba(32, 60, 9, .15);
          color: #fff;
        }

        > ul {
          display: block;
        }
      }

      > a {
        width: 115px;
        border-radius: 6px;
        font-family: youandiB;
      }
    }
  }

  &__list-2 {
    display: none;
    position: absolute;
    top: 30px;
    bottom: 0;
    left: 129px;
    width: calc(100% - (119px + 15px));
    overflow-y: auto;

    li {
      &:first-child {
        margin-top:0;
      }

      &.child {
        > a, b {
          // font-size: 13px;
          font-family: youandiB;
          font-weight: normal;
        }

        ul {
          margin-top: 4px;
        }
      }

      &.is-active {
        > a {
          color: #0089d1 !important;
        }
      }

      a {
        position: relative;
        width: 100%;
        padding-right: 40px;
        font-weight: normal;

        &::before {
          position: absolute;
          top: 50%;
          right: 20px;
          border-width: 3px 0 3px 3px;
          border-style: solid;
          border-color: transparent #000;
          content: '';
          transform: translateY(-50%);
        }
      }
    }

    > li {
      &.submain {
        margin-bottom: 16px;

        > a {
          font-size: 17px;
          color: #000;
          font-family: youandiB;
          font-weight: normal;
        }
      }
    }
  }

  &__list-3 {
    > li:not(.child) {
      a, b {
        padding-left: 25px;
      }
    }

    >li.child {
      > a, b {
        color: #666;
        font-family: youandiR;
        font-weight: normal;
      }
    }
  }

  &__list-4 {
    margin-left: 10px;
  }

  &__list-bottom {
    width: 105px;
    margin-top: 25px;
    margin-left: 10px;

    li ~ li {
      margin-top: 4px;
    }

    a {
      display: flex;
      align-items: center;
      height: 35px;
      padding-left: 15px;
      color: #666666;
      font-size: 13px;
      font-family: youandiR;
      font-weight: 600;
    }
  }
}
