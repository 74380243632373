.o-dimmed {
  overflow: hidden;
  position: fixed;
  top: 0;
  left: 0;
  z-index: $zindex-o-dimmed;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,.6);
  opacity: 0;
  transition: opacity 0ms ease-out;

  &--transition {
    z-index: 100;
    transition-duration: 200ms;
  }

  &.is-active {
    opacity: 1;
  }
}
