/* ---------------------------------------------------------------------------------------------------------------------
<input type="radio" class="o-input o-input--radio" />
<input type="checkbox" class="o-input o-input--checkbox" />
<input type="text" class="o-input o-input--text"/>
<select class="o-input o-input--select"></select>
--------------------------------------------------------------------------------------------------------------------- */
.o-input {
  $root : ".o-input";

  border: 0;
  border-radius: 0;
  background-color: transparent;
  vertical-align: middle;
  color: $black;
  -webkit-transition: border 200ms ease-in-out;
  transition: border 200ms ease-in-out;

  &::-webkit-input-placeholder {
    font-weight: normal;
    color: #666;
  }

  &:-ms-input-placeholder {
    font-weight: normal;
    color: #666;
  }

  &::-moz-placeholder {
    font-weight: normal;
    color: #666;
  }

  &:-webkit-autofill,
  &:-webkit-autofill:focus {
    border-color: #d8d8d8;
    -webkit-text-fill-color: #000;
    -webkit-box-shadow: 0 0 0px 1000px #fff inset;
    transition: background-color 5000s ease-in-out 0s;
  }

  &--radio,
  &--check {
    display: inline-block;
    overflow: hidden;
    position: absolute;
    width: 1px;
    height: 1px;
    opacity: 0;

    label + & + label {
      margin-left: 45px;
    }

    & + label {
      display: inline-block;
      position: relative;
      min-width: 25px;
      min-height: 25px;
      padding-left: 35px;
      font-size: 17px;
      line-height: 25px;
      vertical-align: top;
      cursor: pointer;

      &::before {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 25px;
        height: 25px;
        background-color: $white;
        border: 1px solid #d8d8d8;
        border-radius: 50%;
        content: "";
      }

      &::after {
        display: block;
        position: absolute;
        content: "";
      }
    }

    &:focus + label {
      outline: 1px dotted #767676;
    }

    &:checked + label {
      &::before {
        border-color: #0076c0;
        background-color: #0076c0;
      }
    }

    &#{$root}--small {
      label + & + label {
        margin-left: 40px;
      }

      & + label {
        min-width: 20px;
        min-height: 20px;
        padding-left: 30px;
        font-size: 15px;
        line-height: 20px;

        &::before {
          width: 20px;
          height: 20px;
        }
      }
    }
  }

  &--radio {
    & + label::after {
      top: 8px;
      left: 9px;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background-color: #d8d8d8;
    }

    &:checked + label::after {
      background-color: $white;
    }

    &:disabled + label {
      &::before {
        border-color: #d8d8d8;
        background-color: #f8f8f8;
      }

      &::after {
        background-color: #d8d8d8;
      }
    }

    &:disabled:checked + label {
      &::before {
        border-color: #d8d8d8;
        background-color: #f8f8f8;
      }

      &::after {
        background-color: #0076c0;
      }
    }

    &#{$root}--small {
      & + label::after {
        top: 7px;
        left: 7px;
        width: 6px;
        height: 6px;
      }
    }
  }

  &--check {
    & + label::after {
      top: 13px;
      left: 5px;
      width: 14px;
      height: 11px;
      margin-top: -6px;
      background: url("../img/com/bg_checkbox.png") no-repeat 0 0;
      background-size: 28px auto;
    }

    &:checked + label::after {
      background-position: -14px 0;
    }

    &#{$root}--small {
      & + label::after {
        top: 11px;
        left: 5px;
        width: 11px;
        height: 9px;
        background-size: 23px auto;
        background-position: -1px 0;
      }

      &:checked + label::after {
        background-position: -12px 0;
      }
    }
  }

  &--text,
  &--date {
    display: inline-block;
    width: 100%;
    height: 48px;
    padding: 0 20px;
    border: 1px solid #d8d8d8;
    border-radius: 6px;
    background-color: #fff;
    font-weight: 600;
    font-size: 17px;
    text-align: left;

    &[aria-placeholder] {
      color: #666;
    }

    &[readonly],
    &:disabled {
      background-color: #f8f8f8;
      cursor: default;
    }

    &:focus {
      border-color: #0076c0;
    }

    &#{$root}--small {
      height: 36px;
      font-size: 15px;
    }
  }

  &--text {
    &::-webkit-search-cancel-button {
      display: none;
    }

    &[type="number"]::-webkit-inner-spin-button {
      display: none;
    }
  }

  &--date {
    .aan & {
     &::-webkit-clear-button,
     &::-webkit-inner-spin-button,
     &::-webkit-calendar-picker-indicator {
       -webkit-appearance: none;
       display: none;
     }
    }
    // min-width: 100px;
    // background: #fff url('../img/com/bg_date.png') no-repeat right 20px top 50%;
    // background-size: 10px auto;
  }

  &--textarea {
    display: block;
    width: 100%;
    height: 132px;
    padding: 18px 20px;
    border: 1px solid #d8d8d8;
    border-radius: 6px;
    background-color: #f8f8f8;
    font-size: 15px;
    line-height: 21px;
    color : #666;
    resize: none;
  }

  &--select {
    display: inline-block;
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 48px;
    padding: 0 20px;
    border: 1px solid #d8d8d8;
    border-radius: 6px;
    background: #fff url("../img/com/bg_select.png") no-repeat right 20px top 50%;
    background-size: 10px auto;
    font-size: 17px;
    font-weight: 600;
    color: #222;
    text-align: left;
    text-overflow: ellipsis;
    white-space: nowrap;

    &:disabled,
    &.is-disabled {
      background-color: #f8f8f8;
      cursor: default;
    }

    &:focus {
      border-color: #0076c0;
    }

    &.is-disabled:focus {
      border-color: #ccc;
    }

    &#{$root}--small {
      height: 36px;
      font-size: 15px;
    }
  }
}

.c-radio-box {
  $root : ".c-radio-box";

  display: flex;
  // overflow: hidden;
  width: 100%;
  margin-top: 25px;
  border: 1px solid #d8d8d8;
  border-radius: 6px;
  font-size: 0;

  &__item {
    flex: 1;

    #{$root}__input + label {
      position: relative;
      border-left: 1px solid #d8d8d8;
    }

    &:first-child #{$root}__input + label {
      border-left: 0;
      border-top-left-radius: 6px;
      border-bottom-left-radius: 6px;
    }

    &:last-child #{$root}__input + label {
      border-top-right-radius: 6px;
      border-bottom-right-radius: 6px;
    }
  }

  &__input {
    overflow: hidden;
    position: absolute;
    width: 1px;
    height: 1px;
    opacity: 0;
  }

  &__input + label {
    display: inline-block;
    width: 100%;
    background-color: #fff;
    font-family: youandiR;
    font-size: 15px;
    line-height: 43px;
    text-align: center;
    color: #000;
    cursor: pointer;
  }

  &__input:focus + label {
    outline: 1px dotted #767676;
  }

  &__input:checked + label {
    color: #fff;
    z-index: 1;
    overflow: hidden;
    border-color: #0076c0;

    &:after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      border-color: #0076c0 !important;
      background-color: #0076c0;
      z-index: -1;
    }
  }

  &__input:disabled + label {
    border-color: #d8d8d8;
    background-color: #f8f8f8;
    color: #c8c8c8;

    &:after {
      display: none;
    }
  }
}

/* ---------------------------------------------------------------------------------------------------------------------
기타
--------------------------------------------------------------------------------------------------------------------- */
// 에러 메시지
.message-error {
  display: block;
  width: 100%;
  margin-top: 9px;
  font-size: 15px;
  color: #ff3c50;
}

// 필수입력
.require {
  color: #ff3c50;
}

// 단일 사용, 하단 라인 & 간격 필요
.input-line {
  margin: 0 -20px 40px;
  padding: 30px 20px;
  border-bottom: 8px solid #f0f0f0;
}

// input + text 조합
.input-text {
  position: relative;

  &__unit {
    position: absolute;
    top: 50%;
    right: 15px;
    transform: translateY(-50%);

    .o-input:disabled + & {
      color: #666;
    }
  }

  &__button {
    position: absolute;
    top: 0;
    right: 15px;
    height: 100%;
  }

  .o-input--text {
    padding-right: 40px;
  }
}

// radio, check group
.input-group {
  margin: -25px 0 25px -45px;
  padding-top: 25px;

  &__item {
    display: inline-block;
    margin-top: 25px;
    margin-left: 15px;

    .o-input + label {
      margin-top: 0 !important;
      margin-left: 15px !important;
      vertical-align: middle;
    }

    .o-input:first-child + label {
      margin-left: 0 !important;
    }
  }

  .o-input + label {
    margin-top: 25px;
  }

  .o-input:first-child + label {
    margin-left: 45px;
  }

  &--small {
    margin: -20px 0 20px -40px;
    padding-top: 20px;

    .o-input + label {
      margin-top: 20px;
    }

    .o-input:first-child + label {
      margin-left: 40px;
    }
  }

  .o-heading + &,
  .c-form > &:first-child {
    padding-top: 0;
  }
}

// 동의 체크
.agree-check {
  overflow: hidden;
  margin-top: 50px;
  border: 1px solid #d8d8d8;
  border-radius: 6px;

  &__inside {
    padding: 0 20px;
    background-color: #f8f8f8;
  }

  &__item {
    position: relative;
    padding: 15px 54px 14px 20px;

    & + & {
      border-top: 1px solid #eee;
    }
  }

  &__button {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    width: 45px;
    height: 100%;

    &::before,
    &::after {
      position: absolute;
      right: 20px;
      width: 7px;
      height: 2px;
      background-color: #666;
      content:"";
    }

    &::before {
      top: 50%;
      transform: translateY(-100%) rotate(45deg);
    }

    &::after {
      top: 50%;
      transform: translateY(100%) rotate(-45deg);
    }
  }

  &__inside &__item {
    padding-left: 0;
    padding-right: 24px;
  }

  &__inside &__button {
    right: -20px;
  }

  &--link {
    .o-input--check + label {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

  .o-input--check + label {
    width: 100%;
    font-weight: 600;
    font-size: 15px;

    &::before {
      top: 50%;
      transform: translateY(-50%);
    }

    &::after {
      top: 50%;
    }
  }

  .o-heading--1 + & {
    margin-top: 0;
  }
}

/* ---------------------------------------------------------------------------------------------------------------------
Form
--------------------------------------------------------------------------------------------------------------------- */
.c-form {
  $root : ".c-form";

  margin-top: 25px;

  &--line {
    margin-left: -20px;
    margin-right: -20px;
    padding: 0 20px 30px;
    border-bottom: 8px solid #f0f0f0;
  }

  &__field {
    position: relative;
    padding: 0 20px;
    background-color: $white;
    border: 1px solid #d8d8d8;
    border-radius: 6px;

    &:nth-child(n+2) {
      margin-top: 10px;
    }
  }

  &__label {
    position: absolute;
    top: 50%;
    left: 20px;
    font-size: 17px;
    color: #666;
    transform: translateY(-50%);
    transition: all 100ms;
  }

  &__row {
    display: flex;
    font-size: 0;

    #{$root}__label + & {
      #{$root}__text,
      .o-input {
        height: 64px;
        padding-top: 22px;
      }
    }

    & + & {
      border-top: 1px solid #eee;
    }

    & > *:nth-child(n+2) {
      display: none;
      margin-left: 10px;
    }

  }

  &__auto {
    flex: none !important;
  }

  &__text {
    flex: none !important;
    font-size: 17px;
    line-height: 42px;
    color: #000;
  }

  .o-input--text,
  .o-input--date,
  .o-input--textarea {
    padding: 0;
    border: none;
    background-color: transparent;
  }

  .o-input--date {
    line-height: 42px;

    & + #{$root}__text {
      margin: 0 10px 0 20px;
    }
  }

  .o-input--text {
    height: 42px;
    background-color: transparent !important;
  }

  .o-input--select {
    height: 42px;
    padding: 0;
    border: none;
    background-color: transparent;
    background-position: right 0 top 50%;
  }

  .o-input--text,
  .o-input--date,
  .o-input--textarea,
  .o-input--select {
    color: transparent;

    &::-webkit-input-placeholder {
      color: transparent;
    }

    &:-ms-input-placeholder {
      color: transparent;
    }

    &::-moz-placeholder {
      color: transparent;
    }
  }

  // 상태
  &__field {
    &.has-focus,
    &.has-value,
    &.has-value.is-disabled {
      #{$root}__row {
        & > * {
          display: block;
          flex: 1;
        }
      }

      #{$root}__label {
        top: 0;
        font-size: 13px;
        transform: translateY(10px);
      }

      #{$root}__auto {
        width: auto;
      }

      #{$root}__label + #{$root}__row {
        .o-input--date,
        .o-input--select {
          background-position: right 0 top 40px;
        }
      }

      .o-input {
        color: #000;

        &::-webkit-input-placeholder {
          color: #666;
        }

        &:-ms-input-placeholder {
          color: #666;
        }

        &::-moz-placeholder {
          color: #666;
        }
      }

      .address__button {
        background-position: right 0 top 46px;
      }

      .identifier__first {
        width: 72px;
      }

      .identifier__last {
        width: 115px;
        margin-left: 15px;
      }

      .identifier__only {
        width: 15px;
        margin-left: 15px;
      }
    }

    &.has-focus,
    &.has-value {
      .o-input--textarea {
        height: 108px;
        margin-top: 40px;
        padding-top: 0;
        font-size: 17px;
        line-height: 24px;
      }
    }

    &.has-focus {
      border-color: #0076c0;

      #{$root}__label {
        color: #0076c0;
      }
    }

    &.has-error {
      border-color: #ff3c50;

      #{$root}__label {
        color: #ff3c50;
      }
    }

    &.is-disabled {
      background-color: #f8f8f8;

      #{$root}__label {
        z-index: 1;
      }
    }
  }

  .input-wrap + &__field {
    margin-top: 0;
  }

  input[type=text],
  input[type=number],
  input[type=tel],
  input[type=date],
  input[type=month],
  select,
  textarea,
  button {
    outline: none;
  }
}

.search {
  position: relative;
  width: 100%;
  min-height: 64px;

  &__button {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background: transparent url("../img/com/bg_search.png") no-repeat right 0 top 50%;
    background-size: 17px auto;
  }

  &__text {
    padding: 31px 30px 8px 0;
    font-size: 17px;
    font-weight: 600;
  }

  &__number {
    color: #0076c0;
  }

  .o-input--text {
    height: 50px !important;
    padding-top: 0 !important;
    border-top: 1px solid #eee;
  }
}

.attachment {
  $root : ".attachment";
  position: relative;
  width: 100%;

  &__item {
    position: relative;
    min-height: 42px;
    border-top: 1px solid #eee;
  }

  &__name {
    overflow: hidden;
    width: calc(100% - 30px);
    font-size: 17px;
    font-weight: 600;
    color: $black;
    text-overflow: ellipsis;
    white-space: nowrap;
    line-height: 42px;
  }

  &__input {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 42px;
    opacity: 0;

    &:focus + label {
      outline: 1px dotted #767676;
    }
  }

  &__delete {
    position: absolute;
    bottom: 6px;
    right: -5px;
    width: 30px;
    height: 30px;
    background: transparent url("../img/com/bg_attachment.png") no-repeat 50% 50%;
    background-size: 17px auto;
    content: "";
  }

  &__button {
    position: relative;
    width: 100%;
    height: 64px;

    #{$root}__label {
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
      font-weight: 600;
      font-size: 14px;
      text-decoration: underline;
      line-height: 30px;
      color: #0076c0;
    }
  }

  &__add {
    display: block;
    position: relative;
    border-top: 1px solid #eee;

    #{$root}__label {
      display: block;
      width: 100%;
      font-size: 14px;
      font-weight: 600;
      text-decoration: underline;
      line-height: 42px;
      text-align: center;
      color: #0076c0;
    }
  }

  &.has-file {
    #{$root}__button {
      display: none;
    }

    #{$root}__item:first-child {
      padding-top: 25px;
      border-top: none;
    }
  }
}
