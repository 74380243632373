// 보험상품별 서브메인
.product-submain {
  margin: 0 -20px;
  $root: '.product-submain';

  .product-spec {
    padding: 35px 20px 60px;

    &__item {
      padding-top: 15px;
      padding-bottom: 15px;
    }
  }

  &-head {
    display: flex;
    height: 380px;
    padding: 0 20px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: url('../img/com/bg_product_submain_head_1.png') no-repeat 100% 0/111px auto,
      url('../img/com/bg_product_submain_head_2.png') no-repeat 0 100%/223px auto;
    background-color: #00a39f;
    text-align: center;
    font-size: 18px;
    color: $white;

    #{$root}-head__title {
      margin: 20px 0;
      line-height: 1.2;
      font-family: 'youandiB';
      font-size: 34px;
    }

    // .product-submain__animation2 {
    //   height: 40px !important;
    // }

    #{$root}-head__text {
      font-family: 'youandiR';
      opacity: 1;
    }

    #{$root}-head__label {
      padding: 4px 15px;
      border-radius: 60rem;
      background-color: rgba(0, 0, 0, .18);
      font-family: 'youandiR';
      font-size: 15px;
    }
  }

  &-detail {
    padding: 60px 40px;
    border-top: 8px solid #f0f0f0;

    @media screen and (max-width: 320px) {
      padding-left: 20px;
      padding-right: 20px;
    }

    &.gradient {
      background: linear-gradient(90deg, #0079cc 0%, #17a6a2 100%);

      #{$root}-detail__title {
        color: white;
      }
    }
  }

  #{$root}-detail__title {
    margin-bottom: 35px;
    line-height: 1.3;
    font-family: youandiR;

    .title-em {
      font-family: youandiB;
      font-size: 27px;
    }

    .title-text {
      display: block;

      &:first-child {
        margin-bottom: 10px;
      }

      &:last-child {
        margin-top: 10px;
      }
    }
  }

  #{$root}-detail__text {
    margin-top: -20px;
    font-family: 'youandiR';

    &.fs-17 {
      font-size: 17px;
    }
  }

  #{$root}-detail__label {
    margin-top: 40px;
    text-align: center;
    font-family: youandiB;
    font-size: 17px;

    .label-text {
      display: inline-block;
      padding: 4px 20px;
      border-radius: 60rem;
      border: 1px solid $blue;
    }
  }

  .detail-source {
    display: block;
    margin-top: 20px;
    text-align: center;
    font-size: 13px;
    font-style: normal;
    color: #666;

    &--bg {
      margin-top: 30px;
      padding: 5px;
      background-color: #f8f8f8;
    }
  }

  .detail-content {
    max-width: 360px;
    margin: 35px auto 0;

    &--full {
      max-width: initial;
      margin-left: -40px;
      margin-right: -40px;
      padding: 40px;

      & > * {
        max-width: 360px;
        margin: 0 auto;
      }
    }

    &.px-4 {
      @media screen and (max-width: 320px) {
        padding-left: 0 !important;
        padding-right: 0 !important;
      }
    }

    &.mx-n4 {
      @media screen and (max-width: 320px) {
        padding-left: 20px;
        padding-right: 20px;
      }
    }

    &.mx-n2 {
      @media screen and (max-width: 320px) {
        padding-left: 10px;
        padding-right: 10px;
      }
    }

    @media screen and (min-width: 376px) {
      &.mx-n4,
      &.mx-n2 {
        margin-left: auto !important;
        margin-right: auto !important;
      }
    }

    &.video {
      width: 100%;
    }
  }

  .detail-title {
    margin-bottom: 25px;
    font-family: 'youandiR';
    font-size: 19px;
  }

  .detail-image {
    display: block;
    width: 100%;
    max-width: 360px;
    margin: 0 auto;
    font-size: 0;

    img {
      width: 100%;
    }
  }

  .detail-text {
    margin-top: 15px;
    line-height: 1.6;
  }

  .c-text-list.t-info {
    margin-top: 30px;

    li {
      margin-top: 5px;
      line-height: 1.5;
      font-size: 13px;
      color: #666;
    }
  }

  .detail-content.video {
    position: relative;
    background-color: transparent;
    max-width: initial;
    height: 0;
    padding-top: 75%;

    video {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  .c-button-fixed.po-static {
    padding: 20px;
  }

  // 텍스트 등장효과
  #{$root}__animation1 {
    opacity: 0;
    transition: opacity 1s;

    &.active {
      opacity: 1;
    }
  }

  #{$root}__animation2 {
    display: block;
    overflow: hidden;
    // height: 35px;

    i {
      display: block;
      line-height: inherit;
      font-style: normal;
      transform: translateY(100%);
      transition: transform 1s ease;
    }
  }

  @for $i from 0 to 6 {
    $order: $i + 1;

    [class*='animation1']:nth-child(#{$order}),
    [class*='animation2']:nth-child(#{$order}) i {
      transition-delay: $order * 0.1s;
    }
  }

  // 카드 등장효과
  .product-submain-card,
  .slide-up {
    transform: translateY(70px);
    transition: transform .4s;
  }

  .active {

    #{$root}__animation1 {
      opacity: 1;
    }

    &.product-submain-card,
    &.slide-up,
    & #{$root}__animation2 i {
      transform: translateY(0);
    }
  }
}

.product-submain-card {
  $root: '.product-submain';
  overflow: hidden;
  box-shadow: 20px 25px 40px rgba(0, 0, 0, 0.14);
  border-radius: 15px;
  // margin: 0 -20px;
  border: 1px solid #d8d8d8;
  text-align: center;

  @media screen and (max-width: 320px) {
    margin: 0;
  }

  &+#{$root}-card {
    margin-top: 20px;
  }

  #{$root}-card__head {
    padding: 22px;
    background-color: #018bcf;
  }

  #{$root}-card__panel {
    padding: 22px;
    background-color: $white;
  }

  #{$root}-card__label {
    display: inline-block;
    margin-bottom: 20px;
    padding: 0 15px;
    background-color: #f8f8f8;
    border-radius: 60rem;
    line-height: 30px;
    font-family: youandiB;
  }

  #{$root}-card__highlight {
    display: block;
    line-height: 1.3;
    font-family: youandiB;
    font-size: 29px;
  }

  #{$root}-card__text {
    font-size: 15px;
    color: #666;
  }

  #{$root}-card__foot {
    position: relative;
    padding: 22px;
    background-color: #16a5a3;
    color: $white;
  }

  &--type1 {
    #{$root}-card__highlight {
      color: #018bcf;
    }

    .inside-list {
      margin-top: -9px;

      li {
        display: inline-block;
        margin-top: 9px;
        line-height: 26px;
        padding: 0 10px;
        border-radius: 60rem;
        border: 1px solid $white;
        font-family: youandiR;
        color: $white;
      }
    }
  }

  &--type2 {

    #{$root}-card__label {
      margin-top: 25px;
    }

    #{$root}-card__highlight {
      font-size: 21px;
      color: $white;
    }

    #{$root}-card__panel {
      padding-top: 30px;
      padding-bottom: 40px;
    }

    #{$root}-card__title {
      line-height: 1;
      font-family: youandiB;
      font-size: 21px;
    }

    #{$root}-card__img {
      display: block;
      width: 60px;
      height: 60px;
      margin: 0 auto 5px;
      line-height: 0;

      img {
        width: 100%;
      }
    }

    #{$root}-card__foot::before {
      content: '';
      display: block;
      position: absolute;
      bottom: 100%;
      left: 50%;
      margin-left: -35px;
      border-bottom: 7px solid #16a5a3;
      border-left: 35px solid transparent;
      border-right: 35px solid transparent;
    }

    &.blue #{$root}-card__foot {
      background-color: $blue;

      &::before {
        border-bottom-color: $blue;
      }
    }
  }

  &--type3 {
    padding: 45px 23px;
    background-color: $blue;
    color: $white;

    #{$root}-card__head {
      background-color: inherit;
    }

    #{$root}-card__panel {
      margin: 35px -23px -45px;
      background-color: #0064a3;
      // background-color: rgba(0, 0, 0, 0.15);
    }

    #{$root}-card__text {
      color: white;
    }
  }

  &--type2 .guarantee {
    display: flex;
    margin: 40px -22px 0;
    font-size: 10px;

    &:only-child {
      margin-top: 0;
    }

    &__col {
      position: relative;
      flex: 1;
      text-align: center;
      font-family: youandiR;
    }

    &__amount {
      display: block;
      font-family: youandiB;
    }
  }

  .guarantee--type1 {

    .guarantee__col:not(:first-child) {
      border-left: 1px solid #d8d8d8;
    }

    .guarantee__text {
      font-size: 1.5em;
    }

    .guarantee__amount {
      font-size: 1.7em;
    }
  }

  .guarantee--type2 {

    .guarantee__col:nth-child(2) {

      &::before,
      &::after {
        content: '';
        display: block;
        position: absolute;
        top: 50%;
        left: 0;
        transform: translate(-50%, -50%);
        width: 27px;
        height: 27px;
        background: url('../img/com/img_cross_55x55.png') no-repeat 0 0/cover;
      }

      &::after {
        left: initial;
        right: 0;
        transform: translate(50%, -50%);
      }
    }

    &.col2 {

      .guarantee__col:after {
        content: none;
      }

      .guarantee__amount {
        font-size: 2.1em;
      }

      .guarantee__text {
        font-size: 1.7em;
      }
    }

    &.col3 {

      .guarantee__col:before,
      .guarantee__col:after {
        width: 23px;
        height: 23px;
      }

      .guarantee__amount {
        font-size: 1.7em;
      }

      .guarantee__text {
        font-size: 1.5em;
      }
    }
  }
}

.product-submain-card .ins-plan {
  position: relative;
  display: flex;
  margin: 45px -5px 0;

  li {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-width: 60px;
    margin: 0 5px;
    padding: 15px 5px;
    border-radius: 12px;
    line-height: 1.2;
    background-color: $white;
    font-family: 'youandiR';
    color: $black;

    &.bg-black {
      background-color: $black;
      color: $white;
    }

    &:last-child {
      margin-left: auto;
    }

    &:not(:first-child):before {
      content: '';
      position: absolute;
      top: 0;
      left: 50%;
      display: block;
      width: 38px;
      height: 38px;
      margin-left: -19px;
      margin-top: -28px;
      background: url('../img/insurance/ico_coin.png') no-repeat 0 0/cover;
    }
  }

  @media screen and (max-width: 320px) {
    flex: 1;
    margin: 30px -2px 0;

    &::before {
      content: none;
    }

    li {
      margin: 0 2px;
      min-width: auto;
      flex-grow: 1;

      &:last-child {
        margin-left: 2px;
      }
    }
  }

  &:before {
    content: '';
    display: block;
    position: absolute;
    left: 5px;
    right: 5px;
    top: 50%;
    height: 1px;
    border: 1px dashed $white;
    z-index: -1;
  }

  &__em {
    font-family: youandiB;
  }

  &--type2 {
    margin: 0;
    position: relative;

    &:before {
      content: none;
    }

    &:after {
      content: '';
      display: block;
      position: absolute;
      width: 154px;
      height: 30px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background: url('../img/insurance/bg_max_pension_plan.png') repeat-x 0 50%/auto 30px;
    }

    li {
      padding: 10px;
      background-color: #0064a3;
      border: 1px solid white;
      color: white;
      flex-grow: 0;
      z-index: 1;
    }

    @media screen and (max-width: 320px) {

      &:after {
        width: 98px;
      }

      li {
        margin: 0 2px;
        min-width: 60px;

        &:last-child {
          margin-left: auto;
        }
      }
    }
  }
}

// ZERO 성인병보험
.medical-receipt {
  position: relative;
  padding: 6px 10px 0;
  background: url('../img/insurance/bg_receipt_head.png') no-repeat 0 0/100% auto;

  &-wrap {
    padding: 0 30px 57px;
    border: 1px solid #eee;
    border-bottom: none;
    background-color: #f9f9f9;
    background-image: url('../img/insurance/bg_receipt_inner_shadow.png'),
    url('../img/insurance/bg_receipt_foot.png');
    
    background-repeat: no-repeat;
    background-position: 0 0, 0 100%;
    background-size: 100% auto;
    box-shadow: 0px 12px 14px 1px #e7e7e7;
  }

  &__title {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 147px;
    background: url('../img/insurance/bg_receipt_title.png') no-repeat 50% 0/152px auto;
    font-size: 20px;
  }

  .inside-list {
    
    li {
      display: flex;
      align-items: center;
      padding: 12px 0;
      border-bottom: 1px solid #d8d8d8;
      font-family: 'youandiR';
      font-size: 14px;

      .amount {
        margin-left: auto;
        font-family: 'youandiB';
        font-size: 15px;
      }
    }

    .highlight-item {
      font-size: 15px;
      color: $blue;

      .amount {
        font-size: 17px;
      }
    }
  }
}

// ZERO 메디컬보험
.medical-case {
  $root: '.medical-case';
  width: 94%;
  margin: 0 auto;
  $yPos: 38%;
  
  &__item {
    position: relative;
    width: 100%;
    padding-top: 100%;
    border-radius: 50%;
    background-color: $green;
    transition: opacity 1s;
    opacity: 0;

    &:nth-child(2) {
      margin-top: -#{$yPos};
      background-color: $blue;
      overflow: hidden;
      transition-delay: .5s;

      &:before {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        bottom: #{100% - $yPos};
        width: 100%;
        height: 100%;
        background: #004e82;
        border-radius: inherit;
      }
    }
  }

  &__text {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: #{100% - $yPos};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-family: 'youandiR';
    font-size: 18px;
    color: $white;
  }

  &__em,
  &__point {
    font-family: 'youandiB';
    font-size: 17px;
  }

  &__point {
    font-size: 21px;
  }


  &__item:nth-child(2) #{$root}__text {
    flex-flow: column-reverse;
    top: #{$yPos};
  }

  #{$root}__text.result {
    top: 0;
    height: #{$yPos};
    opacity: 0;
    transition: opacity 1s 1s;

    .medical-case__point {
      font-size: 23px;
      color: #fdff78;
    }
  }

  #{$root}__item.active {
    opacity: 1;

    #{$root}__text.result {
      opacity: 1;
    }
  }
}

// MAX 연금보험,
// MAX 연금보험 하이파이브
.product-submain .graph {
  $root: '.graph';
  overflow: hidden;

  // &__bar {
  //   display: flex;
  //   align-items: center;
  //   position: relative;
  //   min-height: 45px;
  //   margin-bottom: 10px;
  //   padding: 0 10px 0 15px;
  //   border-radius: 0 5px 5px 0;
  //   background-color: #666;
  //   text-align: left;
  //   color: white;

  //   .overflow {
  //     position: absolute;
  //     left: 100%;
  //     top: 50%;
  //     transform: translateY(-50%);
  //     width: 100vw;
  //     margin-left: 10px;
  //     text-align: left;
  //     color: #666;

  //     .graph__data {
  //       margin-left: 10px;
  //     }
  //   }
  // }

  .gradient {
    background: linear-gradient(90deg, $blue 0%, #00a19c 100%);
  }

  &__text {
    display: flex;
    width: 100%;
    line-height: 1.2;
    color: white;
  }

  &__data {
    font-family: 'youandiB';
    margin-left: auto;
  }

  &--type2 {

    #{$root}__bar {
      display: block;
      height: 70px;
      padding: 0;
      padding-right: 10px;
      border-radius: 0;
      background-color: #f8f8f8;
      color: #000;

      &.point {
        margin-bottom: 24px;
        
        .graph__data {
          box-shadow: 5px 10px 20px 0px rgba(0, 137, 209, 0.15);
        }

        :after {
          border-top: 35px solid transparent;
          border-bottom: 35px solid transparent;
          border-left: 11px solid #00a19c;
        }
      }
    }

    #{$root}__data {
      position: relative;
      display: flex;
      align-items: center;
      width: 0;
      height: 100%;
      margin: 0;
      padding-left: 30px;
      background-color: #666;
      text-align: left;
      font-size: 19px;
      color: $white;

      &:after {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 100%;
        padding: 0;
        border-top: 70px solid #666;
        border-bottom: 0px solid transparent;
        border-right: 20px solid transparent;
      }
    }

    #{$root}__text {
      display: flex;
      align-items: center;
      width: auto;
      height: 100%;
      font-family: 'youandiB';
      font-size: 17px;
      color: #666;
    }
  }
}

.ins-tip {
  position: relative;
  background-color: #f8f8f8;
  margin-top: 35px;
  padding: 30px 15px;
  border-radius: 15px;
  text-align: center;

  &__label {
    position: absolute;
    display: inline-block;
    height: 28px;
    margin: 0 auto;
    padding: 0 13px;
    border-radius: 60rem;
    line-height: 28px;
    left: 50%;
    top: -14px;
    transform: translateX(-50%);
    background-color: #f65726;
    font-family: 'youandiB';
    font-size: 13px;
    color: white;
  }
}

// MAX 연금보험 하이파이브
.highfive-scenario {

  &__title {
    display: block;
    border-radius: 5px;
    background-color: #00a19c;
    padding: 18px 0px;
    text-align: center;
    font-family: 'youandiR';
    font-size: 18px;
    color: $white;
  }

  &-list {
    overflow: hidden;
    margin-top: 20px;
    border: 1px solid #e8e8e8;
    border-radius: 5px;

    li {
      position: relative;
      display: flex;
      align-items: center;
      height: 81px;
      justify-content: flex-start;
      flex-direction: row-reverse;
      text-align: center;
      line-height: 1.2;
      font-size: 13px;

      &+li {
        border-top: 1px solid #e8e8e8;

        .pay:after {
          content: '';
          position: absolute;
          left: 50%;
          top: -5px;
          display: block;
          width: 1px;
          height: 10px;
          background-color: #171717;
        }
      }

      &:nth-child(2n) {
        background-color: #f8f8f8;
      }

      &>* {
        flex: 1;
      }

      .pay {
        flex-grow: 0;
        flex-basis: 20%;
      }

      .amount {
        font-size: 18px;
        color: #00a19c;
      }
    }
  }

  &__img {
    font-size: 0;

    img {
      width: 56px;
    }
  }
}

// MAX 저축보험
.max-saving-ref {

  .o-heading {
    margin-top: 25px;
    margin-bottom: 0;
    position: relative;
    text-align: center;
    z-index: 1;

    &>span {
      padding: 0 10px;
      background-color: $white;
    }
  }

  .o-heading {

    &::before {
      content: '';
      display: block;
      width: calc(100% - 10px);
      max-width: 360px;
      height: 5px;
      top: 50%;
      margin-top: -2.5px;
      background-color: #d9f1f0;
      position: absolute;
      z-index: -1;
    }

    &::after {
      content: '';
      display: block;
      position: absolute;
      right: 0;
      top: 50%;
      padding: 0;
      border-left: 15px solid #d9f1f0;
      border-top: 10px solid transparent;
      border-bottom: 10px solid transparent;
      transform: translateY(-50%);
    }
  }
}

// MAX 종신보험
// .life-vs {
//   position: relative;
//   margin: 30px auto 0;
//   text-align: center;
//   @include clearfix;
//   $wrapperH: 245px;

//   &__title {
//     margin-bottom: 30px;
//     font-family: 'youandiR';
    
//     .em {
//       font-size: 18px;
//       font-family: 'youandiB';
//     }
//   }

//   .text-vs {
//     display: inline-flex;
//     height: #{$wrapperH};
//     align-items: center;
//     font-family: 'youandiB';
//     font-size: 17px;
//     color: #006aac;
//   }

//   &.active .text-vs {
//     animation: em-ani 1s .4s forwards;
//   }

//   @keyframes em-ani {
//     50% {
//       transform: scale(1.5);
//     }

//     100% {
//       transform: scale(1);
//     }
//   }

//   &__item {
//     width: 120px;
//     height: #{$wrapperH};
//     line-height: 1.3;
//     font-family: 'youandiR';
//     font-size: 14px;
//     transition: transform 1s;
//   }

//   table {
//     border-spacing: 0 3px;

//     td {
//       padding: 13px 0;
//       color: $white;

//       &.bg-gradient {
//         background-image: linear-gradient(90deg, $blue 0%, #00a19c 100%);
//         color: $white !important;
//       }

//       &.co-dark {
//         background-color: #eceff8;
//       }
//     }
//   }

//   table:nth-of-type(1) {
//     float: left;
//     transform: translateX(-100%);

//     @for $i from 1 through 2 {
//       $opacity: 0.9 / $i;

//       tr:nth-child(#{$i}) td {
//         background-color: rgba(0, 0, 0, $opacity);
//       }
//     }
//   }

//   table:nth-of-type(2) {
//     float: right;
//     transform: translateX(100%);

//     .bg-point {
//       background-color: #e5f3fa;
//       border: 1px dashed $blue;
//       font-family: 'youandiB';
//       color: #006aac;
//     }
//   }

//   &.active .life-vs__item {
//     transform: translateX(0);
//   }
// }

.life-vs {
  position: relative;
  margin: 0 auto;
  text-align: center;
  $wrapperH: 245px;

  .life-vs-wrap {
    position: relative;
    @include clearfix;
  }

  .text-vs {
    position: absolute;
    display: inline-block;
    width: 100%;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    font-family: 'youandiB';
    font-size: 17px;
    color: #006aac;
  }

  &.active .text-vs {
    animation: em-ani 1s .4s forwards;
  }

  @keyframes em-ani {
    50% {
      transform: translateY(-50%) scale(1.5);
    }

    100% {
      transform: translateY(-50%) scale(1);
    }
  }

  &__head {
    padding: 12px 0;
    margin-bottom: 5px !important;
    background-color: $white;
    border-top: 1px solid #333;
    color: #333;

    &.co-blue {
      border-top-color: $blue;
    }
  }

  &__col {
    display: flex;
    flex-direction: column;
    width: 41%;
    height: #{$wrapperH};
    margin: -2px 0;
    line-height: 1.3;
    font-family: 'youandiR';
    font-size: 14px;
    color: $white;
    transition: transform 1s;

    .life-vs__item {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 2px 0;
      padding: 20px 0;

      sup {
        display: block;
        position: absolute;
        top: 4px;
        right: 4px;
        width: 15px;
        height: 15px;
        border-radius: 50%;
        background-color: $white;
        line-height: 15px;
        font-family: $font-family-base;
        color: #666;
      }
    }

    .bg-gradient {
      background-image: linear-gradient(90deg, $blue 0%, #00a19c 100%);
      color: $white !important;
    }

    .bg-gray {
      background-color: #666 !important;
    }

    .bg-darkblue {
      background-color: #00558a;
    }

    .co-dark {
      background-color: #eceff8;
    }

    .bg-point {
      background-color: #e5f3fa;
      border: 1px dashed $blue;
      font-family: 'youandiB';
      color: #006aac;
    }
  }

  &__col:nth-of-type(1) {
    float: left;
    transform: translateX(-70%);
  }

  &__col:nth-of-type(2) {
    float: right;
    transform: translateX(70%);
  }

  &.active .life-vs__col {
    transform: translateX(0);
  }
}

.life-vs--type2 {

  .life-vs__title .em {
    display: block;
    margin-bottom: 20px;
    font-family: 'youandiB';
    font-size: 18px;
  }

  .life-vs__item {
    margin: 0;
    flex-grow: 1;
  }
}

.life-guarantee {

  dl {
    margin-top: 40px;
    padding-left: 20px;

    dt {
      font-family: 'youandiB';
      font-size: 19px;
    }

    dd {
      font-family: 'youandiR';
      font-size: 16px;
    }

    @media screen and (max-width: 320px) {
      & {
        padding-left: 0;
      }
    }
  }

  .inside-card-wrap {
    @include clearfix;
    position: relative;
    display: flex;
  }

  .inside-card {
    // position: relative;
    overflow: hidden;
    margin-top: 25px;
    border-radius: 15px;
    background-color: #00a19c;
    text-align: center;
    font-family: 'youandiR';
    font-size: 10px;
    color: $white;

    &:first-of-type:before {
      content: '';
      position: absolute;
      display: block;
      bottom: 58px;
      left: 50%;
      width: calc(50% - 25px);
      height: 5px;
      transform: translateX(-50%);
      background: url('../img/insurance/bg_life_guarantee_arr.png') repeat-x 0 0/20px auto;
    }

    &.bg-gray {
      background-color: #666 !important;
    }

    &.w-50 {

      &:before {
        background-image: url('../img/insurance/bg_life_guarantee_dot_2.png');
      }

      .inside-card__head {
        padding: 30px 10px 20px;
      }

      .em {
        font-size: 1.2em;
      }
    }

    &__head {
      padding: 40px 10px 30px;
      font-size: 1.8em;

      .em {
        display: block;
        font-family: 'youandiB';
        font-size: 1.6em;
      }
    }

    &__panel {
      display: flex;
      flex-grow: 1;
      position: relative;
      padding: 20px 10px 25px;
      font-size: 14px;
      color: $white;

      &:before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        display: block;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, .15);
      }

      .panel-text {
        flex: 1;
        position: relative;
        display: inline-block;
        padding-top: 20px;
        background: url('../img/insurance/bg_life_guarantee_dot_1.png') no-repeat 50% 0/12.5px auto;
      }
    }
  }

  .inside-tooltip {
    position: absolute;
    left: 50%;
    top: 100%;
    margin-top: -16px;
    transform: translateX(-50%);
    min-width: 146px;
    padding: 7px;
    border-radius: 60rem;
    background-color: #ffde00;
    text-align: center;
    font-size: 13px;
    color: $black;
    box-shadow: 0px 6px 19px 0px rgba(0, 0, 0, 0.15);
    z-index: 1;

    &::before,
    &::after {
      content: '';
      display: block;
      position: absolute;
    }

    &::before {
      bottom: 100%;
      left: 50%;
      margin-left: -17px;
      width: 34px;
      height: 11px;
      background: url('../img/insurance/bg_life_guarantee_tooltip.png') no-repeat 0 0/cover;
    }

    &:after {
      left: 50%;
      margin-left: -5px;
      bottom: 92px;
      width: 10px;
      height: 10px;
      background: url('../img/insurance/bg_life_guarantee_tooltip_dot.png') 0 0/cover;
    }
  }
}

// MAX 종신보험 라이트
// .life-vs--type2 {

//   table {
//     border-spacing: 0;
//     table-layout: fixed;

//     th {
//       height: 50px;
//       background-color: $white;
//       border-top-width: 1px;
//       border-top-style: solid;
//       border-bottom: 5px solid #f8f8f8;
//     }

//     td {
//       position: relative;
//     }
//   }

//   table:nth-of-type(1) {

//     th {
//       border-top-color: $blue;
//       color: $blue;
//     }

//     td {
//       background-color: $blue !important;
//       color: $white;

//     }

//     tr:last-of-type td {
//       background-color: #00558a !important;
//     }
//   }

//   table:nth-of-type(2) {

//     th {
//       border-top-color: $dark;
//       color: $dark;
//     }

//     td {
//       background-color: #666;
//       color: $white;
//     }

//     tr:last-of-type td {
//       background-color: $dark;
//     }
//   }
// }

// MAX 종신보험 선지급형
.life-advance-ref {
  width: 270px;
  height: 270px;
  border-radius: 50%;
  background-color: #f8f8f8;
  margin: 0 auto 30px;
  font-family: 'youandiR';
  font-size: 19px;
  overflow: hidden;

  &__img {
    display: block;
    width: 147.5px;
    height: 130px;
    margin: 25px auto 0;

    img {
      width: 100%;
    }
  }

  &.bg-green {
    background-color: #e5f5f5;
  }
}

.life-advance-guarantee {

  @media screen and (min-width: 360px) {
    margin-left: -10px;
    margin-right: -10px;
  }
  
  &__item {
    position: relative;
    display: table;
    table-layout: fixed;
    width: 100%;
    min-height: 142px;
    border: 1px solid #e8e8e8;
    border-radius: 10px;

    &:not(:first-child) {
      margin-top: 15px;
    }
  }

  &__title {
    display: block;
    margin-bottom: 15px;
    font-family: 'youandiR';
    font-size: 19px;
  }

  .inside-list .inside-list__item {
    display: inline-block;
    margin-top: 2px;
    line-height: 1.5;
    font-size: 15px;
    color: #666;
    
    &:not(:last-child):after {
      content: ', ';
    }
  }

  .panel {
    display: table-cell;
    padding: 25px;
    vertical-align: middle;
  }

  .panel--aside {
    position: relative;
    width: 30%;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 0 10px 10px 0;
    background-color: #00a19c;
    border-left: 1.5px dashed white;
    line-height: 1.3;
    font-family: 'youandiB';
    text-align: center;
    font-size: 19px;
    color: $white;

    .aside-label {
      display: inline-block;
      height: 27px;
      margin-bottom: 10px;
      padding: 0 10px;
      border-radius: 60rem;
      background-color: #007688;
      line-height: 27px;
      font-size: 15px;
    }

    .aside-text {
      display: block;
    }

    &:before,
    &:after {
      content: '';
      display: block;
      position: absolute;
      top: -1px;
      left: 0;
      margin-left: -7px;
      width: 14px;
      height: 7px;
      border-radius: 0 0 7px 7px;
      background-color: $white;
      border: 1px solid #e8e8e8;
      border-top: none;
      z-index: 1;
    }

    &:after {
      top: initial;
      bottom: -1px;
      transform: rotate(180deg);
    }
  }
}

// ZERO 정기보험 ip020601000000
.ins-compare {
  position: relative;
  padding: 40px;
  background: linear-gradient(0deg, $blue 0%, #00a19c 100%);
  font-size: 10px;
  color: $white;
  $myW: 100vw;
  $myH: calc(100vh - 100px);
  // 대략 헤더 높이와 보험료확인하기 버튼 높이 빼주기.
  width: #{$myW};
  height: #{$myH};
 
  &-wrap {
    margin: 40px -40px 0;
  }

  &::before,
  &::after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    right: 100%;
    width: 50vw;
    height: 100%;
    background: linear-gradient(0deg, $blue 0%, #00a19c 100%);
  }

  &::after {
    left: 100%;
    right: initial;
    background: $dark;
  }

  .triangle {
    position: absolute;
    top: 0;
    right: 0;
    width: 0;
    height: 0;
    border-bottom: #{$myH} solid #333;
    border-left: #{$myW} solid transparent;
  }

  &__item {
    font-size: 1em;

    &:last-child {
      position: absolute;
      right: 4em;
      bottom: 4em;
      z-index: 10;
      text-align: right;
    }
  }

  .vs {
    position: absolute;
    top: 50%;
    left: 50%;
    text-align: center;
    letter-spacing: -5px;
    font-family: 'youandiB';
    font-size: 85px;
    color: $dark;
    transform: translate(-50%, -50%) skewX(-19deg);
    z-index: 5;
  }

  &__title {
    display: block;
    line-height: 1.4;
    font-family: youandiB;
    font-size: 1.8em;
  }

  &__highlight {
    display: block;
    margin: 15px 0;
    font-family: youandiB;
    font-size: 1.9em;

    .em {
      font-size: 1.4em;
    }

    .fee .em::first-letter {
      font-family: youandiR;
    }
  }

  &__text {
    font-family: youandiR;
    font-size: 1.4em;
  }

  .product-submain__animation2 {
    height: auto;
  }
}

@media screen and (max-width: 320px) {

  .ins-compare-wrap {
    margin-left: -20px;
    margin-right: -20px;
  }

  .ins-compare {
    padding: 2em;

    .vs {
      font-size: 6.5em;
    }

    &__highlight {
      margin: 12px 0;
      line-height: 1.3;
      font-size: 1.6em;
    }

    &__item:last-child {
      right: 2em;
      bottom: 2em;
    }
  }
}

@media screen and (min-width: 376px) {

  .ins-compare-wrap {
    margin: 40px -20px 0;
  }

  .ins-compare {
    width: 375px;
    height: 575px;
    margin: 0 auto;
  }

  .triangle {
    border-bottom-width: 575px !important;
    border-left-width: 375px !important;
  }
}

.card-compare {
  $itemH: 210px;
  display: flex;
  border: none;
  box-shadow: none;
  background-color: #eceff8;

  &__item {
    flex: 1;
    height: $itemH;
    padding: 50px 20px;
    font-family: youandiR;
    font-size: 23px;
  }

  &__item.bigger {
    position: relative;
    background-color: #00a39f;
    color: $white;

    &:before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      z-index: 2;
      border-left: 21px solid #00a39f;
      border-top: #{$itemH/2} solid #eceff8;
      border-bottom: #{$itemH/2} solid #eceff8;
    }
  }

  &__image {
    display: block;
    width: 46px;
    height: 46px;
    margin: 0 auto 10px;

    img {
      width: 100%;
    }
  }

  &__em {
    font-family: youandiB;
  }
}



// ZERO 치아보험
.dental-ins-spec {
  $root: '.dental-ins-spec';

  &__item {
    width: 100vw;
    background-color: white;
    padding: 35px 0 30px 35px;
    border-radius: 15px 0 0 15px;
    transform: translateX(80px);
    transition: transform .5s;

    &+#{$root}__item {
      margin-top: 15px;
    }

    &.active {
      transform: translateX(-20px);
      
      @media screen and (max-width: 320px) {
        transform: translateX(0);
      }

      #{$root}__icon {
        transform: rotate(360deg);
      }
    }
  }

  &__icon {
    display: block;
    width: 22px;
    height: 22px;
    transition: transform .5s .2s;

    img {
      width: 100%;
    }
  }

  &__title {
    display: block;
    margin: 10px 0;
    font-family: 'youandiB';
    font-size: 21px;
  }

  .c-text-list .c-text-list__item {
    margin-top: 0;
    font-family: 'youandiR';

    &:before {
      width: 4px;
      height: 4px;
    }
  }
}

// 질병순위 룰렛
.ranking-roulette {
  $root: '.roulette';
  background: #eceff8;
  border-radius: 15px;
  padding: 35px 15px;
  text-align: center;
  position: relative;

  #{$root}-title {
    font-size: 19px;
  }

  .roulette-container {
    position: relative;
    margin-top: 20px;
    height: 150px;
    background: url('../img/insurance/bg_roulette.png') no-repeat 0 0/100% 100%;
    text-align: center;
    overflow: hidden;
  }

  #{$root}-item {
    transition: none;
    height: 45px;
    line-height: 45px;
    white-space: nowrap;
    font-family: 'youandiR';
    font-size: 15px;
    color: #ceced0;
    overflow: hidden;

    &.finish {
      transition-property: font-size;
      transition-duration: .4s;

      &.last_motion {
        font-family: 'youandiB';
        font-size: 24px;
        color: $blue;

        @media screen and (max-width: 320px) {
          font-size: 20px;
        }
      }
    }
  }

  #{$root}-item-wrapper {
    transition: 1s ease-out;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }
}

// 보험상품 상세 막대 그래프
.graph-container {
  $root: '.graph';
  width: 100%;

  .active#{$root}__bar .graph__value,
  .active#{$root}__bar .graph__label {
    opacity: 1;
  }

  #{$root}__bar {

    &.bg-blue {
      background-color: $blue;
    }

    &.bg-gradient {
      background: linear-gradient(0deg, $blue 30%, #00a19c 100%);
    }

    &.bt {
      border-top-width: 3px !important;
      border-top-color: $blue !important;
    }
  }

}

.graph-container--vertical {
  $root: '.graph';

  #{$root}-grid {
    height: 260px;
    box-sizing: border-box;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    border-top: 1px solid #eee;

    #{$root}-grid-line {
      position: absolute;
      width: 100%;
      border-top: 1px solid #eee;

      @for $i from 1 through 10 {
        &:nth-child(#{$i}) {
          top: 10%*$i;
        }
      }
    }
  }

  #{$root}-item-wrap {
    font-size: 0;
    text-align: center;
    border-bottom: 1px solid #eee;

    &.outset {

      #{$root}__value {
        bottom: 100%;
        padding-bottom: 10px;
      }

      .bg-gradient #{$root}__value {
        color: $blue;
      }
    }

    &.inset {

      #{$root}__value {
        top: 25px;
        color: #666;
      }

      .bg-bluegreen #{$root}__value,
      .bg-blue #{$root}__value,
      .bg-gradient #{$root}__value {
        color: $white;
      }
    }

    #{$root}__item {
      position: relative;
      display: inline-block;
      height: 260px;
      margin: 0 10px;
    }
  }

  #{$root}-data-wrap {
    height: 260px;
    box-sizing: border-box;
    position: relative;
  }

  #{$root}__bar {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 0;
    box-sizing: border-box;
    background-color: #eceff8;
    transition: 1s;
  }

  #{$root}__value {
    position: absolute;
    left: 0;
    width: 100%;
    text-align: center;
    font-family: 'youandiB';
    font-size: 17px;
    color: $black;
    opacity: 0;
    transition: opacity 2s .5s;
  }

  #{$root}-label-wrap {
    display: flex;
    text-align: center;
    margin-top: 12px;
    font-size: 0;

    #{$root}__label {
      display: inline-block;
      flex: 1;
      letter-spacing: -0.5px;
      font-size: 14px;
      color: #666;

      &.fb-auto {
        flex: 0 1 auto;
      }
    }
  }

  // ZERO 암보험 그래프
  &.t-cancel {

    #{$root}__item {
      width: 24%;
      height: 260px;
    }

    #{$root}__bar--1.active {
      height: 54%;
    }

    #{$root}__bar--2.active {
      height: 65%;
    }

    #{$root}__bar--3.active {
      height: 80%;
    }
  }

  // MAX 저축보험 그래프
  &.t-savings {
    position: relative;

    .graph__bar--1 {
      .graph__value > div {
        position: relative;

        .legend {
          position: absolute;
          top: -1px;
          left: -25px;
        }
      }
    }

    #{$root}__item {
      width: 24%;
      margin: 0 10px;
    }

    #{$root}__bar {
      background: #eceff8;
      border-radius: 0;
      display: flex;
      flex-direction: column;
    }

    #{$root}__bar--1.active {
      height: 54%;
    }

    #{$root}__bar--2.active {
      height: 65%;
    }

    #{$root}__bar--3.active {
      height: 90%;
    }

    #{$root}__value {
      position: static;
      font-family: 'youandiR';
      font-size: 14px;

      &.outset {
        position: absolute;
        bottom: 100%;
        padding-bottom: 10px;
        white-space: nowrap;
      }

      &--base {
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 1;
      }

      .o-icon--up {
        margin-right: 10px;
        border-left: 5px solid transparent;
        border-bottom: 5px solid $white;
        border-right: 5px solid transparent;
      }
    }
  }
}

.graph-container--horizontal {
  $root: '.graph';
  overflow: hidden;
  width: auto;

  #{$root}__item {
    position: relative;
    display: block;
    width: 100%;
    height: 70px;
    padding: 0;
    background-color: #f8f8f8;
    font-size: 17px;
    color: #000;

    &+#{$root}__item {
      margin-top: 10px;
    }

    &--highlight {
      margin-bottom: 24px;

      &>#{$root}__value,
      &>#{$root}__label {
        color: $blue !important;
      }

      :after {
        border-top: 35px solid transparent !important;
        border-left: 12px solid $green;
        border-bottom: 35px solid transparent;
      }
    }
  }

  #{$root}__bar {
    position: relative;
    display: flex;
    align-items: center;
    width: 20%;
    height: 100%;
    margin: 0;
    padding-left: 30px;
    border-radius: 0;
    background-color: #666;
    text-align: left;
    color: $white;
    transition: width 1s;
    font-family: 'youandiB';

    &.bg-gradient {
      background: linear-gradient(90deg, $blue 0%, #00a19c 100%);
    }

    &:after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 100%;
      padding: 0;
      border-top: 70px solid #666;
      border-right: 18px solid transparent;
    }
  }

  #{$root}__bar #{$root}__label,
  #{$root}__bar #{$root}__value {
    color: white;
    opacity: 0;
    transition: opacity .5s .5s;
  }


  #{$root}__item {

    &>#{$root}__label,
    &>#{$root}__value {
      position: absolute;
      right: 15px;
      top: 0;
      display: flex;
      align-items: center;
      width: auto;
      height: 100%;
      font-family: 'youandiB';
      color: #666;
      z-index: 1;
    }
  }

  &.type2 {

    #{$root}__item {
      height: 40px;
      background-color: transparent;
    }

    #{$root}__bar {
      justify-content: space-between;
      border-radius: 0 5px 5px 0;
      padding: 0 10px 0 15px;

      &:after {
        content: none;
      }

      & > *{
        font-size: 15px;
      }

      #{$root}__label {
        font-family: 'youandiR';
      }

      #{$root}__value {
        padding-left: 10px;
      }

      .outset {
        position: absolute;
        left: 100%;
        width: max-content;
        padding-left: 10px;
        color: #666;

        * {
          display: inline;
          color: inherit;
        }
      }
    }
  }

  // MAX 종신보험 라이트 선지급형 그래프
  &.t-life-advance {

    #{$root}__item {
      height: 64px;

      &--highlight #{$root}__bar:after {
        border-top-width: 32px !important;
        border-bottom-width: 32px !important;
      }
    }

    #{$root}__bar:after {
      border-top-width: 64px;
    }

    #{$root}__bar--1.active {
      width: 59%;
    }

    #{$root}__bar--2.active {
      width: 50%;
    }

    #{$root}__bar--3.active {
      width: 42%;
    }

    #{$root}__bar--4.active {
      width: 33%;
    }

    .graph__label {
      right: 15px;
    }
  }

  // ZERO 치아보험 그래프
  &.t-dental {

    #{$root}__bar--1.active {
      width: 67%;
    }

    #{$root}__bar--2.active {
      width: 58%;
    }

    #{$root}__bar--3.active {
      width: 50%;
    }
  }

   // MAX 연금보험 그래프
   &.t-pension {
     
    #{$root}__bar--1.active {
      width: 80%;
    }

    #{$root}__bar--2.active {
      width: 95%;
    }

    #{$root}__bar--3.active {
      width: 85%;
    }

    #{$root}__bar--4.active {
      width: 40%;
    }

    #{$root}__bar--5.active {
      width: 5%;
    }
  }
}

// ZERO 메디컬보험 그래프
.graph-container.t-medical {
  position: relative;

  .graph-item-wrap {
    border-bottom-color: $green;
  }

  .graph__item {
    width: 12%;
    margin: 0 5px;

    .graph__value {
      font-size: 14px;
      color: $black;
    }
  }

  .graph__bar {
    border-radius: 0;
    background-color: #e0e9f2;
  }

  .graph__bar--1.active {
    height: 85%;
  }

  .graph__bar--2.active {
    height: 63%;
  }

  .graph__bar--3.active {
    height: 37%;
  }

  .graph__bar--4.active {
    height: 28%;
  }

  .graph__bar--5.active {
    height: 26%;
  }

  .graph__bar--6.active {
    height: 22%;
  }

  .average-value {
    position: absolute;
    width: 100%;
    height: 10px;
    top: 165px;
    text-align: right;
    background: url('../img/insurance/bg_graph_standard_line_1.png'),
      url('../img/insurance/bg_graph_standard_line_2.png');
    background-repeat: no-repeat, no-repeat;
    background-position: 0 50%, 100% 50%;
    background-size: auto 10px;

    span {
      position: relative;
      right: 30px;
      padding: 0 1px;
      line-height: 30px;
      display: inline-block;
      font-family: 'youandiB';
      background-color: #f8f8f8;
      font-size: 19px;
    }

    &:before {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      top: 5px;
      width: 100%;
      height: 1px;
      background-color: $blue;
    }
  }
}

// 경제적 부담 콘텐츠 (저금통에서 돈 떨어지는 애니메이션)
.obj-pig-container {
  // position: absolute;
  position: relative;
  text-align: center;
  margin-bottom: -60px;
  // width: 340px;
  padding: 0 20px 115px;
  overflow: hidden;
  font-size: 0;

  &:before {
    content: '';
    position: absolute;
    top: -20px;
    left: 50%;
    display: block;
    background: url('../img/insurance/bg_pig_gradient.png') no-repeat 0 0/cover;
    width: 208px;
    height: 208px;
    transform: translateX(-50%);
    opacity: .6;
  }

  .obj-pig {
    position: relative;
    display: inline-block;
    width: 147px;
    height: 106px;
    margin: 0 auto;
    background: url('../img/insurance/img_pig.png') no-repeat 0 0/cover;
    z-index: 5;
  }

  .coins-wrap {
    position: absolute;
    top: 50px;
    left: 50%;
    height: 244px;
    width: 60px;
    margin-left: -30px;
    z-index: 3;

    .coin {
      position: absolute;
      display: inline-block;
      width: 27px;
      height: 27px;
      background: url('../img/insurance/img_pig_coin.png') no-repeat 0 0/100%;

      &--1 {
        left: 35px;
      }

      &--2 {
        left: 10px;
      }

      &--3 {
        right: 0;
      }

      &--4 {
        left: 25px;
      }
    }
  }

  &.active .coin {
    animation-iteration-count: infinite;

    &--1 {
      animation-name: falling-type1;
      animation-duration: 1.3s;
    }

    &--2 {
      animation-name: falling-type2;
      animation-duration: 1s;
      animation-delay: 1.5s;
    }

    &--3 {
      animation-name: falling-type1;
      animation-duration: 1.6s;
      animation-delay: 2s;
    }

    &--4 {
      animation-name: falling-type2;
      animation-duration: 1.8s;
      animation-delay: 2.5s;
    }
  }

  @keyframes falling-type1 {
    from {
      transform: scale(1);
      bottom: 100%;
      opacity: 1;
    }

    to {
      transform: scale(0.8);
      bottom: -50px;
      opacity: 0.5;
    }
  }

  @keyframes falling-type2 {
    from {
      transform: scale(0.8);
      bottom: 100%;
      opacity: 0.8;
    }

    to {
      transform: scale(0.6);
      bottom: -50px;
      opacity: 0.4;
    }
  }
}

// 돈 저축되는 콘텐츠 (저금통으로 돈 떨어지는 애니메이션)
.obj-pig-container.type2 {
  padding: 50px 20px 80px;
  bottom: initial;
  overflow: visible;

  &:before {
    width: 340px;
    height: 340px;
    top: -60px;
  }

  &:after {
    content: '';
    position: absolute;
    display: block;
    top: 50px;
    left: 50%;
    margin-left: -73px;
    width: 147px;
    height: 106px;
    z-index: 8;
    background: transparent url('../img/insurance/img_pig_over.png') no-repeat 0 0/cover;
  }

  .coins-wrap {
    top: 0;
    height: 100px;
    z-index: 5;

    .coin {
      opacity: 0;
    }
  }
}