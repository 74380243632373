.c-loading {
  font-size: 0;

  &__bars {
    position: fixed;
    top: 50%;
    left: 50%;
    z-index: 101;
    transform: translate(-50%);
    width: 50px;
    height: 50px;
  }

  &__bar {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;

    &::before {
      display: block;
      width: 15%;
      height: 15%;
      margin: 0 auto;
      border-radius: 100%;
      background-color: #0076c0;
      animation: loading-bar 1.2s infinite ease-in-out both;
      content: '';
    }

    &--2 {
      transform: rotate(30deg);

      &::before {
        animation-delay: -1.1s;
      }
    }
    &--3 {
      transform: rotate(60deg);

      &::before {
        animation-delay: -1s;
      }
    }
    &--4 {
      transform: rotate(90deg);

      &::before {
        animation-delay: -0.9s;
      }
    }
    &--5 {
      transform: rotate(120deg);

      &::before {
        animation-delay: -0.8s;
      }
    }
    &--6 {
      transform: rotate(150deg);

      &::before {
        animation-delay: -0.7s;
      }
    }
    &--7 {
      transform: rotate(180deg);

      &::before {
        animation-delay: -0.6s;
      }
    }
    &--8 {
      transform: rotate(210deg);

      &::before {
        animation-delay: -0.5s;
      }
    }
    &--9 {
      transform: rotate(240deg);

      &::before {
        animation-delay: -0.4s;
      }
    }
    &--10 {
      transform: rotate(270deg);

      &::before {
        animation-delay: -0.3s;
      }
    }
    &--11 {
      transform: rotate(300deg);

      &::before {
        animation-delay: -0.2s;
      }
    }
    &--12 {
      transform: rotate(330deg);

      &::before {
        animation-delay: -0.1s;
      }
    }
  }

  @-webkit-keyframes loading-bar {
    0%, 39%, 100% {opacity: 0;}
    40% {opacity: 1;}
  }

  @keyframes loading-bar {
    0%, 39%, 100% {opacity: 0;}
    40% {opacity: 1;}
  }
}
