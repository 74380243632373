.step {
  position: relative;
  height: 71px;
  margin: 0 -20px;
  padding: 0 103px 0 20px;
  border-bottom: 8px solid #f0f0f0;
  background-color: #fff;

  &__title {
    display: flex;
    align-items: center;
    height: 100%;
    font-family: youandiB;
    font-size: 17px;
    color: #0076c0;
    line-height: 21px;
  }

  &__number {
    position: absolute;
    top: 21px;
    right: 20px;
    font-family: youandiB;
    font-size: 15px;
    color: #0076c0;
    text-transform: uppercase;
  }

  &__total {
    color: #666;
  }
}
