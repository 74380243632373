.hd-area {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}

.mt5 {
  margin-top: 5px !important;
}

.mt10 {
  margin-top: 10px !important;
}

.mt30 {
  margin-top: 30px !important;
}

.mb10 {
  margin-bottom: 10px !important;
}

h4.tit-default {
  padding-left: 10px;
  font-weight: bold;
  font-size: 12px;
  background: url("http://biz.fubonhyundai.com/wsdoc/images/common/bar_default.gif") no-repeat left center;
}

.tbs02 {
  border-bottom: 1px solid #d1d1d1;
  border-top: 1px solid #666;
  zoom: 1;
}

.tbs02 .parag {
  text-align: left;
  padding: 0 10px;
  word-break: break-all;
  word-wrap: break-word;
}

.tbs02 .paragR {
  text-align: right;
  padding-right: 20px;
  word-break: break-all;
  word-wrap: break-word;
}

.tbs02 .paragL {
  text-align: left;
  padding-left: 15px;
  word-break: break-all;
  word-wrap: break-word;
}

.tbs02 .paragC {
  text-align: center;
  word-break: break-all;
  word-wrap: break-word;
}

.tbs02 table {
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;
}

.tbs02 thead th {
  background-color: #f7f7f7;
}

.tbs02 th.no-bg {
  background: none;
}

.tbs02 th, .tbs02 td {
  border-bottom: 1px solid #e8e8e8;
  border-right: 1px solid #e8e8e8;
  padding: 15px 0 10px;
  text-align: center;
  line-height: 15px;
}

.tbs02 td {
  border-right: 1px solid #e8e8e8;
}

.tbs02 .last th, .tbs02 .last td {
  border-bottom: none;
}

.tbs02 th.last, .tbs02 td.last {
  border-right: none;
}

.tbs02 tbody th, .tbs02 tbody td {
  color: #6a6a6a;
}

.tbs02 tr:last-child td {
  border-bottom: none;
}

.tbs02 tbody tr td.last-border {
  border-bottom: 0;
}

.tbs02 tbody td.bg {
  background-color: #f7f7f7;
  color: #666;
}

.tbs02 .th {
  background-color: #f7f7f7;
  color: #666;
}

.tbs02 .line2 th {
  border-bottom: 1px solid #d1d1d1;
}

.tbs02 .line2, .tbs02 .line2 th, .tbs02 .line2 td {
  padding: 7px 0;
  line-height: 17px;
}

.tbs02 tfoot td {
  border-top: 1px solid #e8e8e8;
}

.tbs02.t-dis tbody td {
  font-size: 12px;
  line-height: 1.5em;
  color: #666;
}

.tbs02.t-dis-ju th, .tbs02.t-dis-ju td {
  padding: 5px 0;
  font-family: dotum;
  font-size: 11px;
}

.tbs02.t-line {
  border-top: 1px solid #d1d1d1;
}

.tbs02 .title {
  overflow: hidden;
  padding-left: 10px;
  padding-right: 5px;
  text-overflow: ellipsis;
  text-align: left;
  white-space: nowrap;
}

.tbs02 .title a:hover {
  font-weight: bold;
}

#notice_area {
  width: 100% !important;
  padding: 0 !important;
}

.tbs02.noti_tbl tr td {
  word-break: break-all !important;
}

.img img {
  width: 100%;
  max-width: 100%;
  height: auto;
}

.img + .img,
.img + .txt,
.txt + .img,
.txt + .txt {
  margin-top: 20px;
}

/* table : style3(상품안내) */
.infoTbl3 {
  font-size: 14px;
  border-bottom: 1px solid #d9d9d9;
}

.infoTbl3 th {
  padding: 11px 5px;
  border-top: 1px solid #d9d9d9;
  border-left: 1px solid #d9d9d9;
  background-color: #f5f5f5;
  color: #333;
  text-align: center;
}

.infoTbl3 td {
  padding: 11px 10px;
  border-top: 1px solid #d9d9d9;
  border-left: 1px solid #d9d9d9;
  color: #666;
  text-align: left;
}

.infoTbl3.style2 td {
  padding: 11px 5px;
}

/* 2월 상품개정 */
.infoTbl3 tr:first-child th,
.infoTbl3 tr:first-child td {
  border-top-color: #808080;
}

.infoTbl3 thead + tbody tr:first-child th,
.infoTbl3 thead + tbody tr:first-child td {
  border-top: none;
}

.infoTbl3 tr th:first-child,
.infoTbl3 tr td:first-child {
  border-left: none;
}

.infoTbl3 thead tr:last-child th,
.infoTbl3 thead tr:last-child td {
  border-bottom: 1px solid #d9d9d9;
}

.infoTbl3 td > div.txt {
  padding: 3px 10px 3px 0;
}

.infoTbl3 td > div.txt .inTxt {
  margin-top: 5px;
}

.infoTbl3 td > div.txt .inTxt:first-child {
  margin-top: 0;
}

.infoTbl3 .aL {
  text-align: left;
}

.infoTbl3 .aC {
  text-align: center;
}

.infoTbl3 .aR {
  text-align: right;
}

.infoTbl3 .bgNone {
  background: none;
  color: #666;
}

.infoTbl3 p.bulTxt,
.infoTbl3 ul.bulTxt li,
.infoTbl3 p.dashTxt,
.infoTbl3 ul.dashTxt li {
  font-size: 14px;
}

.infoTbl3 td > div.txt .inTxt + .bulTxt,
.infoTbl3 td > div.txt .inTxt + .dashTxt,
ul.bulTxt li > .dashTxt,
ul.bulTxt li > .wrTxt {
  margin-top: 5px;
}

#notice_area table {
  width: 100%;
}

.noti_inner h4 {
  float: none !important;
}

.div_fl {
  float: none !important;
  width: auto !important;
  margin-top: 10px;
  margin-left: 10px;
}

.notilow_cont {
  margin: 10px 0 0 10px;
}