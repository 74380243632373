/* ---------------------------------------------------------------------------------------------------------------------
Heading
--------------------------------------------------------------------------------------------------------------------- */
.o-heading {
  display: block;

  &--1 {
    margin: 46px 0 21px;
    @include h2-hb;
    line-height: 24px;
  }

  &--2 {
    margin: 36px 0 21px;
    @include h3-hb;
    line-height: 21px;
  }

  &--3 {
    margin: 26px 0 21px;
    font-size: 15px;
    line-height: 21px;
  }

  &--4 {
    margin: 44px 0 25px;
    font-family: youandiB;
    font-size: 21px;
    line-height: 29px;
  }

  &--5 {
    margin: 42px 0 21px;
    font-family: youandiB;
    font-size: 19px;
    line-height: 34px;
  }

  &__label {
    display: inline-block;
    margin-right: 10px;
    padding: 0 9px;
    border-radius: 11px;
    background-color: #f8f8f8;
    border: 1px solid #eee;
    line-height: 19px;
    font-family: 'Noto Sans';
    font-weight: 600;
    font-size: 13px;
    color: #666;
  }
}

/* ---------------------------------------------------------------------------------------------------------------------
Text
--------------------------------------------------------------------------------------------------------------------- */

.webfont-r {
  font-family: youandiR;
}

.webfont-b {
  font-family: youandiB;
}

.font-basic {
  font-family: $font-family-base !important;
}

.fs-13 {
  font-size: 13px !important;
}

.fs-15 {
  font-size: 15px !important;
}

// 해당 부분은 추후 variables.scss 에 정리 필요
.co-gray {
  color: #666 !important;
}

.co-black {
  color: #000 !important;
}

.o-text {
  $root: '.o-text';

  display: inline-block;
  text-align: left;

  &--1 {
    font-size: 17px;
    line-height: 24px;
  }

  &--2 {
    font-size: 15px;
    line-height: 27px;

    & + & {
      margin-top: 7px;
    }

    .link {
      vertical-align: top;
      line-height: inherit;
    }
  }

  &--3 {
    font-size: 15px;
    line-height: 21px;
    color: #666;
  }

  &--4 {
    font-size: 13px;
    line-height: 21px;
    color: #666;
  }

  &--underline {
    position: relative;
    overflow: hidden;
    z-index: 1;
    display: inline-block;
    font-family: youandiB;
    font-size: 24px;
    line-height: 1.3;

    &::before {
      content: '';
      position: absolute;
      z-index: -1;
      bottom: 0;
      left: -100%;
      width: 100%;
      height: 12px;
      background-color: #f5f17c;
      animation-fill-mode: forwards;
      animation-name: text-underline;
      animation-duration: 0.7s;
    }

    @keyframes text-underline {
      from {
        left: -100%;
      }

      to {
        left: 0;
      }
    }

    @for $i from 1 through 10 {
      &:nth-of-type(#{$i}) {
        &::before {
          animation-delay: #{$i * 300-100}ms;
        }
      }
    }
  }

  &--dot {
    position: relative;
    display: block;
    padding-left: 12px;
    font-size: 15px;
    line-height: 27px;

    &::before {
      display: block;
      position: absolute;
      top: 11px;
      left: 0;
      width: 2px;
      height: 2px;
      border-radius: 50%;
      background-color: #333;
      content: '';
    }

    & + & {
      margin-top: 6px;
    }

    #{$root}--hyphen:first-child {
      margin-top: 6px;
    }
  }

  &--hyphen {
    position: relative;
    display: block;
    padding-left: 12px;
    font-size: 15px;
    line-height: 27px;

    &::before {
      display: block;
      position: absolute;
      top: 12px;
      left: 0;
      width: 5px;
      height: 1px;
      background-color: #666;
      content: '';
    }
  }

  &--star {
    position: relative;
    display: block;
    margin-top: 15px;
    padding-left: 12px;
    font-size: 13px;
    line-height: 21px;
    color: #666;

    &::before {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      content: '*';
    }

    & + & {
      margin-top: 5px;
    }

    .link {
      font-size: 13px;
      line-height: 21px;
    }
  }
}

.section-title-wrap {
  .o-heading--1 + .fl-right {
    margin: 46px 0 21px;
  }
}

/* ---------------------------------------------------------------------------------------------------------------------
Text List
--------------------------------------------------------------------------------------------------------------------- */
.c-text-list {
  $root: '.c-text-list';

  &__item {
    position: relative;
    font-size: 15px;
    line-height: 27px;

    &::before {
      display: block;
      position: absolute;
      content: '';
    }
  }

  [class='c-text-list'] & {
    padding-left: 12px;
  }

  &--star {
    margin-top: 14px;

    & > #{$root}__item {
      padding-left: 12px;
      line-height: 24px;
      color: #666;

      &::before {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        content: '*';
      }

      &:nth-child(n + 2) {
        margin-top: 1px;
      }
    }
  }

  &--dot > &__item {
    padding-left: 12px;

    &::before {
      top: 11px;
      left: 0;
      width: 2px;
      height: 2px;
      border-radius: 50%;
      background-color: #333;
    }

    &:nth-child(n + 2) {
      margin-top: 6px;
    }

    #{$root}--hyphen > #{$root}__item:first-child {
      margin-top: 6px;
    }
  }

  &--hyphen > &__item {
    padding-left: 12px;

    &::before {
      top: 12px;
      left: 0;
      width: 5px;
      height: 1px;
      background-color: #666;
    }
  }
}

dl.c-text-list {
  dd {
    padding-left: 12px;

    .c-accordion & {
      font-size: 13px;
      color: #666;
    }
  }
}
