.c-tooltip {
  $root : ".c-tooltip";

  display: block;
  position: relative;
  margin-top: 0 !important;

  &__button {
    position: relative;
    display: inline-block;
    width: 18px;
    height: 18px;
    background: url("../img/com/ico_tooltip.png") no-repeat 0 0;
    background-size: 36px auto;
    vertical-align: middle;
  }

  &__panel {
    display: none;
    position: absolute;
    top: calc(100% + 13px);
    left: -10px;
    z-index: 100;
    padding: 20px;
    border: 1px solid #0076c0;
    border-radius: 4px;
    background-color: #fff;
    text-align: left;
    font-family: $font-family-base;
    font-size: 13px;
    color: #666;
    line-height: 20px;
    opacity: 0;
    transition: opacity .35s linear;

    .c-text-list__item,
    .o-text--hyphen,
    .o-text--dot {
      font-size: 13px;
      line-height: 21px;
    }


    > ul > li:nth-child(n+2) {
      margin-top: 16px !important;
    }

    .c-text-list--hyphen > .c-text-list__item::before {
      top: 10px;
    }
  }

  &__button.is-active + &__panel {
    display: block;
    opacity: 1;
  }

  &__button:disabled + &__panel {
    display: none !important;
    opacity: 0 !important;
  }

  &__arrow {
    position: absolute;
    top: -7px;
    left: 22px;
    z-index: 101;
    width: 12px;
    height: 12px;
    border: 1px solid #0076c0;
    background-color: #fff;
    border-top: 0;
    border-right: 0;
    transform: rotate(135deg);

    #{$root}--top & {
      top: inherit;
      bottom: -7px;
      transform: rotate(-45deg);
    }
  }

  &--top {
    #{$root}__button.is-active {
      &::after {
        bottom: 25px;
        transform: rotate(180deg);
      }
    }

    #{$root}__panel {
      top: auto;
      bottom: calc(100% - -10px);
    }
  }

  &--ellipsis {
    #{$root}__button {
      width: 29px;
      height: 29px;
      background: url("../img/com/ico_tooltip_ellipsis.png") no-repeat 0 0;
      background-size: 87px auto;

      &.is-active {
        background-position: -29px 0;

        &::after {
          display: none;
        }
      }

      &:disabled {
        background-position: -58px 0;
        cursor: default;
      }
    }
  }

  .o-heading {
    display: inline-block;
    margin-bottom: 0;
  }

  .o-heading + &__button {
    margin-left: 7px;
  }

  .o-heading--1 ~ &__panel {
    top: calc(100% + 11px);
  }

  .o-heading--2 ~ &__panel {
    top: calc(100% + 12px);
  }

  &--top .o-heading--1 ~ &__panel {
    top: auto !important;
    bottom: calc(100% - 36px) !important;
  }
}
